import { ReactNode } from 'react';
import { SubHeader } from '@estimateone/frontend-components';
import { Heading } from '../../../../fec';
import ReturnLink from '../../../ReturnLink';
import styles from './styles.scss';

export type TitleHeroProps = {
  title: string;
  subtitle?: string;
  returnUrl: string;
  nav?: ReactNode;
};

const TitleHero = ({ title, subtitle, returnUrl, nav }: TitleHeroProps) => (
  <SubHeader>
    <div className={styles.titleHero}>
      <ReturnLink url={returnUrl} />
      <Heading level={1}>
        {title}
        {subtitle && <span className={styles.subtitle}>| {subtitle}</span>}
      </Heading>
    </div>
    {nav}
  </SubHeader>
);

export default TitleHero;
