export const parseIntIfDefined = (str?: string): number | undefined =>
  str !== undefined ? parseInt(str) : undefined;

export const reportIfMissing = (name: string, item?: string | DOMStringMap) => (item ? '' : name);
export const filterEmptyMissingReports = (values: string[]) =>
  values.filter((value) => value !== '');

type ProfilesData = { profilesUiUrl: string; profilesBffUrl: string; profilesIdpUrl: string };
export const getSubbieProfileDataEnvVars = (name: string): ProfilesData => {
  const isProfilesData = (item: unknown): item is ProfilesData =>
    item !== null &&
    item !== undefined &&
    typeof item === 'object' &&
    'profilesUiUrl' in item &&
    'profilesBffUrl' in item &&
    'profilesIdpUrl' in item;

  const data = document.getElementById(name)?.dataset ?? {};
  if (!isProfilesData(data)) {
    const missing = filterEmptyMissingReports([
      reportIfMissing('data', data),
      data ? reportIfMissing('profilesUiUrl', data.profilesUiUri) : '',
      data ? reportIfMissing('profilesBffUrl', data.profilesBffUrl) : '',
      data ? reportIfMissing('profilesIdpUrl', data.profilesIdpUrl) : '',
    ]);
    const message = `${name}: missing ${missing.join(', ')}`;
    throw new Error(message);
  }
  return data;
};
