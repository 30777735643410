import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react';
import { Translator } from 'eo-locale';
import { localTranslations } from './translations';
import { KnowledgeBaseLocale } from '@profiles/providers/TranslationsProvider/types';

export type AscensionWindow = Window & {
  global: {
    locale: string;
  };
};

export const isAscesionWindow = (subject: Window): subject is AscensionWindow =>
  (subject as AscensionWindow)?.global?.locale !== undefined;

const TranslationContext = createContext<Translator | undefined>(undefined);
const isValidLocale = (locale?: string | null) =>
  locale !== null && locale !== undefined && locale.length !== 0;
const isSupportedLocale = (locale: string) =>
  localTranslations.find(({ language }) => language === locale) !== undefined;

const DEFAULT_LOCALE = 'en_AU';

export const TranslationProvider = ({ children }: PropsWithChildren<unknown>) => {
  const locale =
    isAscesionWindow(window) &&
    isValidLocale(window.global.locale) &&
    isSupportedLocale(window.global.locale)
      ? window.global.locale
      : DEFAULT_LOCALE;

  const translator = useMemo(() => new Translator(locale, localTranslations), [locale]);

  return <TranslationContext.Provider value={translator}>{children}</TranslationContext.Provider>;
};

/**
 * Must be used within the `<TranslationProvider />`
 * @example
 * function Foo() {
 *    const translate = useTranslator();
 *
 *    return <p>{translate('businessIdentifier')}</p>;
 * }
 */
export const useTranslator = (locale?: string) => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error('useTranslation must be used within a <TranslationProvider />');
  }

  if (locale && locale !== context.language) {
    const translator = new Translator(locale, localTranslations);
    return translator.translate;
  }

  return context.translate;
};

export const useLanguage = () => {
  const context = useContext(TranslationContext);

  if (context === undefined) {
    throw new Error('useTranslation must be used within a <TranslationProvider />');
  }

  return context.language;
};

/**
 * Calls useLanguage for choosing the appropriate KnowledgeBaseLocale enum
 */
export const useKnowledgeBaseLocale = () => {
  const locale = useLanguage();

  switch (locale) {
    case 'en_AU':
      return KnowledgeBaseLocale.EN_AU;
    case 'en_GB':
      return KnowledgeBaseLocale.EN_GB;
    case 'en_IE':
      return KnowledgeBaseLocale.EN_IE;
    case 'en_NZ':
      return KnowledgeBaseLocale.EN_NZ;
    default:
      return KnowledgeBaseLocale.EN_AU;
  }
};

export const useTranslatorByLocale = () => {
  const auTranslator = useTranslator('en_AU');
  const ieTranslator = useTranslator('en_IE');
  const gbTranslator = useTranslator('en_GB');
  const nzTranslator = useTranslator('en_NZ');

  return useCallback(
    (message: string, locale: string) => {
      switch (locale) {
        case 'en_IE':
          return ieTranslator(message);
        case 'en_GB':
          return gbTranslator(message);
        case 'en_NZ':
          return nzTranslator(message);
        case 'en_AU':
        default:
          return auTranslator(message);
      }
    },
    [auTranslator, ieTranslator, gbTranslator, nzTranslator],
  );
};
