import { joinClassNames } from '@estimateone/frontend-components';
import styles from './styles.scss';

type LoadingSpinnerProps = {
  lightBackground?: boolean;
  size?: 'minute' | 'tiny' | 'small' | 'medium';
  inline?: boolean;
  center?: boolean;
};

const LoadingSpinner = ({
  lightBackground = true,
  size = 'tiny',
  inline = false,
  center = true,
}: LoadingSpinnerProps) => (
  <div className={joinClassNames(inline && styles.inline)}>
    <div
      role="status"
      aria-label="Loading"
      className={joinClassNames(
        styles.loadingSpinner,
        styles[size],
        center && styles.centre,
        lightBackground ? styles.light : styles.dark,
      )}
    />
  </div>
);

export default LoadingSpinner;
