import { ReactElement, Component, ReactNode, ErrorInfo } from 'react';
import Routing from 'routing';
import { Button, Col, E1Link, Row } from '@estimateone/frontend-components';
import { setExtras, setTag } from '@sentry/browser';
import E1Request from '@ascension/js/classes/E1Request';
import getSupportDetails from '@ascension/js/utils/support_details';
import { reportError } from '../helpers/errorReporter';
import ApiVersionMismatchError from './ApiVersionMismatchError';
import AuthenticationError from './AuthenticationError';

const AUTH_TIMEOUT = 419;
const API_VERSION_MISMATCH = 999;
const HANDLED_ERR_CODES = [AUTH_TIMEOUT, API_VERSION_MISMATCH];

type ErrorTitleAndMessage = {
  title: string;
  message: ReactElement;
  isRecoverable: boolean;
};

const getErrorTitleAndMessageForCode = (code: number | null = null): ErrorTitleAndMessage => {
  const { phone } = getSupportDetails();
  switch (code) {
    default:
      return {
        title: `We've had a slight hiccup!`,
        message: (
          <>
            <p>Looks like something weird is going on at our end.</p>
            <p>
              We&apos;ve just notified our engineers. If the issue is urgent please call us on{' '}
              <E1Link link={`tel:${phone}`}>{phone}</E1Link>.
            </p>
          </>
        ),
        isRecoverable: false,
      };
  }
};

const recoveryAction = (isRecoverable: boolean) =>
  isRecoverable
    ? window.location.reload()
    : new E1Request(Routing.generate('app_help_contactusmodal')).setShowLoadingModal().submit();

const DisplayedError = ({
  children,
  title,
  wrapContents,
  isRecoverable = false,
}: {
  children: ReactNode;
  title: string;
  wrapContents: boolean;
  isRecoverable?: boolean;
}) => (
  <div className={wrapContents ? 'wrapper' : undefined}>
    <Row>
      <Col span={12} alignContentX="center">
        <h1>{title}</h1>
      </Col>
    </Row>
    <Row>
      <Col span={6} offset={3} alignContentX="center">
        <div style={{ textAlign: 'center' }}>{children}</div>
      </Col>
    </Row>
    <Row>
      <Col span={4} offset={4}>
        <Button onClick={() => recoveryAction(isRecoverable)}>
          {isRecoverable ? 'Refresh' : 'Contact support'}
        </Button>
      </Col>
    </Row>
  </div>
);

type Props = {
  category?: string;
  wrapContents?: boolean;
  displayedError?: ReactElement;
  children?: ReactNode;
};

class ErrorBoundary extends Component<Props, { hasError: boolean; code: number | null }> {
  static defaultProps: Partial<Props> = {
    wrapContents: false,
    category: undefined,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
      code: null,
    };
  }

  static getDerivedStateFromError(error: { code: number | null }): {
    code: number | null;
    hasError: boolean;
  } {
    return {
      code: error.code || null,
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    const { code } = this.state;
    const { category } = this.props;
    if (code && HANDLED_ERR_CODES.includes(code)) {
      // Error is handled - do not pass to Sentry
      return;
    }
    reportError(error);
    setExtras({ errorInfo });
    if (category) {
      setTag('category', category);
    }
  }

  render(): ReactNode {
    const { hasError, code } = this.state;
    const { wrapContents, children, displayedError } = this.props;

    if (!hasError) return children;

    if (code === AUTH_TIMEOUT) return <AuthenticationError />;
    if (code === API_VERSION_MISMATCH) return <ApiVersionMismatchError />;

    if (displayedError) return displayedError;

    const { title, message, isRecoverable } = getErrorTitleAndMessageForCode(code);

    return (
      <DisplayedError
        title={title}
        wrapContents={wrapContents ?? false}
        isRecoverable={isRecoverable}
      >
        {message}
      </DisplayedError>
    );
  }
}

export default ErrorBoundary;
