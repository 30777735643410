import { Button, ButtonSize, Col, Row, Wrapper } from '@estimateone/frontend-components';
import styles from './styles.scss';

type NoStagesErrorProps = {
  message: string;
  returnUrl: string;
};

const NoStagesError = ({ message, returnUrl }: NoStagesErrorProps) => (
  <Wrapper>
    <Row>
      <Col span={12}>
        <Row>
          <Col span={10} offset={1} alignContentX="center">
            <h1 className={styles.noStagesHeading}>{message}</h1>
          </Col>
        </Row>
        <Row>
          <Col span={4} offset={4}>
            <Button size={ButtonSize.Large} link={returnUrl} fullWidth>
              Back
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  </Wrapper>
);

export default NoStagesError;
