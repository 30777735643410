import { captureMessage } from '@sentry/browser';

export enum LangRef {
  BUILDER = 'builder',
  BUILDER_HEADING = 'Builder',
  BUILDERS = 'builders',
  BUILDERS_HEADING = 'Builders',
  HEAD_CONTRACTOR_HEADING = 'head-contractor-heading',
  INCOGNITO_BUILDER_HEADING = 'incognito-builder-heading',
}

const LANG: Record<string, Record<LangRef, string>> = {
  en_AU: {
    [LangRef.BUILDER]: 'builder',
    [LangRef.BUILDER_HEADING]: 'Builder',
    [LangRef.BUILDERS]: 'builders',
    [LangRef.BUILDERS_HEADING]: 'Builders',
    [LangRef.HEAD_CONTRACTOR_HEADING]: 'Head contractor',
    [LangRef.INCOGNITO_BUILDER_HEADING]: 'Private Builder',
  },
  en_IE: {
    [LangRef.BUILDER]: 'contractor',
    [LangRef.BUILDER_HEADING]: 'Contractor',
    [LangRef.BUILDERS]: 'contractors',
    [LangRef.BUILDERS_HEADING]: 'Contractors',
    [LangRef.HEAD_CONTRACTOR_HEADING]: 'Contractor',
    [LangRef.INCOGNITO_BUILDER_HEADING]: 'Private Contractor',
  },
  en_GB: {
    [LangRef.BUILDER]: 'contractor',
    [LangRef.BUILDER_HEADING]: 'Contractor',
    [LangRef.BUILDERS]: 'contractors',
    [LangRef.BUILDERS_HEADING]: 'Contractors',
    [LangRef.HEAD_CONTRACTOR_HEADING]: 'Contractor',
    [LangRef.INCOGNITO_BUILDER_HEADING]: 'Private Contractor',
  },
};

const isSupportedLocale = (locale: string) =>
  Object.keys(LANG).find((language) => language === locale) !== undefined;

/**
 * @param ref the reference code for the text to be retrieved
 */
export const getTranslation = (ref: LangRef): string => {
  const locale = window.global?.locale;

  if (!locale) {
    captureMessage(
      `Translation requested but locale not found for url: ${window.location.href}, locale was: ${
        locale === undefined ? 'undefined' : locale === null ? 'null' : 'an empty string'
      }`,
    );
    return LANG.en_AU[ref];
  }

  return isSupportedLocale(locale) ? LANG[locale][ref] : LANG.en_AU[ref];
};
