import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Col,
  Icon,
  IconName,
  Row,
} from '@estimateone/frontend-components';
import styles from './styles.scss';

const NoResults = () => (
  <div className={styles.noResults}>
    <Row>
      <Col span={12} alignContentX="center">
        <strong>You haven&apos;t uploaded any quotes yet</strong>
        <p>
          Hit the
          <span>
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.Secondary}
              link={Routing.generate('app_default_index')}
            >
              <Icon name={IconName.Upload} size="1.2em" marginRight="small" />
              Add Quote
            </Button>
          </span>
          button on any project to get started
        </p>
      </Col>
    </Row>
  </div>
);

export default NoResults;
