import { E1Link } from '@estimateone/frontend-components';

type CompanyNameOrLinkProps = {
  companyName: string;
  handleViewContactDetails?: () => void;
};

const CompanyNameOrLink = ({ companyName, handleViewContactDetails }: CompanyNameOrLinkProps) => {
  if (!handleViewContactDetails) {
    return <span>{companyName}</span>;
  }

  return <E1Link onClick={() => handleViewContactDetails()}>{companyName}</E1Link>;
};

export default CompanyNameOrLink;
