import { useQuery } from '@apollo/client';
import AddQuoteForm from '../../../features/QuotesFeature/AddQuoteForm';
import NoStagesError from './NoStagesError';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import { FileManagerParams } from '@shared/FileUploader/types';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { CurrencyInfoContextProvider } from '@shared/context';
import { GET_PROJECT_WITH_QUOTABLE_STAGES } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import {
  GetProjectAndQuotableStages,
  GetProjectAndQuotableStagesVariables,
} from './types/GetProjectAndQuotableStages';
import { EntityId } from '@ascension/types';

export type AddMultipleQuotesPageProps = Pick<
  FileManagerParams,
  'context' | 'fileManagerLocale'
> & {
  projectId: EntityId;
  returnUrl: string;
};

const AddMultipleQuotesPage = ({
  context,
  fileManagerLocale,
  projectId,
  returnUrl,
}: AddMultipleQuotesPageProps) => {
  const { data } = useQuery<GetProjectAndQuotableStages, GetProjectAndQuotableStagesVariables>(
    GET_PROJECT_WITH_QUOTABLE_STAGES,
    {
      variables: { id: projectId },
    },
  );

  if (data === undefined) return <PageLoadingSpinner />;

  const {
    project: { name: projectName, address: projectAddress, quotableStages },
  } = data;

  if (quotableStages.length === 0) {
    return (
      <NoStagesError
        message="This project no longer has any builders available to send quotes to."
        returnUrl={returnUrl}
      />
    );
  }

  const currencyInfo = getCurrencyInfo(quotableStages[0].country.id);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <AddQuoteForm
        context={context}
        fileManagerLocale={fileManagerLocale}
        projectId={projectId}
        projectName={projectName}
        projectAddress={projectAddress ? graphqlTypeToString(projectAddress) : 'Unknown location'}
        stages={quotableStages}
        returnUrl={returnUrl}
      />
    </CurrencyInfoContextProvider>
  );
};

export default AddMultipleQuotesPage;
