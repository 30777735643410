import { useCallback, useEffect, ReactElement } from 'react';
import { reportError } from '../../helpers/errorReporter';
import { getRenderingFunctions } from './getRenderingFunctions';

export function asMicroFrontend<P extends Record<string, unknown>>(
  name: string,
  host: string,
): (props: P) => ReactElement {
  return (props) => {
    const containerName = `${name}-container`;

    const { mount, unmount } = getRenderingFunctions<P>(containerName);

    const mountMicroFrontend = useCallback(() => {
      if (mount) {
        mount(props);
      }
    }, [mount, props]);

    const unmountMicroFrontend = useCallback(unmount, [unmount]);

    const fetchAndAttachScript = useCallback(
      (scriptId: string) => {
        fetch(`${host}/asset-manifest.json`)
          .then((res) => res.json())
          .then((manifest) => {
            /* eslint-disable fp/no-mutation */
            const script = document.createElement('script');
            script.id = scriptId;
            script.crossOrigin = '';
            script.onload = mountMicroFrontend;
            document.head.appendChild(script);
            script.src = `${host}${manifest.files['main.js']}`;

            const link = document.createElement('link');
            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = `${host}${manifest.files['main.css']}`;
            document.head.appendChild(link);
            /* eslint-enable fp/no-mutation */
          })
          .catch((e) => {
            reportError(e);
          });
      },
      [mountMicroFrontend],
    );

    useEffect(() => {
      const scriptId = `micro-frontend-script-${name}`;

      if (document.getElementById(scriptId)) {
        mountMicroFrontend();
      } else {
        fetchAndAttachScript(scriptId);
      }

      return () => {
        unmountMicroFrontend();
      };
    }, [fetchAndAttachScript, mountMicroFrontend, unmountMicroFrontend]);

    return <div id={containerName} />;
  };
}
