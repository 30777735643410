import { CommitStatus, Upload } from '@ascension/js/classes/file_manager/PendingUploadManager';
import { ArchiveProgressBar } from './ProgressBar';
import styles from './styles.scss';

const ArchiveUploadProgressBar = ({
  pendingArchives,
  completedArchives,
}: {
  pendingArchives: Upload[];
  completedArchives: Upload[];
}) => {
  const archivesCount = pendingArchives.length + completedArchives.length;

  if (archivesCount === 0) {
    return null;
  }

  const pendingArchiveFileCount = pendingArchives.reduce(
    (i, { count_archive_entries: ct }) => i + (ct ?? 0),
    0,
  );

  if (
    completedArchives.length === 0 &&
    pendingArchives.length !== 0 &&
    pendingArchiveFileCount === 0
  ) {
    // Waiting for file list

    return (
      <ArchiveProgressBar percentComplete={0}>
        <div className={styles.message}>Preparing to extract {archivesCount} archive(s)</div>
      </ArchiveProgressBar>
    );
  }

  // Some files in pending archives can be complete without the whole archive being complete
  const completedInPendingArchivesFileCount = pendingArchives.reduce(
    (i, { archive_entries: ae }) =>
      i + ae.filter((e) => e.commit_status === CommitStatus.COMPLETE).length,
    0,
  );
  const completedArchiveFileCount = completedArchives.reduce(
    (i, { count_archive_entries: ct }) => i + (ct ?? 0),
    0,
  );

  const totalArchiveFileCount = pendingArchiveFileCount + completedArchiveFileCount;

  const extractionProgress = Math.ceil(
    ((completedArchiveFileCount + completedInPendingArchivesFileCount) / totalArchiveFileCount) *
      100,
  );

  return (
    <ArchiveProgressBar percentComplete={extractionProgress}>
      <div className={styles.message}>
        Extracted {completedArchiveFileCount + completedInPendingArchivesFileCount}/
        {totalArchiveFileCount} files from {archivesCount} archive(s)
      </div>
      <div>{extractionProgress}% complete</div>
    </ArchiveProgressBar>
  );
};

export default ArchiveUploadProgressBar;
