import { ReactNode, useState } from 'react';
import { Button, ButtonVariant, Icon, IconName } from '@estimateone/frontend-components';
import { BannerVariant } from './enums';
import styles from './styles.scss';

interface BannerProps {
  children: ReactNode;
  variant?: BannerVariant;
  onDismiss?: () => void;
}

const Banner = ({ children, variant = BannerVariant.Info, onDismiss }: BannerProps) => {
  const [dismissed, setDismissed] = useState(false);
  if (dismissed) {
    return null;
  }

  const handleDismiss = () => {
    setDismissed(true);
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <div className={`${styles.bannerContainer} ${styles[variant]}`}>
      <div className={styles.bannerContent}>
        {children}
        {onDismiss !== undefined && (
          <div className={styles.closeButtonContainer}>
            <Button
              onClick={handleDismiss}
              tooltip="Close"
              aria-label="Close"
              variant={ButtonVariant.Transparent}
            >
              <span className="badge badge-round-xs">
                <Icon name={IconName.Close} />
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
