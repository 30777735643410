import { ReactNode, useMemo } from 'react';
import Routing from 'routing';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import Helper from '@ascension/js/classes/Helper';

type HelpSliderTriggerProps = {
  template: string;
  className?: string;
  children: ReactNode;
};

export const HelpSliderTrigger = ({ template, className, children }: HelpSliderTriggerProps) => {
  const container = document.getElementsByClassName('helper-container')[0];
  const helper = useMemo(() => {
    if (container instanceof HTMLElement) {
      return new Helper($(container));
    }

    return null;
  }, [container]);

  if (!helper) {
    return null;
  }

  return (
    <Button
      type="button"
      variant={ButtonVariant.Transparent}
      className={className}
      data-help-template={template}
      onClick={async () => {
        await helper
          .setUrl(
            Routing.generate('app_help_trigger', {
              template,
            }),
          )
          .show()
          .submit();
      }}
    >
      {children}
    </Button>
  );
};
