import { DragEvent, ReactNode, useState } from 'react';

const CustomDragDropArea = ({
  children,
  onFile,
  activeBackgroundColour,
}: {
  children?: ReactNode;
  onFile: (file: File) => void;
  activeBackgroundColour?: string;
}) => {
  const [dropCounter, setDropCounter] = useState(0);

  const getFilesFromEvent = (e: DragEvent<HTMLDivElement>): File[] => {
    if (!e.dataTransfer) return [];
    const fileList = e.dataTransfer.files;

    return [...new Array(fileList.length)].reduce<File[]>((acc, _, i) => {
      const file = fileList.item(i);
      if (file) {
        acc.push(file);
      }
      return acc;
    }, []);
  };

  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    getFilesFromEvent(e).forEach((file) => {
      onFile(file);
    });
    setDropCounter(0);
  };

  const handleEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropCounter((prev) => prev + 1);
  };

  const handleLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDropCounter((prev) => prev - 1);
  };

  return (
    <div
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDrag(e)}
      onDragEnter={(e) => handleEnter(e)}
      onDragLeave={(e) => handleLeave(e)}
      style={{ background: dropCounter !== 0 ? activeBackgroundColour : 'transparent' }}
    >
      {children}
    </div>
  );
};

export default CustomDragDropArea;
