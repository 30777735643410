import { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonSize, ButtonVariant, Col, Row } from '@estimateone/frontend-components';
import setFieldTouched from 'final-form-set-field-touched';
import { Heading } from '../../../../fec';
import { getViewRouteForQuoteId } from '../utils';
import GlobalErrorList from '../../../../shared/FinalForm/GlobalErrorList';
import { TransformedFile } from '@shared/FileUploader';
import { FileManagerParams } from '@shared/FileUploader/types';
import { NonEditablePackageBuilderSection } from '@shared/Quotes/Form/NonEditablePackageContactSection';
import { StageDetails } from '@shared/Quotes/Form/ProjectOrStageDetails';
import { QuoteSectionWithQuoteFileUploader } from '@shared/Quotes/Form/QuoteFormQuoteSection';
import { focusOnQuoteErrorsDecorator } from '@shared/Quotes/Form/decorators';
import { validateQuoteFormCommonFields } from '@shared/Quotes/Form/validators';
import { useReviseQuote } from './hooks';
import { QuoteFormCommonFieldName, UploadParentType } from '@ascension/enums';
import { QuoteSubbieReviseFormValues, SubbieReviseQuoteFormErrors } from './types';
import { EntityId } from '@ascension/types';
import styles from '../../../../shared/Quotes/Form/styles.scss';

export type ReviseQuoteFormProps = {
  quoteId: EntityId;
  projectAddress: string;
  stageName: string;
  packageTitle: string;
  builderName: string;
  extraFiles: TransformedFile[];
  quoteDetails: string | undefined;
  returnUrl: string;
} & Pick<FileManagerParams, 'fileManagerLocale'>;

const FORM_ID = 'subbie-revise-quote-form';

const validate = (values: QuoteSubbieReviseFormValues): SubbieReviseQuoteFormErrors =>
  validateQuoteFormCommonFields(values);

const ReviseQuoteForm = ({
  fileManagerLocale,
  quoteId,
  projectAddress,
  stageName,
  packageTitle,
  builderName,
  extraFiles,
  quoteDetails,
  returnUrl,
}: ReviseQuoteFormProps) => {
  const focusOnErrors = useMemo(
    () =>
      focusOnQuoteErrorsDecorator<QuoteSubbieReviseFormValues, SubbieReviseQuoteFormErrors>(
        FORM_ID,
      ),
    [],
  );

  const { submit: reviseQuote, revisedQuoteId } = useReviseQuote(quoteId);

  useEffect(() => {
    if (revisedQuoteId) {
      window.location.replace(getViewRouteForQuoteId(revisedQuoteId));
    }
  }, [revisedQuoteId]);

  return (
    <Form<QuoteSubbieReviseFormValues>
      onSubmit={reviseQuote}
      initialValues={{
        [QuoteFormCommonFieldName.ExtraFiles]: extraFiles,
        [QuoteFormCommonFieldName.Details]: quoteDetails,
      }}
      decorators={[focusOnErrors]}
      validate={(values): SubbieReviseQuoteFormErrors => validate(values)}
      mutators={{ setFieldTouched }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form id={FORM_ID} onSubmit={handleSubmit} name="Revise Quote">
          <Row>
            <Col span={12}>
              {submitError && <GlobalErrorList errors={submitError} />}

              <div className={styles.subHeader}>
                Revise your quote by uploading an updated quote file and providing details of any
                changes.
              </div>

              <div className={styles.sectionHeading}>
                <Heading level={2}>Project details</Heading>
              </div>
              <StageDetails name={stageName} addressString={projectAddress} />
            </Col>
          </Row>

          <Row>
            <Col span={12} excludeBottomGutter>
              <NonEditablePackageBuilderSection
                packageName={packageTitle}
                recipientName={builderName}
              />
            </Col>
          </Row>

          <QuoteSectionWithQuoteFileUploader
            fileManagerLocale={fileManagerLocale}
            parentType={UploadParentType.QUOTE}
            parentId={quoteId}
            extraFilesParentType={UploadParentType.QUOTE_EXTRA_FILE}
            extraFilesParentId={quoteId}
          />

          <Row>
            <Col span={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col span={2}>
              <Button link={returnUrl} size={ButtonSize.Large} variant={ButtonVariant.Grey}>
                Cancel
              </Button>
            </Col>
            <Col span={4} offset={6}>
              <Button size={ButtonSize.Large} fullWidth isDisabled={submitting}>
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default ReviseQuoteForm;
