import { Card, CardVariant, E1Link, Icon, IconName } from '@estimateone/frontend-components';
import { readableFileSize } from '@ascension/js/utils/filesize';
import readableFileType from '../utils/readableFileType';
import FilenameOrLink from './FilenameOrLink';
import { QuoteFileAction } from '@ascension/enums';
import styles from './styles.scss';

type QuoteFileProps = {
  action?: QuoteFileAction;
  file: {
    fileName: string;
    fileSize: number;
    downloadUrl?: string;
    viewUrl?: string;
  };
  onRemove?: () => void;
};

const QuoteFile = ({
  action = QuoteFileAction.Download,
  file,
  onRemove = () => undefined,
}: QuoteFileProps) => {
  const fileExtension = readableFileType(file.fileName);
  const fileInfo = fileExtension
    ? `${fileExtension} - ${readableFileSize(file.fileSize)}`
    : readableFileSize(file.fileSize);

  return (
    <Card variant={CardVariant.Grey}>
      <Card.Body isWhite>
        <div className={styles.quoteFile}>
          <div className={styles.fileIcon}>
            <Icon name={IconName.File} size="5rem" strokeWidth={1} />
          </div>
          <div className={styles.fileDetails}>
            <div>
              <p className={styles.fileName}>
                {action === QuoteFileAction.Download && (
                  <Icon name={IconName.View} marginRight="small" size="0.8em" />
                )}
                <FilenameOrLink action={action} file={file} />
              </p>
              <p>{fileInfo}</p>
            </div>

            {action === QuoteFileAction.Download && (
              <div className={styles.fileAction}>
                <Icon name={IconName.Download} marginRight="small" size="0.8em" />
                <E1Link link={file.downloadUrl} target="_blank" rel="noopener noreferrer">
                  Download
                </E1Link>
              </div>
            )}
            {action === QuoteFileAction.Remove && (
              <div className={styles.fileAction}>
                <Icon name={IconName.Close} marginRight="small" size="0.8em" />
                <E1Link onClick={() => onRemove()}>Remove file</E1Link>
              </div>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default QuoteFile;
