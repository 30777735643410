import { Icon, IconName, Tag, TagVariant } from '@estimateone/frontend-components';
import { format, parseISO } from 'date-fns';
import { QuoteStatus } from '../../../../enums';

const Variant: { [key in QuoteStatus]: TagVariant } = {
  [QuoteStatus.Viewed]: TagVariant.DocsNotAccessed,
  [QuoteStatus.Shortlisted]: TagVariant.Magic600,
  [QuoteStatus.NotViewed]: TagVariant.Warning,
  [QuoteStatus.Downloaded]: TagVariant.Practical300,
  [QuoteStatus.NotDownloaded]: TagVariant.Crimson600,
};

const StatusMessage: { [key in QuoteStatus]: string } = {
  [QuoteStatus.Viewed]: 'Viewed',
  [QuoteStatus.Shortlisted]: 'Shortlisted',
  [QuoteStatus.NotViewed]: 'Not viewed',
  [QuoteStatus.Downloaded]: 'Downloaded',
  [QuoteStatus.NotDownloaded]: 'Not downloaded',
};

type QuoteStatusTagProps = {
  status: QuoteStatus;
  dateTime?: string | null;
};

const iconStatus = (localStatus: QuoteStatus) => {
  switch (localStatus) {
    case QuoteStatus.Shortlisted:
      return <Icon name={IconName.Check} strokeWidth={4} size=".6em" marginRight="small" />;
    case QuoteStatus.NotViewed:
      return <Icon name={IconName.Alert} strokeWidth={0} size=".8em" marginRight="large" />;
    default:
      return null;
  }
};

const QuoteStatusTag = ({ status, dateTime }: QuoteStatusTagProps) => {
  const icon = iconStatus(status);

  const viewedDateTime = dateTime ? format(parseISO(dateTime), 'd MMM yyyy') : '';

  const text =
    status === QuoteStatus.Viewed
      ? `${StatusMessage[status]} ${viewedDateTime}`
      : StatusMessage[status];

  return <Tag variant={Variant[status]} text={text} icon={icon || undefined} />;
};

export default QuoteStatusTag;
