import { ReactElement } from 'react';
import Routing from 'routing';
import { E1Link } from '@estimateone/frontend-components';
import { truncate } from '@ascension/js/utils/truncate';
import ProjectDate from '@shared/ProjectDate';
import QuoteStatusTag from '@shared/Quotes/QuoteStatusTag';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { Column } from './enums';
import { QuoteReturnRoute, QuoteStatus } from '@ascension/enums';
import { GetQuotes_subbieQuotes as Quote } from './types/GetQuotes';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const getViewLink = (id: EntityId) =>
  Routing.generate('app_quote_view', {
    id,
    returnTo: QuoteReturnRoute.SubbieQuoteList,
  });

export type IndexedComponents = { [col in Column]: ReactElement };

export default (quotesGroupedByProject: Quote[][]) =>
  quotesGroupedByProject.map((projectQuotes): IndexedComponents => {
    const [{ projectId, projectAddress, projectName }] = projectQuotes;

    return {
      [Column.Project]: (
        <div>
          <div className={styles.projectName}>{projectName}</div>
          <div className={styles.projectDetails}>
            #{projectId}
            {projectAddress !== null && ` - ${graphqlTypeToString(projectAddress)}`}
          </div>
        </div>
      ),
      [Column.Builder]: (
        <ul>
          {projectQuotes.map(({ id, recipientName }) => (
            <li key={id}>{truncate(recipientName, 32)}</li>
          ))}
        </ul>
      ),
      [Column.QuotesDue]: (
        <ul>
          {projectQuotes.map(({ id, tenderQuotesDue: dueDate }) => (
            <li key={id}>
              <ProjectDate timestamp={dueDate ?? ''} useRelativeDayNames />
            </li>
          ))}
        </ul>
      ),
      [Column.Package]: (
        <ul>
          {projectQuotes.map(({ id, packageTitle }) => (
            <li key={id}>{truncate(packageTitle, 24)}</li>
          ))}
        </ul>
      ),
      [Column.QuoteDetails]: (
        <ul>
          {projectQuotes.map(({ id, version, createdByFullName }) => (
            <li key={id}>
              <E1Link link={getViewLink(id)}>Version {version}</E1Link>
              <span className={styles.quoteAuthor}>added by {truncate(createdByFullName, 32)}</span>
            </li>
          ))}
        </ul>
      ),
      [Column.Status]: (
        <ul>
          {projectQuotes.map(({ id, lastDownloadedAt }) => (
            <li key={id}>
              <QuoteStatusTag
                status={lastDownloadedAt ? QuoteStatus.Viewed : QuoteStatus.NotViewed}
                dateTime={lastDownloadedAt}
              />
            </li>
          ))}
        </ul>
      ),
    };
  });
