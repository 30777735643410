import { gql } from '@apollo/client';
import Discounts from '../../../api/fragments/Discounts';

export const USER_ACCOUNT_AND_SUPPORT_DETAILS = gql`
  query AccountContextQuery {
    availableAccountOffer {
      ...OfferAndRecommendedProduct
    }
    currentUser {
      ...UserDetails
      ...AccountAndLicenses
      account {
        primaryState {
          id
          shortName
        }
        primaryCountry {
          id
        }
        credits {
          allowed
          used
          startDate
          renewalDate
        }
        isChargebee
      }
    }
    experiments(filter: USER) {
      id
      name
    }
    projectNoteCounts {
      projectId
      count
    }
    searches {
      ...UserSearchDetails
    }
    paywallValueProjectMaxBudget
    supportDetails {
      email
      phone
    }
    termsOfUseURL
    privacyPoliciesURL
    hasSpeciFinderFeature: featureValue(featureName: SPECIFINDER_SAVED_KEYWORDS)
    speciFinderKeywordsForUser {
      id
      phrase
    }
  }

  fragment OfferAndRecommendedProduct on AccountOffer {
    id
    lapsedAt
    recommendedProduct {
      id
      name
      isAddon
      pricingOptions {
        ...PricingOptions
      }
    }
    offer {
      id
      absoluteDiscount
      relativeDiscount
      monthDuration
      products {
        id
        name
      }
      discountedPricingOptions {
        ...PricingOptions
      }
    }
  }

  fragment UserDetails on User {
    id
    firstName
    lastName
    email
    phone
    isAdmin
    isSystem
    address {
      id
      latitude
      longitude
    }
    imperialUnitsSetting
    lastActiveAt
  }

  fragment LicenseDetails on License {
    id
    product {
      id
      name
      features
    }
    billingCycle
    amount
    isAutoRenew
    renewedAt
    discontinuedAt
    expiredAt
    graceDays
    activatedAt
  }

  fragment AccountAndLicenses on User {
    account {
      id
      name
      createdAt
      licensingInfo {
        activeLicenses {
          ...LicenseDetails
        }
        provisionedLicenses {
          ...LicenseDetails
        }
        hasActivePaidLicenses
        canAccountUpgrade
      }
      activeOrProvisionedAddOns {
        name
        features
      }
      realUsers {
        id
        fullName
        firstName
        lastName
        phone
        email
        deletedAt
      }
      stockTrades {
        id
      }
      type
    }
  }

  fragment UserSearchDetails on UserProjectSearch {
    id
    searchTerm
    searchesCount
    numResults
    updatedAt
  }
  ${Discounts.fragments.pricingOptions}
`;
