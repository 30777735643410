import { gql } from '@apollo/client';
import Address from '../../../api/fragments/Address';
import Quotes from '../../../api/fragments/Quotes';

const GET_QUOTE = gql`
  query GetQuoteAndVersions($id: EntityId!) {
    quote(id: $id) {
      ...QuoteCommon
      ...QuoteExtraDetail

      canRevise
      recipientName
      version
      confirmFixedPrice

      ...QuoteActivityLogs
      ...QuoteNotes
      ...QuotePackageRfq

      siblings {
        ...QuoteSummary
        ...QuoteNotes
      }

      recipientAddress {
        ...DetailedAddress
      }

      stageName
      recipientCountry {
        id
      }
    }
  }
  ${Address.fragments.detailedAddress}
  ${Quotes.fragments.common}
  ${Quotes.fragments.detail}
  ${Quotes.fragments.summary}
  ${Quotes.fragments.packageRfq}
  ${Quotes.fragments.quoteNotes}
  ${Quotes.fragments.quoteActivityLogs}
`;

const GET_QUOTE_WITH_CONTACT_DETAILS = gql`
  query GetQuoteAndVersionsAndContact($id: EntityId!) {
    quote(id: $id) {
      ...QuoteCommon
      ...QuoteExtraDetail

      canRevise
      recipientName
      version
      confirmFixedPrice

      ...QuoteActivityLogs
      ...QuoteNotes
      ...QuotePackageRfqWithContactDetails

      siblings {
        ...QuoteSummary
        ...QuoteNotes
      }

      recipientAddress {
        ...DetailedAddress
      }

      stageName
      recipientCountry {
        id
      }
    }
  }
  ${Address.fragments.detailedAddress}
  ${Quotes.fragments.common}
  ${Quotes.fragments.detail}
  ${Quotes.fragments.summary}
  ${Quotes.fragments.packageRfqWithContactDetails}
  ${Quotes.fragments.quoteNotes}
  ${Quotes.fragments.quoteActivityLogs}
`;

export { GET_QUOTE, GET_QUOTE_WITH_CONTACT_DETAILS };
