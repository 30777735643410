import { Button, ButtonVariant, Icon, IconName } from '@estimateone/frontend-components';
import { readableFileSize } from '@ascension/js/utils/filesize';
import readableFileType from '../utils/readableFileType';
import FilenameOrLink from './FilenameOrLink';
import { QuoteFileAction } from '@ascension/enums';
import styles from './styles.scss';

type CommonProps = {
  action?: QuoteFileAction;
  onRemove?: (file: { id: string }) => void;
};

type ExtraFile = {
  id: string;
  fileName: string;
  fileSize: number;
  downloadUrl?: string;
};

const QuoteExtraFile = ({
  action = QuoteFileAction.Download,
  file,
  onRemove = () => undefined,
}: CommonProps & { file: ExtraFile }) => (
  <li>
    <div className={styles.extraFileIcon}>
      <Icon name={IconName.File} />
    </div>
    <div className={styles.extraFileName}>
      <FilenameOrLink action={action} file={file} />
    </div>
    <div className={styles.extraFileType}>{readableFileType(file.fileName)}</div>
    <div className={styles.extraFileSize}>{readableFileSize(file.fileSize)}</div>

    <div className={styles.extraFileAction}>
      {action === QuoteFileAction.Download && (
        <Button
          link={file.downloadUrl}
          variant={ButtonVariant.Transparent}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Download"
        >
          <Icon name={IconName.Download} />
        </Button>
      )}
      {action === QuoteFileAction.Remove && (
        <Button onClick={() => onRemove?.(file)} variant={ButtonVariant.Transparent}>
          <Icon name={IconName.Close} />
        </Button>
      )}
    </div>
  </li>
);

const QuoteExtraFiles = ({ action, files, onRemove }: CommonProps & { files: ExtraFile[] }) => (
  <ul className={styles.quoteExtraFilesList}>
    {files.map((additionalFile) => (
      <QuoteExtraFile
        action={action}
        file={additionalFile}
        onRemove={onRemove}
        key={`${additionalFile.fileName}:${additionalFile.id}`}
      />
    ))}
  </ul>
);

export default QuoteExtraFiles;
