import { gql } from '@apollo/client';
import Quotes from '../../../../api/fragments/Quotes';

export const SUBBIE_GET_QUOTE_FOR_REVISION = gql`
  query SubbieGetQuoteForRevision($id: EntityId!) {
    quote(id: $id) {
      id
      clarifications
      version

      additionalFiles {
        ...QuoteExtraFile
      }

      package {
        id
        title
      }

      recipientName
      recipientCountry {
        id
      }

      projectId
      projectAddress {
        id
        address1
        shortAddress
        country {
          id
          name
        }
      }

      stageName
    }
  }
  ${Quotes.fragments.quoteExtraFile}
`;
