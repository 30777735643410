import { Card, CardVariant } from '@estimateone/frontend-components';
import { graphqlTypeToLines } from '../utils/Address';
import CompanyNameOrLink from './CompanyNameOrLink';
import { AddressDetails } from '@api/fragments/Address/types';
import styles from './styles.scss';

type QuoteContactProps = {
  title: string;
  companyName: string | null;
  companyAddress: AddressDetails | null;
  contactName?: string | null;
  handleViewContactDetails?: () => void;
};

const QuoteContact = ({
  title,
  companyName,
  companyAddress,
  contactName,
  handleViewContactDetails = undefined,
}: QuoteContactProps) => {
  const address = companyAddress ? graphqlTypeToLines(companyAddress) : null;

  return (
    <Card variant={CardVariant.Grey}>
      <Card.Body isWhite>
        <p className={styles.cardTitle}>{title}</p>
        <p className={styles.cardSubtitle}>
          {companyName && (
            <CompanyNameOrLink
              companyName={companyName}
              handleViewContactDetails={handleViewContactDetails}
            />
          )}
          {contactName && ` - ${contactName}`}
        </p>
        {address && (
          <p className={styles.contactAddress}>
            {address.firstLine} <br />
            {address.secondLine}
          </p>
        )}
      </Card.Body>
    </Card>
  );
};

export default QuoteContact;
