export type E1AddressFields = {
  address: string | null;
  address2: string | null;
  address3: string | null;
  suburb: string | null;
  province: string | null;
  city: string | null;
  state: string | null;
  postcode: string | null;
  country: string | null;
  longitude: number | null;
  latitude: number | null;
};

export type E1AddressStringFields = keyof Omit<E1AddressFields, 'longitude' | 'latitude'>;

export const addressFields: E1AddressStringFields[] = [
  'address',
  'address2',
  'address3',
  'suburb',
  'city',
  'province',
  'state',
  'postcode',
  'country',
];

export const googleAddressParts: string[] = [
  'street_number',
  'street_address',
  'route',
  'premise',
  'subpremise',
];

export const baseGoogleToE1Mapping: Record<string, E1AddressStringFields[]> = {
  country: ['country'],
  postal_code: ['postcode'],
};

export const AUGoogleToE1Mapping: Record<string, E1AddressStringFields[]> = {
  locality: ['suburb'],
  administrative_area_level_1: ['state'],
  ...baseGoogleToE1Mapping,
};

export const UKGoogleToE1Mapping: Record<string, E1AddressStringFields[]> = {
  postal_town: ['city'],
  administrative_area_level_2: ['province'],
  ...baseGoogleToE1Mapping,
};

export const IEGoogleToE1Mapping: Record<string, E1AddressStringFields[]> = {
  // Irish addresses seem to either return postal_town or locality
  postal_town: ['city'],
  locality: ['city'],
  administrative_area_level_1: ['province'],
  ...baseGoogleToE1Mapping,
};

export const fallbackGoogleToE1Mapping: Record<string, E1AddressStringFields[]> = {
  country: ['country'],
  postal_code: ['postcode'],
  postal_town: ['city'],
  locality: ['suburb', 'city'],
  administrative_area_level_1: ['state', 'province'],
};
