import { Tag, TagVariant } from '@estimateone/frontend-components';
import { getVariantForTypeName } from './utils';
import { ConsultantTypeGroup } from '../../../enums';

const Variant: { [key in ConsultantTypeGroup]: TagVariant } = {
  [ConsultantTypeGroup.Architect]: TagVariant.Magic200,
  [ConsultantTypeGroup.Engineer]: TagVariant.Practical200,
  [ConsultantTypeGroup.Other]: TagVariant.West200,
};

type ConsultantTypeTagProps = {
  typeName: string;
};

const ConsultantTypeTag = ({ typeName }: ConsultantTypeTagProps) => (
  <Tag variant={Variant[getVariantForTypeName(typeName)]} text={typeName} />
);

export default ConsultantTypeTag;
