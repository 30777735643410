const englishCommon = {
  businessIdentifier_AU: 'ABN',
  businessIdentifier_NZ: 'NZBN',
  businessIdentifier_UK: 'CRN',
  businessIdentifier_IE: 'CRN',

  businessIdentifierFieldHeader_AU: 'What is your company ABN?',
  businessIdentifierFieldHeader_NZ: 'What is your company NZBN?',
  businessIdentifierFieldHeader_UK: 'What is your company registration number (CRN)?',
  businessIdentifierFieldHeader_IE: 'What is your company registration number (CRN)?',

  businessIdentifierFieldDescription_AU:
    'Adding your ABN shows builders that you are a legitimate subcontractor whom they can trust.',
  businessIdentifierFieldDescription_NZ:
    'Adding your NZBN shows builders that you are a legitimate subcontractor whom they can trust.',
  businessIdentifierFieldDescription_UK:
    'This shows contractors that you are a legitimate subcontractor they can trust.',
  businessIdentifierFieldDescription_IE:
    'This shows contractors that you are a legitimate subcontractor they can trust.',

  missingBusinessIdentifierMessage_AU: 'Please enter an ABN',
  missingBusinessIdentifierMessage_NZ: 'Please enter an NZBN',
  missingBusinessIdentifierMessage_UK: 'Please enter a CRN',
  missingBusinessIdentifierMessage_IE: 'Please enter a CRN',

  projectCategoryFieldDescription_AU:
    'This enables builders to find you for projects that match your expertise.',
  projectCategoryFieldDescription_NZ:
    'This enables builders to find you for projects that match your expertise.',
  projectCategoryFieldDescription_UK:
    'This enables contractors to find you for projects that match your expertise.',
  projectCategoryFieldDescription_IE:
    'This enables contractors to find you for projects that match your expertise.',
  builders_AU: 'Builders',
  builders_NZ: 'Builders',
  builders_UK: 'Contractors',
  builders_IE: 'Contractors',
  builder_AU: 'Builder',
  builder_NZ: 'Builder',
  builder_UK: 'Contractor',
  builder_IE: 'Contractor',
  incognitoBuilder_AU: 'Private Builder',
  incognitoBuilder_NZ: 'Private Builder',
  incognitoBuilder_UK: 'Private Contractor',
  incognitoBuilder_IE: 'Private Contractor',
};

/* eslint-disable camelcase */
const en_AU = {
  language: 'en_AU',
  messages: {
    ...englishCommon,
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new builders and getting access to best job opportunities on E1.',
    businessIdentifier: 'ABN',
    businessIdentifierFieldHeader: 'What is your company ABN?',
    businessIdentifierFieldDescription:
      'Adding your ABN shows builders that you are a legitimate subcontractor whom they can trust.',
    missingBusinessIdentifierMessage: 'Please enter an ABN',
    currencySymbol: '$',
    defaultCountryCode: 'au',
    location: 'au',
    missingBusinessIdentifier: 'No ABN',
    builderLabel: 'Builder',
    businessIdentifierTooltipText:
      'Adding an ABN ensures Builders interacting with you on E1 recognise you operate as a registered business. Only Builders can see this information on E1.',
    companyIdentifierNotFoundErrorMessage:
      'ABN could not be found in the Australian Business Register',
    notSupportedCountryErrorMessage:
      'E1 does not currently check company registration numbers for this country.',
    technicalIssueErrorMessage:
      'An issue prevented us from checking this ABN. We’ll try again later.',
    builders: 'Builders',
    invitations: 'invitations',
  },
};

const en_IE = {
  language: 'en_IE',
  messages: {
    ...englishCommon,
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new contractors and getting access to best job opportunities on E1.',
    businessIdentifier: 'CRN',
    businessIdentifierFieldHeader: 'What is your company CRN?',
    businessIdentifierFieldDescription:
      'Adding your CRN shows contractors that you are a legitimate subcontractor whom they can trust.',
    missingBusinessIdentifierMessage: 'Please enter a CRN',
    currencySymbol: '€',
    defaultCountryCode: 'ie',
    location: 'ie',
    missingBusinessIdentifier: 'No CRN',
    builderLabel: 'Contractor',
    businessIdentifierTooltipText:
      'Adding a CRN ensures Contractors interacting with you on E1 recognise you operate as a registered business. Only Contractors can see this information on E1.',
    companyIdentifierNotFoundErrorMessage:
      'CRN could not be found in the Companies Registration Office register',
    notSupportedCountryErrorMessage:
      'E1 does not currently check company registration numbers for this country.',
    technicalIssueErrorMessage:
      'An issue prevented us from checking this CRN. We’ll try again later.',
    builders: 'Contractors',
    invitations: 'enquiries',
    provinceFieldLabel: 'County',
  },
};

const en_GB = {
  language: 'en_GB',
  messages: {
    ...englishCommon,
    formDescription:
      'An up-to-date Company Profile is the key to connecting with new contractors and getting access to best job opportunities on E1.',
    businessIdentifier: 'CRN',
    businessIdentifierFieldHeader: 'What is your company CRN?',
    businessIdentifierFieldDescription:
      'Adding your CRN shows contractors that you are a legitimate subcontractor whom they can trust.',
    missingBusinessIdentifierMessage: 'Please enter a CRN',
    currencySymbol: '£',
    defaultCountryCode: 'gb',
    location: 'gb',
    missingBusinessIdentifier: 'No CRN',
    businessIdentifierTooltipText:
      'Adding a CRN ensures Contractors interacting with you on E1 recognise you operate as a registered business. Only Contractors can see this information on E1.',
    builderLabel: 'Contractor',
    companyIdentifierNotFoundErrorMessage: 'CRN could not be found in the Companies House register',
    notSupportedCountryErrorMessage:
      'E1 does not currently check company registration numbers for this country.',
    technicalIssueErrorMessage:
      'An issue prevented us from checking this CRN. We’ll try again later.',
    builders: 'Contractors',
    invitations: 'enquiries',
    provinceFieldLabel: 'County',
  },
};

const en_NZ = {
  language: 'en_NZ',
  messages: {
    ...englishCommon,
    businessIdentifier: 'NZBN',
    currencySymbol: '$',
    defaultCountryCode: 'nz',
    provinceFieldLabel: 'Province',
    builders: 'Builders',
  },
};

export const localTranslations = [en_IE, en_AU, en_GB, en_NZ];

export type TranslationTerms =
  | keyof (typeof en_IE)['messages']
  | keyof (typeof en_AU)['messages']
  | keyof (typeof en_GB)['messages']
  | keyof (typeof en_NZ)['messages'];
