import {
  addressFields,
  AUGoogleToE1Mapping,
  E1AddressFields,
  E1AddressStringFields,
  fallbackGoogleToE1Mapping,
  googleAddressParts,
  IEGoogleToE1Mapping,
  UKGoogleToE1Mapping,
} from './mapping';

interface E1AddressInterface extends E1AddressFields {
  toObject(): E1Address;

  setProperty<K extends keyof E1AddressFields>(prop: K, value: E1AddressFields[K]): void;

  clone(): E1Address;

  toString(): string;

  toShortString(): string;
}

export class E1Address implements E1AddressInterface {
  constructor(
    public address: string | null = null,
    public address2: string | null = null,
    public address3: string | null = null,
    public suburb: string | null = null,
    public province: string | null = null,
    public city: string | null = null,
    public state: string | null = null,
    public postcode: string | null = null,
    public country: string | null = null,
    public longitude: number | null = null,
    public latitude: number | null = null, // eslint-disable-next-line no-empty-function
  ) {}

  /**
   * Map the curiously-named Google PlaceResult fields to E1Address normalisation
   */
  static fromGoogleResult(googleResult: google.maps.places.PlaceResult): E1Address {
    const e1Address = new E1Address();
    const { address_components: addressComponents, geometry } = googleResult;

    const location = geometry?.location ?? null;
    const country = addressComponents?.find((component) =>
      component.types.includes('country'),
    )?.long_name;

    if (addressComponents) {
      e1Address.mapGoogleToE1FieldsForCountry(addressComponents, country);
    }

    if (location) {
      e1Address.setLatitude(location.lat());
      e1Address.setLongitude(location.lng());
    }

    e1Address.normalise();

    return e1Address;
  }

  private mapGoogleToE1FieldsForCountry(
    addressComponents: google.maps.GeocoderAddressComponent[],
    country?: string,
  ): void {
    const mapAddressComponentsToFields = (mapping: Record<string, E1AddressStringFields[]>) => {
      addressComponents.forEach((component) => this.mapComponentToE1Fields(component, mapping));
    };

    switch (country) {
      case 'Australia':
        return mapAddressComponentsToFields(AUGoogleToE1Mapping);
      case 'United Kingdom':
        return mapAddressComponentsToFields(UKGoogleToE1Mapping);
      case 'Ireland':
        return mapAddressComponentsToFields(IEGoogleToE1Mapping);
      default:
        return mapAddressComponentsToFields(fallbackGoogleToE1Mapping);
    }
  }

  private mapComponentToE1Fields(
    component: google.maps.GeocoderAddressComponent,
    mapping: Record<string, E1AddressStringFields[]>,
  ): void {
    const { types, long_name: longName } = component;

    const e1FieldsForComponentType = types.flatMap((type) => mapping[type] || []);
    e1FieldsForComponentType.forEach((field) => this.setProperty(field, longName));

    if (
      !e1FieldsForComponentType.length &&
      types.some((type) => googleAddressParts.includes(type))
    ) {
      this.setAddress(this.address === null ? longName : `${this.address} ${longName}`);
    }
  }

  public setProperty<K extends keyof E1AddressFields>(prop: K, value: (typeof this)[K]): void {
    this[prop] = value;
  }

  setAddress(address: string) {
    this.address = address;
  }

  setLatitude(lat: number) {
    this.latitude = lat;
  }

  setLongitude(lon: number) {
    this.longitude = lon;
  }

  /**
   * Right now, `suburb` and `state` are used for AU (and the USA).
   * Whilst `city` and `province` are used for everyone else.
   * But Google addresses don't cleanly map the same way, since we might map another country's `suburb` and then
   * display the `city` input instead.
   * So we map everything and then normalise the country.
   */
  public normalise() {
    if (this.country === 'Australia') {
      this.province = null;
      this.city = null;
      return;
    }

    this.suburb = null;
    this.state = null;
  }

  public toString() {
    return addressFields
      .map((f) => this[f])
      .filter(Boolean)
      .join(' ');
  }

  /**
   * Formats the address to look like Google's suggestion
   */
  public toShortString() {
    const addressComponents: string[] = [];

    addressFields
      .filter((f) => f !== 'postcode')
      .forEach((field) => {
        const value = this[field];
        if (value && value !== 'null') {
          if (field === 'province' && this.province && this.province === this.state) {
            return;
          }
          addressComponents.push(value);
        }
      });

    return addressComponents.join(', ');
  }

  public toObject(): E1Address {
    return { ...this };
  }

  public clone(): E1Address {
    // eslint-disable-next-line fp/no-mutating-assign
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this.toObject());
  }
}
