import AddMultipleQuotesPage from './AddMultipleQuotesPage';
import AddSingleQuotePage from './AddSingleQuotePage';
import QuotePageLayout from '../../../../shared/Quotes/Page/QuotePageLayout';
import { FileManagerParams } from '@shared/FileUploader/types';
import { EntityId } from '@ascension/types';

type AddQuotePageProps = Pick<FileManagerParams, 'fileManagerLocale'> & {
  projectId: EntityId;
  returnUrl: string;
  packageId?: number;
  stageId?: number;
};

const AddQuotePage = ({
  fileManagerLocale,
  projectId,
  returnUrl,
  packageId,
  stageId,
}: AddQuotePageProps) => (
  <QuotePageLayout title="Add quotes" returnUrl={returnUrl}>
    {stageId ? (
      <AddSingleQuotePage
        fileManagerLocale={fileManagerLocale}
        projectId={projectId}
        returnUrl={returnUrl}
        packageId={packageId}
        stageId={stageId}
      />
    ) : (
      <AddMultipleQuotesPage
        fileManagerLocale={fileManagerLocale}
        projectId={projectId}
        returnUrl={returnUrl}
      />
    )}
  </QuotePageLayout>
);

export default AddQuotePage;
