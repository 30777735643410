// To be used as URL parametres. The backend will handle sending the event
export enum AnalyticsTrackedParam {
  IN_APP_SOURCE = 'ia_ssrc',
}

// To be used when sending an event directly via the frontend
export enum AnalyticsEventParam {
  IN_APP_SOURCE = 'analyticsInAppSource',
}

export enum SourceTrackingToken {
  GENERIC = 'www',
  UPGRADE_ROADBLOCK_AWARDED = 'www-awarded-roadblock',
  UPGRADE_ROADBLOCK_BUDGET = 'www-budget-roadblock',
  UPGRADE_ROADBLOCK_FIFTY = 'www-fifty-roadblock',
  UPGRADE_ROADBLOCK_SINGLE_TENDERER = 'www-single-tenderer-roadblock',
  UPGRADE_ROADBLOCK_AWARDED_REDACTED_EXPERIMENT = 'www-awarded-redacted-experiment-roadblock',
  UPGRADE_ROADBLOCK_BUDGET_REDACTED_EXPERIMENT = 'www-budget-redacted-experiment-roadblock',
  UPGRADE_HEADER_BUTTON = 'www-header-button',
  UPGRADE_MENU_BUTTON = 'www-topmenu-button',
  UPGRADE_SPECIFINDER_SETTINGS_KEYWORDS_LINK = 'www-specifinder-settings-keywords-link',
  UPGRADE_SPECIFINDER_SETTINGS_KEYWORDS_BUTTON = 'www-specifinder-settings-keywords-button',
  UPGRADE_ROADBLOCK_TENDERS = 'www-tenders-roadblock',
  UPGRADE_ROADBLOCK_WATCHLIST = 'www-watchlist-roadblock',
  UPGRADE_ROADBLOCK_ADDRESSBOOK = 'www-addressbook-roadblock',
  PLAN_SELECT_PAGE = 'www-plan-select-page',
  PROFESSIONAL_COMPARE_PLANS_LINK = 'www-professional-compare-plans-link',
  PROFESSIONAL_PLUS_COMPARE_PLANS_LINK = 'www-pro-plus-compare-plans-link',
  UPGRADE_ROADBLOCK_CONSULTANTS = 'www-consultants-upgrade-roadblock',
  LEARN_MORE_ROADBLOCK_CONSULTANTS = 'www-consultants-learnmore',
  UPGRADE_ROADBLOCK_SSC_SUBBIES = 'www-ssc-subbies-upgrade-roadblock',
  BUILDER_DIRECTORY_UPGRADE_BUTTON = 'www-builder-directory-upgrade',
  BUILDER_DETAILS_PAGE_UPGRADE_BUTTON = 'www-builder-details-upgrade',
  UPGRADE_ROADBLOCK_LEADS_OTHER_BUILDERS = 'www-leads-other-builders-upgrade-roadblock',
  UPGRADE_ROADBLOCK_LEADS_SPECIFINDER = 'www-specifinder-upgrade-roadblock',
  UPGRADE_ROADBLOCK_LEADS_QUOTE_VIEWED_BY_BUILDER = 'www-quotes-view-status-roadblock',
}

export enum SignUpEvent {
  GENERIC = 'www-signup',
  INVITE_SIGNUP = 'invite-signup',
  SUBBIE_SIGN_UP = 'www-signup sub',
  SUPPLIER_SIGN_UP = 'www-signup supp',
}
