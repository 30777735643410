import { Icon, IconName } from '@estimateone/frontend-components';
import styles from './styles.scss';

type ReturnLinkProps = { url?: string };

const ReturnLink = ({ url }: ReturnLinkProps) => (
  <a className={styles.returnLink} href={url}>
    <Icon name={IconName.ChevronLeft} size="0.7em" /> Back
  </a>
);

export default ReturnLink;
