import { Icon, IconName, joinClassNames } from '@estimateone/frontend-components';
import { IconProps } from '@estimateone/frontend-components/src/components/Graphics/Icons/_deprecated/Icon';
import { SortDirection } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

const getSortIcon = (direction?: SortDirection) => {
  switch (direction) {
    case SortDirection.ASC:
      return IconName.ChevronUp;
    case SortDirection.DESC:
      return IconName.ChevronDown;
    default:
      return IconName.Reorder;
  }
};

type SortIconProps = Omit<IconProps, 'name'> & {
  sortDisabled?: boolean;
  sortDirection?: SortDirection;
};

const SortIcon = ({ sortDisabled = false, sortDirection, ...props }: SortIconProps) => {
  const classes = joinClassNames(styles.sortIcon, sortDirection !== undefined && styles.sorted);

  if (sortDisabled) return null;

  return (
    <span className={classes}>
      <Icon name={getSortIcon(sortDirection)} {...props} />
    </span>
  );
};

export default SortIcon;
