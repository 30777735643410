import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

type BaseImageProps = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const getPrefixedPath = (originalSrc: string, prefix: string) =>
  `${prefix}/images/${originalSrc.split('/').pop()}`;

const Image = ({ src, alt, className, ...props }: BaseImageProps) => {
  const prefix = document.getElementsByTagName('body')?.item(0)?.dataset.cdnRoot || undefined;
  const path = prefix && src ? getPrefixedPath(src, prefix) : src;

  return <img className={className} alt={alt} src={path} {...props} />;
};

export default Image;
