import LoadingSpinner from './index';
import styles from './styles.scss';

const PageLoadingSpinner = () => (
  <div className={styles.loadingSpinnerContainer} data-testid="loading-spinner">
    <LoadingSpinner size="medium" center />
  </div>
);

export default PageLoadingSpinner;
