import { useState } from 'react';
import { useField, useForm } from 'react-final-form';
import {
  Col,
  Row,
  Button,
  ButtonVariant,
  Fieldset,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import FileUploader, { TransformedFile } from '../../../FileUploader';
import { QuoteExtraFiles } from '../../QuoteFiles';
import { QuoteFileRestrictions } from '@shared/FileUploader/QuoteFileRestrictions';
import type { FileManagerParams } from '@shared/FileUploader/types';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { QuoteFileAction, QuoteFormCommonFieldName, UploadCategory } from '@ascension/enums';
import type { QuoteExtraFilesValue, QuoteFormsValues } from '../types';

const QuoteExtraFilesField = ({
  context,
  fileManagerLocale,
  parentType,
  parentId,
}: FileManagerParams) => {
  const { change: updateFieldValue } = useForm<QuoteFormsValues>();

  const [showExtraFileUploader, setShowExtraFileUploader] = useState(false);
  const {
    input: { value: files },
    meta: { error, submitError, touched },
  } = useField<QuoteExtraFilesValue>(QuoteFormCommonFieldName.ExtraFiles);

  const hasFiles = files && files.length !== 0;

  const handleUploadComplete = (existingFiles: TransformedFile[], newFiles: TransformedFile[]) => {
    updateFieldValue(QuoteFormCommonFieldName.ExtraFiles, [...existingFiles, ...newFiles]);
    setShowExtraFileUploader(false);
  };

  return (
    <FieldWrapper errors={error || submitError} showErrors={touched}>
      <Fieldset
        tabIndex={0}
        name={QuoteFormCommonFieldName.ExtraFiles}
        id="quote-extra-files"
        legend="Extra files"
        hideLegend={!hasFiles}
      >
        {hasFiles && (
          <Row>
            <Col span={12}>
              <QuoteExtraFiles
                action={QuoteFileAction.Remove}
                files={files}
                onRemove={(file) =>
                  updateFieldValue(
                    QuoteFormCommonFieldName.ExtraFiles,
                    files.filter((f) => f !== file),
                  )
                }
              />
            </Col>
          </Row>
        )}

        <Row>
          {showExtraFileUploader ? (
            <Col span={12}>
              <FileUploader
                context={context}
                existingFiles={files || []}
                fileManagerLocale={fileManagerLocale}
                dragDropMessage="Drag extra files (such as brochures or insurance docs) here, or"
                uppyOptions={{
                  id: 'extraFiles',
                  restrictions: {
                    maxFileSize: QuoteFileRestrictions.MaxFileSize,
                    allowedFileTypes: QuoteFileRestrictions.AllowedAttachmentFileTypes,
                  },
                }}
                category={UploadCategory.QUOTE_EXTRA_FILE}
                parentType={parentType}
                parentId={parentId}
                onAllUploadsComplete={(newFiles) => {
                  // TODO: remove onAllUploadsComplete prop and use onUploadComplete for all upload destinations
                  handleUploadComplete(files, newFiles);
                }}
              />
            </Col>
          ) : (
            <Col span={3}>
              <Button variant={ButtonVariant.Grey} onClick={() => setShowExtraFileUploader(true)}>
                <Icon name={IconName.Add} size="0.8em" marginRight="large" />
                Attach extra files
              </Button>
            </Col>
          )}
        </Row>
      </Fieldset>
    </FieldWrapper>
  );
};

export default QuoteExtraFilesField;
