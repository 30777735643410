import { useField } from 'react-final-form';
import { fieldNameGenerator, packageToOption } from './utils';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { FieldError } from '@shared/FinalForm/types';
import { SingleSelect } from '@shared/Select';
import { comparePackageTitlesAlphaFullSetFirst } from '@shared/Util/sorting';
import { StagePackagesSubField } from '../enums';
import {
  PackageOptionValue,
  StagePackageIsSelectedValue,
  StagePackageIsWithdrawnValue,
  StagePackagePackageValue,
} from '../types';
import { ActivePackage } from './types';
import { EntityId } from '@ascension/types';

type PackageSelectorFieldProps = {
  parentFieldName: string;
  stageId: EntityId;
  activePackages: ActivePackage[];
  errors?: FieldError[];
};

const PackageSelectorField = ({
  parentFieldName,
  stageId,
  activePackages,
  errors,
}: PackageSelectorFieldProps) => {
  const getFullSubFieldName = fieldNameGenerator<StagePackagesSubField>(parentFieldName);
  const isSelectedFieldName = getFullSubFieldName(StagePackagesSubField.IsSelected);
  const isWithdrawnFieldName = getFullSubFieldName(StagePackagesSubField.IsWithdrawn);
  const packageFieldName = getFullSubFieldName(StagePackagesSubField.Package);

  const {
    input: { checked: isSelected },
  } = useField<StagePackageIsSelectedValue>(isSelectedFieldName, { type: 'checkbox' });

  const {
    input: { value: isWithdrawn },
  } = useField<StagePackageIsWithdrawnValue>(isWithdrawnFieldName);

  const {
    input: { value: packageValue, onChange, ...packageInputProps },
  } = useField<StagePackagePackageValue>(packageFieldName);

  const hasErrors = errors && errors.length !== 0;

  const packages = [...activePackages].sort((a, b) =>
    comparePackageTitlesAlphaFullSetFirst(a.title, b.title),
  );

  return (
    <FieldWrapper errors={errors} showErrors={hasErrors}>
      <SingleSelect<PackageOptionValue>
        id={`quote-stage-package-${stageId}-package`}
        label={`Package for stage ${stageId}`}
        hideLabel
        placeholder="Select a package"
        options={packages.map(packageToOption)}
        isDisabled={isWithdrawn || !isSelected}
        value={packageValue.value}
        onChange={({ selectedOption }) => onChange(selectedOption)}
        {...packageInputProps}
      />
    </FieldWrapper>
  );
};

export default PackageSelectorField;
