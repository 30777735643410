import { FormEvent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button } from '@estimateone/frontend-components';
import { ADD_NOTE } from './mutations';
import { EntityId } from '../../../../types';
import { AddQuoteNote, AddQuoteNoteVariables } from './types/AddQuoteNote';
import styles from './styles.scss';

const QuoteNoteForm = ({ quoteId }: { quoteId: EntityId }) => {
  const [noteText, setNoteText] = useState<string>('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [addNote, { loading, data }] = useMutation<AddQuoteNote, AddQuoteNoteVariables>(ADD_NOTE);

  const handleNoteSubmit = async (e: FormEvent) => {
    e.preventDefault();
    return addNote({
      variables: { input: { id: quoteId, note: noteText } },
    });
  };

  const resetForm = () => {
    setNoteText('');
    setErrorMessages([]);
  };

  useEffect(() => {
    if (data) {
      switch (data.addNoteToQuote.__typename) {
        case 'Errors':
          setErrorMessages(data.addNoteToQuote.errors.map(({ message }) => message));
          break;
        case 'Quote':
        default:
          resetForm();
          break;
      }
    }
  }, [data]);

  const hasErrors = errorMessages.length !== 0;

  return (
    <form onSubmit={handleNoteSubmit} aria-label="Quote Note Form">
      <fieldset className={`${styles.noteTextarea} ${hasErrors ? 'has-error' : ''}`}>
        <textarea
          value={noteText}
          onChange={(event) => setNoteText(event.target.value)}
          className="form-control"
          rows={3}
          placeholder="Compose a new note here"
        />
        {hasErrors && (
          <div className={styles.errorMessage}>
            {errorMessages.map((msg) => (
              <p key={msg}>{msg}</p>
            ))}
          </div>
        )}
      </fieldset>

      <Button isDisabled={loading}>Add note</Button>
    </form>
  );
};

export default QuoteNoteForm;
