import { gql } from '@apollo/client';

export const ADD_NOTE = gql`
  mutation AddQuoteNote($input: AddNoteToQuoteInput!) {
    addNoteToQuote(input: $input) {
      __typename
      ... on Quote {
        id
        notes {
          id
          authorName
          text
          createdAt
        }
      }
      ... on Errors {
        errors {
          field
          message
        }
      }
    }
  }
`;

export const DELETE_QUOTE = gql`
  mutation DeleteQuote($input: DeleteQuoteInput!) {
    deleteQuote(input: $input) {
      id
      deletedAt
    }
  }
`;

export const MARK_QUOTE_AS_VIEWED = gql`
  mutation MarkQuoteAsViewed($input: MarkQuoteAsViewedInput!) {
    markQuoteAsViewed(input: $input) {
      id
    }
  }
`;
