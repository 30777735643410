import { useQuery } from '@apollo/client';
import AddQuoteForm from '../../../features/QuotesFeature/AddQuoteForm';
import NoStagesError from './NoStagesError';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import { FileManagerParams } from '@shared/FileUploader/types';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { CurrencyInfoContextProvider } from '@shared/context';
import { GET_PROJECT_WITH_QUOTABLE_STAGES } from './queries';
import { getCurrencyInfo } from '@ascension/enums';
import {
  GetProjectAndQuotableStages,
  GetProjectAndQuotableStagesVariables,
} from './types/GetProjectAndQuotableStages';
import { EntityId } from '@ascension/types';

export type AddSingleQuotePageProps = Pick<FileManagerParams, 'fileManagerLocale'> & {
  projectId: EntityId;
  stageId: EntityId;
  packageId?: EntityId;
  returnUrl: string;
};

const AddSingleQuotePage = ({
  fileManagerLocale,
  projectId,
  stageId,
  packageId,
  returnUrl,
}: AddSingleQuotePageProps) => {
  const { data, loading } = useQuery<
    GetProjectAndQuotableStages,
    GetProjectAndQuotableStagesVariables
  >(GET_PROJECT_WITH_QUOTABLE_STAGES, {
    variables: { id: projectId },
  });

  if (loading || !data) return <PageLoadingSpinner />;

  const {
    project: { name: projectName, address: projectAddress, quotableStages },
  } = data;

  const selectedStage = quotableStages.find(({ id }) => id === stageId);

  if (!selectedStage) {
    return (
      <NoStagesError
        message="This tender is no longer available for quoting."
        returnUrl={returnUrl}
      />
    );
  }

  const currencyInfo = getCurrencyInfo(selectedStage.country.id);

  return (
    <CurrencyInfoContextProvider currencyInfo={currencyInfo}>
      <AddQuoteForm
        fileManagerLocale={fileManagerLocale}
        projectId={projectId}
        projectName={projectName}
        projectAddress={projectAddress ? graphqlTypeToString(projectAddress) : 'Unknown location'}
        stages={[selectedStage]}
        returnUrl={returnUrl}
        defaultPackageId={packageId}
      />
    </CurrencyInfoContextProvider>
  );
};

export default AddSingleQuotePage;
