/* eslint-disable */
// @generated
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  EntityId: { input: number; output: number; }
}

export enum AccountFileType {
  CAPABILITY_STATEMENT = 'CAPABILITY_STATEMENT',
  INSURANCE = 'INSURANCE',
  QUOTE_ADDITIONAL = 'QUOTE_ADDITIONAL'
}

export enum AccountType {
  ADMIN = 'ADMIN',
  ARCHITECT = 'ARCHITECT',
  BUILDER = 'BUILDER',
  E1 = 'E1',
  ENGINEER = 'ENGINEER',
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  SUPPLIER = 'SUPPLIER'
}

export interface AccountWatchlistAssignInput {
  projectId: Scalars['EntityId']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  teamMemberId: Scalars['EntityId']['input'];
}

export interface ActionMergeRequestInput {
  id: Scalars['EntityId']['input'];
}

export interface AddConnectionPreferenceInput {
  contactId: Scalars['EntityId']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['EntityId']['input'];
  type: ConnectionPreferenceType;
}

export interface AddCreditCardInput {
  secureCardToken: Scalars['String']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface AddKeywordsForKeywordTypeInput {
  keywordType: SearchPhraseCategory;
  keywords: Array<Scalars['String']['input']>;
}

export interface AddNoteToProjectInput {
  projectId: Scalars['EntityId']['input'];
  text: Scalars['String']['input'];
}

export interface AddNoteToQuoteInput {
  id: Scalars['EntityId']['input'];
  note: Scalars['String']['input'];
}

export interface AddSpeciFinderKeywordAndTypeInput {
  keyword: Scalars['String']['input'];
  keywordType: SearchPhraseCategory;
}

export interface AddSpeciFinderKeywordVariationsAndTypeInput {
  excludes?: InputMaybe<Scalars['String']['input']>;
  includes?: InputMaybe<Scalars['String']['input']>;
  keyword: Scalars['String']['input'];
  keywordType: SearchPhraseCategory;
  variations: Array<Scalars['String']['input']>;
}

export interface AddSpecificationInsightsReportFilterInput {
  category: Scalars['Int']['input'];
  location: Scalars['Int']['input'];
  phrase: Scalars['String']['input'];
}

export interface AddSpecificationInsightsReportInput {
  filters: Array<AddSpecificationInsightsReportFilterInput>;
  name: Scalars['String']['input'];
}

export interface AddUserFeedbackInput {
  feedbackText?: InputMaybe<Scalars['String']['input']>;
  score: UserFeedbackScore;
  topic: UserFeedbackTopic;
}

export enum AddonName {
  CONSULTANT_DETAILS = 'CONSULTANT_DETAILS'
}

export enum AddressBookDataSource {
  BUILDER_AND_PROFILE = 'BUILDER_AND_PROFILE',
  BUILDER_CREATED = 'BUILDER_CREATED',
  IMPORT = 'IMPORT',
  ITP = 'ITP',
  NETWORK_INVITATION_PROFILE = 'NETWORK_INVITATION_PROFILE',
  PROFILE = 'PROFILE',
  RFQ = 'RFQ',
  SHADOW_PROFILE = 'SHADOW_PROFILE'
}

export enum BillingCycle {
  ONE_MONTH = 'ONE_MONTH',
  TWELVE_MONTHS = 'TWELVE_MONTHS'
}

export enum BusinessEntityType {
  REGISTERED_BUSINESS = 'REGISTERED_BUSINESS',
  SOLE_TRADER = 'SOLE_TRADER'
}

export enum ConnectionPreferenceType {
  EQUIVALENT = 'EQUIVALENT',
  SPECIFIED = 'SPECIFIED',
  SPECIFIED_AND_EQUIVALENT = 'SPECIFIED_AND_EQUIVALENT'
}

export enum ConsultantDiscipline {
  ARCHITECT = 'ARCHITECT',
  ARCHITECT_INTERIOR = 'ARCHITECT_INTERIOR',
  ARCHITECT_LANDSCAPE = 'ARCHITECT_LANDSCAPE',
  CONSULTANT_FIRE = 'CONSULTANT_FIRE',
  CONSULTANT_LIGHTING = 'CONSULTANT_LIGHTING',
  CONSULTANT_SUSTAINABILITY = 'CONSULTANT_SUSTAINABILITY',
  CONSULTANT_TECHNOLOGY = 'CONSULTANT_TECHNOLOGY',
  ENGINEER_ACOUSTIC = 'ENGINEER_ACOUSTIC',
  ENGINEER_CIVIL = 'ENGINEER_CIVIL',
  ENGINEER_ELECTRICAL = 'ENGINEER_ELECTRICAL',
  ENGINEER_FIRE = 'ENGINEER_FIRE',
  ENGINEER_HYDRO = 'ENGINEER_HYDRO',
  ENGINEER_MECH = 'ENGINEER_MECH',
  ENGINEER_STRUCTURAL = 'ENGINEER_STRUCTURAL',
  ENGINEER_THERMAL = 'ENGINEER_THERMAL'
}

export interface ConsultantInsightsMatchedProjectsInput {
  consultantId: Scalars['EntityId']['input'];
  consultantTypeId: Scalars['EntityId']['input'];
  locationId: Scalars['Int']['input'];
  searchPhraseId: Scalars['EntityId']['input'];
}

export interface ConsultantInsightsReportInput {
  consultantTypeId: Scalars['EntityId']['input'];
  locationId: Scalars['EntityId']['input'];
  searchPhraseIds: Array<Scalars['EntityId']['input']>;
}

export interface ConsultantProjectsInput {
  id: Scalars['EntityId']['input'];
  page: Scalars['Int']['input'];
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy: Scalars['String']['input'];
  sortDir: SortDirection;
  status: SearchStatus;
}

export interface ConsultantsInput {
  locationIds: Array<Scalars['Int']['input']>;
  onlyFavourites: Scalars['Boolean']['input'];
  page: Scalars['Int']['input'];
  perPage?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<Scalars['String']['input']>;
  sortDir: SortDirection;
  types: Array<Scalars['Int']['input']>;
}

export enum ContactType {
  CONSTRUCTION = 'CONSTRUCTION',
  ESTIMATING = 'ESTIMATING'
}

export enum ContractSizeEnum {
  SIZE_1M = 'SIZE_1M',
  SIZE_5M = 'SIZE_5M',
  SIZE_10K = 'SIZE_10K',
  SIZE_10M = 'SIZE_10M',
  SIZE_25M = 'SIZE_25M',
  SIZE_50K = 'SIZE_50K',
  SIZE_50M_PLUS = 'SIZE_50M_PLUS',
  SIZE_100K = 'SIZE_100K',
  SIZE_250K = 'SIZE_250K',
  SIZE_500K = 'SIZE_500K'
}

export interface CreateAccountInviteInput {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
}

export interface CreateChargebeeCheckoutSessionInput {
  billingCycle: Scalars['Int']['input'];
  productId: Scalars['EntityId']['input'];
}

export interface CreateQuotesForProjectInput {
  additionalFiles: Array<UncommittedFileInput>;
  amount: Scalars['Float']['input'];
  confirmFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  packageIds: Array<Scalars['EntityId']['input']>;
  projectId: Scalars['EntityId']['input'];
  quoteFile: UncommittedFileInput;
}

export interface CreateRetentionSessionInput {
  licenseId: Scalars['EntityId']['input'];
}

export enum CreditCardCompany {
  AMEX = 'AMEX',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA'
}

export enum CriticalStatus {
  IS_CRITICAL = 'IS_CRITICAL',
  NO_CHANGE = 'NO_CHANGE'
}

export interface DeleteCreditCardInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteQuoteInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteSearchInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteSpeciFinderKeywordInput {
  id: Scalars['EntityId']['input'];
}

export interface DeleteSpecificationInsightsReportInput {
  id: Scalars['EntityId']['input'];
}

export interface DiscontinueLicenseInput {
  licenseId: Scalars['EntityId']['input'];
  reasons: Array<ReasonInput>;
}

export enum EmailSubscriptionOption {
  ALWAYS = 'ALWAYS',
  DAILY = 'DAILY',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  WEEKLY = 'WEEKLY'
}

export enum EmailSubscriptionType {
  HAPPY_MONDAY = 'HAPPY_MONDAY',
  MIDWEEK_UPDATE = 'MIDWEEK_UPDATE',
  SPECIFINDER_KEYWORD_REPORT = 'SPECIFINDER_KEYWORD_REPORT',
  TENDER_INVITATION_REMINDER = 'TENDER_INVITATION_REMINDER',
  TENDER_REMINDERS = 'TENDER_REMINDERS',
  TENDER_STATUS_CHANGES = 'TENDER_STATUS_CHANGES',
  WATCHLIST_UPDATE = 'WATCHLIST_UPDATE',
  WEEKLY_REPORT = 'WEEKLY_REPORT'
}

export enum EnquiryNature {
  GENERAL = 'GENERAL',
  RENEWAL = 'RENEWAL',
  TECHNICAL = 'TECHNICAL',
  UPGRADE = 'UPGRADE'
}

export enum ExperimentFilter {
  USER = 'USER'
}

export enum ExperimentName {
  ADOPT_LITE_TO_ESSENTIAL_UPGRADE = 'ADOPT_LITE_TO_ESSENTIAL_UPGRADE',
  BLD_REQUEST_PROFILE_UPDATE = 'BLD_REQUEST_PROFILE_UPDATE',
  BUILDER_DOC_RENAME_WIZARD = 'BUILDER_DOC_RENAME_WIZARD',
  BUILDER_MENTION_TEAMMATES_IN_NOTES = 'BUILDER_MENTION_TEAMMATES_IN_NOTES',
  BUILDER_REDACTED_SUBBIE_PROFILE = 'BUILDER_REDACTED_SUBBIE_PROFILE',
  BUILDER_SHAREPOINT_INTEGRATION = 'BUILDER_SHAREPOINT_INTEGRATION',
  /** @deprecated internal */
  BUILDER_TEST = 'BUILDER_TEST',
  BUILDER_VISUAL_DOC_DIFFS = 'BUILDER_VISUAL_DOC_DIFFS',
  /** @deprecated no longer in use */
  CHARGEBEE_CHECKOUT_EXPERIENCE = 'CHARGEBEE_CHECKOUT_EXPERIENCE',
  CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW = 'CLARIFY_TENDER_WIZARD_UNIFIED_INVITATION_FLOW',
  DISCOVER_LEADS_MGT = 'DISCOVER_LEADS_MGT',
  DISCOVER_LEADS_SPECIFINDER = 'DISCOVER_LEADS_SPECIFINDER',
  DISCOVER_LEADS_VIEWED_QUOTE = 'DISCOVER_LEADS_VIEWED_QUOTE',
  DISCOVER_LEADS_VIEW_OTHER_BUILDERS = 'DISCOVER_LEADS_VIEW_OTHER_BUILDERS',
  DISCOVER_QUOTES_VIEWED_QUOTE = 'DISCOVER_QUOTES_VIEWED_QUOTE',
  DISCOVER_SUNSET_INVITATIONS_BANNER = 'DISCOVER_SUNSET_INVITATIONS_BANNER',
  FUSION_PREQUALIFICATION = 'FUSION_PREQUALIFICATION',
  FUSION_SUBBIE_CHAS_TRIAL = 'FUSION_SUBBIE_CHAS_TRIAL',
  ITP_OTP_AUTHENTICATOR = 'ITP_OTP_AUTHENTICATOR',
  NEW_IN_SUBBIE_CREDIT_EXPERIMENT = 'NEW_IN_SUBBIE_CREDIT_EXPERIMENT',
  PPT_CHARGEBEE_FREE_TO_PAID_FLOW = 'PPT_CHARGEBEE_FREE_TO_PAID_FLOW',
  PRISM_CHARGEBEE_RETENTION_MODULE = 'PRISM_CHARGEBEE_RETENTION_MODULE',
  SPECIFINDER_TRIAL_WITH_ONE_KEYWORD = 'SPECIFINDER_TRIAL_WITH_ONE_KEYWORD',
  SUBBIE_ACCOUNT_ONBOARDING_FORM = 'SUBBIE_ACCOUNT_ONBOARDING_FORM',
  SUBBIE_ACTIVITY_FEED = 'SUBBIE_ACTIVITY_FEED',
  SUBBIE_CORE_TRADES = 'SUBBIE_CORE_TRADES',
  SUBBIE_NETWORK_ENROLMENT_FORM = 'SUBBIE_NETWORK_ENROLMENT_FORM',
  SUBBIE_PRICING_TREATMENT_1 = 'SUBBIE_PRICING_TREATMENT_1',
  SUBBIE_PRICING_TREATMENT_2 = 'SUBBIE_PRICING_TREATMENT_2',
  SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS = 'SUBBIE_SHOW_PROFILE_ONBOARDING_FOR_FREE_ACCOUNTS',
  SUBBIE_SPECIFINDER_INVITATIONS = 'SUBBIE_SPECIFINDER_INVITATIONS',
  /** @deprecated internal */
  SUBBIE_TEST = 'SUBBIE_TEST',
  SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE = 'SUBBLIER_PROFESSIONAL_BUNDLED_PACKAGE',
  SUPPLIER_FIFTY_REDACTED = 'SUPPLIER_FIFTY_REDACTED',
  SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API = 'SUPPLIER_INSIGHTS_EMAIL_USE_NEW_API',
  SUPPLIER_PRICING_TREATMENT_1 = 'SUPPLIER_PRICING_TREATMENT_1',
  SUPPLIER_PRICING_TREATMENT_2 = 'SUPPLIER_PRICING_TREATMENT_2',
  SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY = 'SUPPLIER_SPECIFINDER_DOC_FIRST_PUBLISHED_QUERY',
  SUPPLIER_SSC_TRIAL = 'SUPPLIER_SSC_TRIAL'
}

export interface FavouriteConsultantInput {
  /** Consultant ID */
  id: Scalars['EntityId']['input'];
  isFavourite: Scalars['Boolean']['input'];
}

export enum FeatureName {
  AB_AUDIT = 'AB_AUDIT',
  AB_CARDDAV = 'AB_CARDDAV',
  AB_ENABLED = 'AB_ENABLED',
  AB_MULTIPLE_CONTACT_TYPES_ENABLED = 'AB_MULTIPLE_CONTACT_TYPES_ENABLED',
  ACCOUNT_USERS_RESTRICT = 'ACCOUNT_USERS_RESTRICT',
  ACTION_RFQ_ENABLED = 'ACTION_RFQ_ENABLED',
  ADDRESS_BOOK_APP = 'ADDRESS_BOOK_APP',
  ADVANCED_PROJECT_DETAILS = 'ADVANCED_PROJECT_DETAILS',
  ADVANCED_SEARCH = 'ADVANCED_SEARCH',
  ADVANCED_SPEC_SEARCH = 'ADVANCED_SPEC_SEARCH',
  BUILDER_DIRECTORY = 'BUILDER_DIRECTORY',
  BUILDER_FIXED_PRICE_QUOTES = 'BUILDER_FIXED_PRICE_QUOTES',
  BUILDER_INCOGNITO_ENABLED = 'BUILDER_INCOGNITO_ENABLED',
  BUILDER_PROFILE = 'BUILDER_PROFILE',
  BUILDER_QUOTES = 'BUILDER_QUOTES',
  COMPANY_PROFILE = 'COMPANY_PROFILE',
  COMPANY_PROFILE_ADVANCED = 'COMPANY_PROFILE_ADVANCED',
  /** Legacy construction feature */
  CONSTRUCTION_CORRESPONDENCE = 'CONSTRUCTION_CORRESPONDENCE',
  /** Legacy construction feature */
  CONSTRUCTION_DELIVERY = 'CONSTRUCTION_DELIVERY',
  /** Legacy construction feature */
  CONSTRUCTION_MODE_ENABLED = 'CONSTRUCTION_MODE_ENABLED',
  CONSULTANT_DETAILS = 'CONSULTANT_DETAILS',
  CONSULTANT_INSIGHTS = 'CONSULTANT_INSIGHTS',
  CREDITS_LITE_TIER = 'CREDITS_LITE_TIER',
  DIRECTORY_ENABLED = 'DIRECTORY_ENABLED',
  ENTERPRISE_SSO = 'ENTERPRISE_SSO',
  ITP_ENABLED = 'ITP_ENABLED',
  ITP_REBRANDING_ENABLED = 'ITP_REBRANDING_ENABLED',
  LEADS_MANAGEMENT = 'LEADS_MANAGEMENT',
  MARKET_INSIGHTS = 'MARKET_INSIGHTS',
  MORE_RESTRICTIVE_BUDGET_PAYWALL = 'MORE_RESTRICTIVE_BUDGET_PAYWALL',
  NETWORK_CONNECTIONS = 'NETWORK_CONNECTIONS',
  NETWORK_SEARCH = 'NETWORK_SEARCH',
  NETWORK_UNIFIED_PROFILE = 'NETWORK_UNIFIED_PROFILE',
  NETWORK_VIEW_MARKET_STATISTICS_ENABLED = 'NETWORK_VIEW_MARKET_STATISTICS_ENABLED',
  OMIT_COMPETITOR_DETAILS = 'OMIT_COMPETITOR_DETAILS',
  PROCUREMENT_PHASE_ENABLED = 'PROCUREMENT_PHASE_ENABLED',
  PRODUCT_INSIGHTS_REPORT = 'PRODUCT_INSIGHTS_REPORT',
  PROJECT_COVER_SHEETS = 'PROJECT_COVER_SHEETS',
  /** @deprecated no longer in use */
  PROJECT_SPEC_SEARCH_MAX = 'PROJECT_SPEC_SEARCH_MAX',
  PROJECT_VIEW_AWARDED_ENABLED = 'PROJECT_VIEW_AWARDED_ENABLED',
  PROJECT_VIEW_TENDERERS = 'PROJECT_VIEW_TENDERERS',
  PROJECT_VIEW_VALUE_MAX = 'PROJECT_VIEW_VALUE_MAX',
  QUOTE_VIEWED_BY_BUILDER = 'QUOTE_VIEWED_BY_BUILDER',
  QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK = 'QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK',
  RFQ_ENGAGEMENT = 'RFQ_ENGAGEMENT',
  SPECIFICATION_INSIGHTS = 'SPECIFICATION_INSIGHTS',
  SPECIFINDER_ADVANCED_SEARCH = 'SPECIFINDER_ADVANCED_SEARCH',
  SPECIFINDER_CAN_SEE_UPGRADE_HOOK = 'SPECIFINDER_CAN_SEE_UPGRADE_HOOK',
  SPECIFINDER_KEYWORD_VARIATIONS = 'SPECIFINDER_KEYWORD_VARIATIONS',
  SPECIFINDER_SAVED_KEYWORDS = 'SPECIFINDER_SAVED_KEYWORDS',
  SSC_5_CREDIT = 'SSC_5_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_10_CREDIT = 'SSC_10_CREDIT',
  SSC_15_CREDIT = 'SSC_15_CREDIT',
  /** @deprecated to be removed, no longer in use */
  SSC_20_CREDIT = 'SSC_20_CREDIT',
  SSC_20_TOP_UP_CREDIT = 'SSC_20_TOP_UP_CREDIT',
  SSC_30_CREDIT = 'SSC_30_CREDIT',
  SSC_50_CREDIT = 'SSC_50_CREDIT',
  SSC_50_TOP_UP_CREDIT = 'SSC_50_TOP_UP_CREDIT',
  /** @deprecated no longer in use, but still in the db */
  STAGE_FILES_ENABLED = 'STAGE_FILES_ENABLED',
  SUBBIE_ADDONS = 'SUBBIE_ADDONS',
  SUBBIE_PROFILE = 'SUBBIE_PROFILE',
  SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT = 'SUBBIE_PROJECT_UNLOCK_1_BONUS_CREDIT',
  SUBBIE_SEE_ALL_TENDERING_BUILDERS = 'SUBBIE_SEE_ALL_TENDERING_BUILDERS',
  TENDER_CALENDAR_SYNC = 'TENDER_CALENDAR_SYNC',
  TENDER_CREATE_PRIVATE_ENABLED = 'TENDER_CREATE_PRIVATE_ENABLED',
  TENDER_CREATE_PUBLIC_ENABLED = 'TENDER_CREATE_PUBLIC_ENABLED',
  TENDER_HANDOVER = 'TENDER_HANDOVER',
  TENDER_INVITE_MAX = 'TENDER_INVITE_MAX',
  TENDER_MARKET_VIEW = 'TENDER_MARKET_VIEW',
  TENDER_NOTICEBOARD_ENABLED = 'TENDER_NOTICEBOARD_ENABLED',
  TENDER_RETENDER = 'TENDER_RETENDER',
  TENDER_SUGGESTION_ENABLED = 'TENDER_SUGGESTION_ENABLED',
  TENDER_VIEW_MAX = 'TENDER_VIEW_MAX',
  UNREDACT_FIFTY = 'UNREDACT_FIFTY',
  WATCHLIST_ASSIGN_PROJECT_TO_USER = 'WATCHLIST_ASSIGN_PROJECT_TO_USER'
}

export interface GenericKeyValuePair {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
}

export interface HistoricalProjectInput {
  builderName: Scalars['String']['input'];
  category: StageCategory;
  completionDate: Scalars['DateTime']['input'];
  contractSize: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  linkedProjectId?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
}

export enum InterestLevel {
  ASSIGNED = 'ASSIGNED',
  EXPLICITLY_RESET = 'EXPLICITLY_RESET',
  INTERESTED = 'INTERESTED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  QUOTED = 'QUOTED',
  QUOTING = 'QUOTING',
  UNACTIONED = 'UNACTIONED'
}

export enum InvoiceStatus {
  CANCELLED = 'CANCELLED',
  NOT_PAID = 'NOT_PAID',
  OUTSTANDING = 'OUTSTANDING',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAYMENT_DUE = 'PAYMENT_DUE',
  PENDING = 'PENDING',
  POSTED = 'POSTED',
  VOIDED = 'VOIDED'
}

export enum LettingScheduleCustomColumnIdentifier {
  CUSTOM_COLUMN_1 = 'CUSTOM_COLUMN_1',
  CUSTOM_COLUMN_2 = 'CUSTOM_COLUMN_2'
}

export enum LettingSchedulePriority {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export interface LogSupplierContactViewInput {
  projectId: Scalars['EntityId']['input'];
  supplierAccountId: Scalars['EntityId']['input'];
}

export interface MarkQuoteAsViewedInput {
  id: Scalars['EntityId']['input'];
}

export interface NotifyCancelLicencesIntentInput {
  cancellationReason: Scalars['String']['input'];
}

export interface OfficeServiceAreaInput {
  boundaryIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  countryId?: InputMaybe<Scalars['EntityId']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
}

export enum ProductFilter {
  NATIONAL = 'NATIONAL',
  RECOMMENDED = 'RECOMMENDED'
}

export enum ProductTag {
  BUILDER_FREE = 'BUILDER_FREE',
  BUILDER_MEMBER = 'BUILDER_MEMBER',
  SUBBIE_ADDON = 'SUBBIE_ADDON',
  SUBBIE_FREE = 'SUBBIE_FREE',
  SUBBIE_MEMBER = 'SUBBIE_MEMBER',
  SUBBIE_PRICING_EXPERIMENT = 'SUBBIE_PRICING_EXPERIMENT',
  SUBBIE_PRICING_EXPERIMENT_HUNDRED_PERCENT_NOTICEBOARD = 'SUBBIE_PRICING_EXPERIMENT_HUNDRED_PERCENT_NOTICEBOARD',
  SUPPLIER_EXTRA = 'SUPPLIER_EXTRA'
}

export interface ProfileAddressInput {
  address1: Scalars['String']['input'];
  city?: InputMaybe<Scalars['String']['input']>;
  postcode: Scalars['String']['input'];
  province?: InputMaybe<Scalars['String']['input']>;
  stateId?: InputMaybe<Scalars['EntityId']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export enum ProfileViewLogSource {
  ADDRESSBOOK = 'ADDRESSBOOK',
  DIRECTORY = 'DIRECTORY',
  PAGE = 'PAGE',
  QUOTE = 'QUOTE',
  RFQ = 'RFQ'
}

export enum ProjectFilterLocationType {
  DISTANCE = 'DISTANCE',
  NATIONAL = 'NATIONAL',
  STATE = 'STATE'
}

export enum ProjectFilterType {
  CONSULTANTS = 'CONSULTANTS',
  NOTICEBOARD = 'NOTICEBOARD',
  SAVED_KEYWORDS = 'SAVED_KEYWORDS'
}

export enum ProjectRedactedReason {
  AWARDED = 'AWARDED',
  BUDGET = 'BUDGET',
  CREDIT_EXPERIMENT = 'CREDIT_EXPERIMENT',
  FIFTY_PERCENT = 'FIFTY_PERCENT',
  SINGLE_TENDERER = 'SINGLE_TENDERER'
}

export enum ProjectStatus {
  AWARDED = 'AWARDED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export interface PurchaseAddonEFTInput {
  billingCycle: Scalars['Int']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface PurchaseAddonExistingCCInput {
  billingCycle: Scalars['Int']['input'];
  creditCardId: Scalars['EntityId']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface PurchaseAddonNewCCInput {
  billingCycle: Scalars['Int']['input'];
  productId: Scalars['EntityId']['input'];
  secureCard?: InputMaybe<SecureCardInput>;
  terms: Scalars['Boolean']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface PurchaseLicenseUsingEFTInput {
  addonIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  billingCycle: Scalars['Int']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userDetails: UserDetailsInput;
}

export interface PurchaseLicenseUsingExistingCreditCardInput {
  addonIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  billingCycle: Scalars['Int']['input'];
  creditCardId: Scalars['EntityId']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userDetails: UserDetailsInput;
}

export interface PurchaseLicenseWithChargebeeOfflineInput {
  billingCycle: Scalars['Int']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface PurchaseLicenseWithChargebeeTokenInput {
  billingCycle: Scalars['Int']['input'];
  chargebeeToken: Scalars['ID']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface PurchaseLicenseWithOfferUsingExistingCreditCardInput {
  accountOfferId: Scalars['EntityId']['input'];
  addonIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  billingCycle: Scalars['Int']['input'];
  creditCardId: Scalars['EntityId']['input'];
  productId: Scalars['EntityId']['input'];
  terms: Scalars['Boolean']['input'];
  userDetails: UserDetailsInput;
}

export enum QualificationLevel {
  ADVANCED = 'ADVANCED',
  COMMON_ASSESSMENT_STANDARD = 'COMMON_ASSESSMENT_STANDARD',
  ELITE = 'ELITE',
  FOUNDATION = 'FOUNDATION',
  STANDARD = 'STANDARD',
  VERIFIED_SUPPLIER = 'VERIFIED_SUPPLIER'
}

export enum QualificationSource {
  CHAS = 'CHAS'
}

export enum QualificationStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE'
}

export enum QualificationType {
  COMPLIANCE = 'COMPLIANCE',
  MEMBERSHIP = 'MEMBERSHIP'
}

export enum QuoteLogType {
  ADDED = 'ADDED',
  COMPLETED = 'COMPLETED',
  CREATED_FROM_RFQ = 'CREATED_FROM_RFQ',
  DELETED = 'DELETED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADED_ANON = 'DOWNLOADED_ANON',
  DOWNLOADED_BULK_PACKAGE = 'DOWNLOADED_BULK_PACKAGE',
  DOWNLOADED_BULK_STAGE = 'DOWNLOADED_BULK_STAGE',
  DOWNLOADED_BULK_UNCAT = 'DOWNLOADED_BULK_UNCAT',
  FILE_DOWNLOADED = 'FILE_DOWNLOADED',
  FILE_DOWNLOADED_ANON = 'FILE_DOWNLOADED_ANON',
  PACKAGE_ASSIGNED = 'PACKAGE_ASSIGNED',
  REVISED = 'REVISED',
  SUBMITTED = 'SUBMITTED',
  SUBMITTED_UNCAT = 'SUBMITTED_UNCAT',
  VIEWED = 'VIEWED'
}

export interface ReasonInput {
  extraInfo?: InputMaybe<Scalars['String']['input']>;
  tag: Scalars['String']['input'];
}

export interface RemoveTeamMemberInput {
  id: Scalars['EntityId']['input'];
}

export interface RequestHelpInput {
  enquiry: Scalars['String']['input'];
  extraInfo: Array<GenericKeyValuePair>;
  nature: EnquiryNature;
  route: Scalars['String']['input'];
}

export interface ReviseQuoteInput {
  additionalFiles: Array<UncommittedFileInput>;
  amount: Scalars['Float']['input'];
  confirmFixedPrice?: InputMaybe<Scalars['Boolean']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  quoteFile: UncommittedFileInput;
  quoteId: Scalars['EntityId']['input'];
}

export enum RfqQuotingStatus {
  DECLINED = 'DECLINED',
  QUOTED = 'QUOTED',
  QUOTING = 'QUOTING'
}

export enum SearchPhraseCategory {
  BRAND = 'BRAND',
  COMPETITOR = 'COMPETITOR',
  OTHER = 'OTHER',
  PRODUCTS = 'PRODUCTS',
  SERVICE = 'SERVICE',
  TRADE = 'TRADE'
}

export enum SearchStatus {
  AWARDED = 'AWARDED',
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export interface SecureCardInput {
  secureCardToken: Scalars['String']['input'];
}

export enum ServiceAreaBoundaryType {
  COUNTRY = 'COUNTRY',
  STATE = 'STATE'
}

export interface ServiceAreaInput {
  boundaryIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  countryId?: InputMaybe<Scalars['EntityId']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
  /** @deprecated (clarity) use boundaryIds */
  stateIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
}

export enum ServiceAreaType {
  BOUNDARY = 'BOUNDARY',
  RADIAL = 'RADIAL'
}

export interface SetOrUpdateUserSettingInput {
  dateTimeValue?: InputMaybe<Scalars['DateTime']['input']>;
  intValue?: InputMaybe<Scalars['Int']['input']>;
  settingName: UserSettingName;
  stringValue?: InputMaybe<Scalars['String']['input']>;
  unset: Scalars['Boolean']['input'];
}

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum StageCategory {
  AGED_CARE = 'AGED_CARE',
  CIVIL = 'CIVIL',
  COMMERCIAL = 'COMMERCIAL',
  CORRECTIONS = 'CORRECTIONS',
  DATA_CENTRE = 'DATA_CENTRE',
  DEFENCE = 'DEFENCE',
  EDUCATION = 'EDUCATION',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FIT_OUT = 'FIT_OUT',
  GOVERNMENT = 'GOVERNMENT',
  HEALTH = 'HEALTH',
  HOSPITALITY = 'HOSPITALITY',
  INDUSTRIAL_HEAVY = 'INDUSTRIAL_HEAVY',
  INDUSTRIAL_LIGHT = 'INDUSTRIAL_LIGHT',
  MEDICAL = 'MEDICAL',
  MIXED_USE = 'MIXED_USE',
  OTHER = 'OTHER',
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  RECREATION = 'RECREATION',
  REFURBISHMENT = 'REFURBISHMENT',
  RESIDENTIAL = 'RESIDENTIAL',
  RETAIL = 'RETAIL',
  TOURISM = 'TOURISM'
}

export enum StageType {
  PROCUREMENT = 'PROCUREMENT',
  TENDER = 'TENDER'
}

export interface SuggestConsultantCorrectionInput {
  id: Scalars['EntityId']['input'];
  message: Scalars['String']['input'];
}

export interface UncommittedFileInput {
  contentType?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  fileSize: Scalars['Int']['input'];
  hash: Scalars['String']['input'];
}

export interface UpdateCalendarSyncPreferencesInput {
  interestLevels: Array<InterestLevel>;
}

export interface UpdateCompanyCapabilitiesInput {
  contractSizeMax?: InputMaybe<ContractSizeEnum>;
  contractSizeMin?: InputMaybe<ContractSizeEnum>;
  coreTradeIds?: InputMaybe<Array<InputMaybe<Scalars['EntityId']['input']>>>;
  serviceArea: OfficeServiceAreaInput;
  stockTradeIds: Array<Scalars['EntityId']['input']>;
}

export interface UpdateConnectionPreferenceInput {
  contactId: Scalars['EntityId']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['EntityId']['input'];
  type: ConnectionPreferenceType;
}

export interface UpdateFilterInput {
  addedWithin?: InputMaybe<Scalars['Int']['input']>;
  awardedAtMax?: InputMaybe<Scalars['DateTime']['input']>;
  awardedAtMin?: InputMaybe<Scalars['DateTime']['input']>;
  awardedWithin?: InputMaybe<Scalars['Int']['input']>;
  budgetMax?: InputMaybe<Scalars['Int']['input']>;
  budgetMin?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<StageCategory>>;
  closedWithin?: InputMaybe<Scalars['Int']['input']>;
  docsAvailable?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['EntityId']['input'];
  interestLevels?: InputMaybe<Array<InterestLevel>>;
  locationDistance?: InputMaybe<Scalars['Int']['input']>;
  locationStateId?: InputMaybe<Scalars['EntityId']['input']>;
  locationType?: InputMaybe<ProjectFilterLocationType>;
  office?: InputMaybe<Scalars['EntityId']['input']>;
  officeId?: InputMaybe<Scalars['EntityId']['input']>;
  quotesDueMax?: InputMaybe<Scalars['Int']['input']>;
  quotesDueMin?: InputMaybe<Scalars['Int']['input']>;
  reset?: InputMaybe<Scalars['Boolean']['input']>;
  speciFinderMatches?: InputMaybe<Scalars['Boolean']['input']>;
  stateIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  subTradeIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
  tradeIds?: InputMaybe<Array<Scalars['EntityId']['input']>>;
}

export interface UpdateLicensesBillingContactInput {
  licenseId: Scalars['EntityId']['input'];
  userId: Scalars['EntityId']['input'];
}

export interface UpdateOfficeDetailsInput {
  accountOfficeId: Scalars['EntityId']['input'];
  address: ProfileAddressInput;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  serviceArea: ServiceAreaInput;
  website?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateProfileDetailsInput {
  businessEntityType?: InputMaybe<BusinessEntityType>;
  companyNumber?: InputMaybe<Scalars['String']['input']>;
  defaultOfficeAddress: ProfileAddressInput;
  name: Scalars['String']['input'];
  primaryContactId: Scalars['EntityId']['input'];
}

export interface UpdateSpeciFinderKeywordInput {
  excludes?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['EntityId']['input'];
  includes?: InputMaybe<Scalars['String']['input']>;
  keyword: Scalars['String']['input'];
  keywordType: SearchPhraseCategory;
  variations: Array<Scalars['String']['input']>;
}

export interface UpdateTeamMemberInput {
  admin: Scalars['Boolean']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['EntityId']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
}

export interface UpdateUserEmailPreferenceInput {
  emailSubscriptionId: EmailSubscriptionType;
  option: EmailSubscriptionOption;
}

export interface UpgradeLiteToEssentialInput {
  billingCycle: BillingCycle;
}

export interface UserDetailsInput {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  companyPhone?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['Int']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  postcode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['Int']['input']>;
  suburb?: InputMaybe<Scalars['String']['input']>;
}

export enum UserFeedbackScore {
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
  POSITIVE = 'POSITIVE',
  STRONG_NEGATIVE = 'STRONG_NEGATIVE',
  STRONG_POSITIVE = 'STRONG_POSITIVE'
}

export enum UserFeedbackTopic {
  ACTIVITY_FEED = 'ACTIVITY_FEED',
  COVER_SHEETS = 'COVER_SHEETS'
}

export enum UserPackageDownloadStatus {
  APPROVED = 'APPROVED',
  AUTO_APPROVED = 'AUTO_APPROVED',
  AWAITING_RESPONSE = 'AWAITING_RESPONSE',
  CAN_REQUEST = 'CAN_REQUEST',
  DECLINED = 'DECLINED',
  REVOKED = 'REVOKED'
}

export enum UserSettingName {
  ATTR_ADD_PROJECT_HISTORY_BANNER_DISMISSED = 'ATTR_ADD_PROJECT_HISTORY_BANNER_DISMISSED',
  SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED = 'SUB_TRADES_ONBOARDING_MODAL_LAST_DISMISSED'
}

export interface WatchProjectInput {
  projectId: Scalars['EntityId']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
  status: InterestLevel;
}

export enum WorkforceSizeEnum {
  RANGE_0_5 = 'RANGE_0_5',
  RANGE_6_20 = 'RANGE_6_20',
  RANGE_21_50 = 'RANGE_21_50',
  RANGE_51_250 = 'RANGE_51_250',
  RANGE_250_PLUS = 'RANGE_250_PLUS',
  RANGE_251_PLUS = 'RANGE_251_PLUS'
}
