import { useField, useForm } from 'react-final-form';
import { Fieldset } from '@estimateone/frontend-components';
import FileUploader from '../../../FileUploader';
import { QuoteFile } from '../../QuoteFiles';
import { QuoteFileRestrictions } from '@shared/FileUploader/QuoteFileRestrictions';
import { FileManagerParams } from '@shared/FileUploader/types';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { QuoteFileAction, QuoteFormCommonFieldName, UploadCategory } from '@ascension/enums';
import { QuoteFileValue, QuoteFormsValues } from '../types';

const QuoteFileField = ({
  context,
  fileManagerLocale,
  parentType,
  parentId,
}: FileManagerParams) => {
  const {
    change: updateFieldValue,
    mutators: { setFieldTouched },
  } = useForm<QuoteFormsValues>();

  const {
    input: { value: quoteFile },
    meta: { error, submitError, touched },
  } = useField<QuoteFileValue>(QuoteFormCommonFieldName.QuoteFile);

  return (
    <FieldWrapper errors={error || submitError} showErrors={touched}>
      <Fieldset
        tabIndex={0}
        name={QuoteFormCommonFieldName.QuoteFile}
        id="quote-file"
        legend="Quote file"
        onBlur={() => setFieldTouched(QuoteFormCommonFieldName.QuoteFile, true)}
        isRequired
      >
        {quoteFile ? (
          <QuoteFile
            action={QuoteFileAction.Remove}
            file={quoteFile}
            onRemove={() => updateFieldValue(QuoteFormCommonFieldName.QuoteFile, undefined)}
          />
        ) : (
          <FileUploader
            context={context}
            fileManagerLocale={fileManagerLocale}
            dragDropMessage="Drag and drop one quote file here, or"
            browseMessage="upload"
            uppyOptions={{
              id: 'quote',
              restrictions: {
                maxFileSize: QuoteFileRestrictions.MaxFileSize,
                maxNumberOfFiles: 1,
                allowedFileTypes: QuoteFileRestrictions.AllowedAttachmentFileTypes,
              },
              locale: {
                strings: {
                  youCanOnlyUploadX: {
                    0: "Upload one quote above, then any extra files by selecting 'Attach extra files' below.",
                  },
                },
              },
            }}
            category={UploadCategory.QUOTE}
            parentType={parentType}
            parentId={parentId}
            onUploadComplete={([file]) =>
              updateFieldValue(QuoteFormCommonFieldName.QuoteFile, file)
            }
          />
        )}
      </Fieldset>
    </FieldWrapper>
  );
};

export default QuoteFileField;
