import { SyntheticEvent } from 'react';
import { useField } from 'react-final-form';
import { DatePicker, DatePickerProps } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';

export type DatePickerFieldProps = {
  fieldName: string;
  id: string;
  label: string;
  hideErrorMessages?: boolean;
  // Make ReactDatePicker's onChange prop optional
  onChange?: (date: Date | [Date, Date] | null, event: SyntheticEvent<unknown> | undefined) => void;
  validate?: (value: Date) => string[] | undefined;
  onCalendarClose?: () => void;
} & Omit<DatePickerProps, 'onChange' | 'onCalendarClose'>;

export const DatePickerField = ({
  fieldName,
  id,
  label,
  hideErrorMessages = false,
  onChange,
  validate,
  onCalendarClose,
  ...props
}: DatePickerFieldProps) => {
  const {
    input: { value, onChange: onInputChange, onBlur: onInputBlur, ...fieldInput },
    meta: { error, submitError, touched },
  } = useField(fieldName, { validate });
  const errors = error || submitError;

  return (
    <FieldWrapper errors={errors} showErrors={touched} hideErrorMessages={hideErrorMessages}>
      {/* Container is required for the datepicker to attach itself to */}
      <div>
        <DatePicker
          id={id}
          label={label}
          onChange={(date, e) => {
            if (onChange) onChange(date, e);
            onInputChange(date);
          }}
          onCalendarClose={() => {
            onInputBlur();
            if (onCalendarClose) onCalendarClose();
          }}
          selected={value || null}
          {...props}
          {...fieldInput}
        />
      </div>
    </FieldWrapper>
  );
};
