import { gql } from '@apollo/client';
import Address from '../Address';

const Quotes = {
  fragments: {
    quoteFile: gql`
      fragment QuoteFile on Quote {
        id
        fileName
        fileSize
        hash
      }
    `,
    downloadableQuoteFile: gql`
      fragment DownloadableQuoteFile on Quote {
        id
        fileName
        fileSize
        hash
        downloadUrl
        viewUrl
      }
    `,
    quoteExtraFile: gql`
      fragment QuoteExtraFile on QuoteExtraFile {
        id
        fileName
        fileSize
        hash
      }
    `,
    downloadableQuoteExtraFile: gql`
      fragment DownloadableQuoteExtraFile on QuoteExtraFile {
        id
        fileName
        fileSize
        hash
        downloadUrl
      }
    `,
    rfqTimestampFields: gql`
      fragment RfqTimestampFields on Rfq {
        approvedAt
        invitedAt
        declinedAt
        requestedAt
        revokedAt
      }
    `,
    common: gql`
      fragment QuoteCommon on Quote {
        id
        title
        hash
        amount
        canDelete
        downloadUrl
        viewUrl
        lastDownloadedAt
        uploadedByCurrentAccount
        deletedAt
        createdAt
        createdBy {
          id
          firstName
          lastName
          fullName
        }
      }
    `,
    detail: gql`
      fragment QuoteExtraDetail on Quote {
        clarifications
        canEdit
        fileName
        fileSize
        additionalFiles {
          id
          hash
          fileName
          fileSize
          downloadUrl
        }
      }
    `,
    summary: gql`
      fragment QuoteSummary on Quote {
        id
        createdAt
        fileName
        version
        createdBy {
          id
          fullName
        }
      }
    `,
    packageRfq: gql`
      fragment QuotePackageRfq on Quote {
        package {
          id
          title
        }
        rfq {
          id
          companyName
          contactName
          contactAddress {
            ...DetailedAddress
          }
        }
      }
      ${Address.fragments.detailedAddress}
    `,
    packageRfqWithContactDetails: gql`
      fragment QuotePackageRfqWithContactDetails on Quote {
        package {
          id
          title
        }
        rfq {
          id
          companyName
          contactName
          contactAddress {
            ...DetailedAddress
          }
          contactAccount {
            id
          }
          contact {
            id
            company {
              id
            }
          }
        }
      }
      ${Address.fragments.detailedAddress}
    `,
    quoteNotes: gql`
      fragment QuoteNotes on Quote {
        notes {
          id
          authorName
          text
          createdAt
        }
      }
    `,
    quoteActivityLogs: gql`
      fragment QuoteActivityLogs on Quote {
        activityLogs {
          id
          message
          activityType
          createdAt
          createdByFullName
        }
      }
    `,
  },
};

export default Quotes;
