import { useInExperiment, useHasFeature } from '@subbie/context/AccountProvider/hooks';
import { ExperimentName, FeatureName } from '@ascension/_gqltypes/subbie.generated';

export const useCanSeeQuoteViewedByBuilderInQuotesPage = (): boolean => {
  const isQuotesViewedByBuilderExperiment = useInExperiment(
    ExperimentName.DISCOVER_QUOTES_VIEWED_QUOTE,
  );
  const canSeeQuotesViewedBy = useHasFeature(FeatureName.QUOTE_VIEWED_BY_BUILDER);

  return !isQuotesViewedByBuilderExperiment || canSeeQuotesViewedBy;
};

export const useCanSeeUpgradeHookForQuoteViewedInQuotesPage = (): boolean => {
  const isQuotesViewedByBuilderExperiment = useInExperiment(
    ExperimentName.DISCOVER_QUOTES_VIEWED_QUOTE,
  );
  const canSeeUpgradeHook = useHasFeature(FeatureName.QUOTE_VIEWED_BY_BUILDER_UPGRADE_HOOK);
  return isQuotesViewedByBuilderExperiment && canSeeUpgradeHook;
};
