import { Alert, AlertVariant } from '@estimateone/frontend-components';
import { FORM_ERROR } from 'final-form';
import styles from './styles.scss';

type GlobalErrorListProps = { errors: string[] };

const GlobalErrorList = ({ errors }: GlobalErrorListProps) => (
  <div data-name={FORM_ERROR}>
    <Alert variant={AlertVariant.Red}>
      <ul className={styles.errors}>
        {errors.map((message) => (
          <li key={message}>{message}</li>
        ))}
      </ul>
    </Alert>
  </div>
);

export default GlobalErrorList;
