import { gql } from '@apollo/client';

export const GET_QUOTES = gql`
  query GetQuotes {
    subbieQuotes {
      id
      version

      createdByFullName

      packageTitle

      recipientName
      lastDownloadedAt

      projectId
      projectName
      projectAddress {
        id
        address1
        shortAddress
      }

      tenderQuotesDue
    }
  }
`;
