export enum Step {
  UPGRADE_SELECTION = 1,
  UPGRADE_ADDONS = 2,
  UPGRADE_CHECKOUT = 3,
  UPGRADE_THANK_YOU = 4,
  UPGRADE_OFFER = 11,
}

export enum Country {
  AU_ID = 1,
  USA_ID = 3,
}
