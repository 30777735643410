import Routing from 'routing';
import { Button, ButtonVariant } from '@estimateone/frontend-components';
import { GetQuoteAndVersions_quote as Quote } from './types/GetQuoteAndVersions';
import styles from './styles.scss';

const QuoteActions = ({
  quote: { id, downloadUrl, canRevise, canDelete },
  handleDeleteClicked,
}: {
  quote: Quote;
  handleDeleteClicked: () => void;
}) => (
  <ul className={styles.actionList}>
    <li>
      <Button
        link={downloadUrl}
        variant={ButtonVariant.Secondary}
        target="_blank"
        rel="noopener noreferrer"
        fullWidth
      >
        Download quote
      </Button>
    </li>

    {canRevise && (
      <li>
        <Button
          link={Routing.generate('app_quote_revise', { id })}
          variant={ButtonVariant.Secondary}
          fullWidth
        >
          Revise quote
        </Button>
      </li>
    )}

    {canDelete && (
      <li>
        <Button onClick={handleDeleteClicked} variant={ButtonVariant.Grey} fullWidth>
          <span className={styles.deleteQuoteButtonContent}>Delete quote</span>
        </Button>
      </li>
    )}
  </ul>
);

export default QuoteActions;
