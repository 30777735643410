import { useContext } from 'react';
import { addThousandsSeparators } from '../utils';
import { CurrencyInfoContext } from '../../shared/context';
import styles from './styles.scss';

enum PricingVariant {
  Default,
  Featured,
  Spaced,
}

export type PricingProps = {
  price: number;
  hideCents?: boolean;
  variant?: PricingVariant;
};

const Pricing = ({ price, hideCents = false, variant = PricingVariant.Default }: PricingProps) => {
  const [{ currencyInfo }] = useContext(CurrencyInfoContext);

  // We must round in case we get a fractional cents value.
  const isNegative = price < 0;
  const priceString = Math.round(Math.abs(price)).toString();

  const sign = isNegative ? '-' : '';
  const dollars = addThousandsSeparators(priceString.slice(0, priceString.length - 2)) || '0';
  const cents = priceString.slice(-2).padEnd(2, '0');

  if (variant === PricingVariant.Default) {
    return (
      <span>{`${sign}${currencyInfo?.currencySymbol ?? '$'}${dollars}${
        hideCents ? '' : `.${cents}`
      }`}</span>
    );
  }

  if (variant === PricingVariant.Spaced) {
    return (
      <span>{`${currencyInfo?.currencySymbol ?? '$'} ${sign}${dollars}${
        hideCents ? '' : `.${cents}`
      }`}</span>
    );
  }

  return (
    <div className={styles.pricing}>
      {isNegative && <span className={styles.sign}>{sign}</span>}
      <span className={styles.dollarSign}>{currencyInfo?.currencySymbol ?? '$'}</span>
      <span className={styles.dollars}>{dollars}</span>
      {!hideCents && <span className={styles.cents}>.{cents}</span>}
    </div>
  );
};

export { PricingVariant };

export default Pricing;
