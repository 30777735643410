import { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuoteView, { QuoteViewPageProps } from '../../../../shared/Quotes/View';
import { CurrencyInfoContext } from '@shared';
import { GET_QUOTE } from '@shared/Quotes/View/queries';
import {
  GetQuoteAndVersions,
  GetQuoteAndVersionsVariables,
} from '@shared/Quotes/View/types/GetQuoteAndVersions';
import { getCurrencyInfo } from '@ascension/enums';

const QuoteViewPage = ({ quoteId, quotesListUrl, returnTo, returnUrl }: QuoteViewPageProps) => {
  const { data, loading } = useQuery<GetQuoteAndVersions, GetQuoteAndVersionsVariables>(GET_QUOTE, {
    variables: { id: quoteId },
  });

  const [, setCurrencyInfoContext] = useContext(CurrencyInfoContext);

  useEffect(() => {
    const currencyInfo = getCurrencyInfo(data?.quote.recipientCountry?.id);
    setCurrencyInfoContext({ currencyInfo });
  }, [data, loading, setCurrencyInfoContext]);

  if (loading || !data) return <PageLoadingSpinner />;

  return (
    <QuoteView
      quote={data.quote}
      quotesListUrl={quotesListUrl}
      returnTo={returnTo}
      returnUrl={returnUrl}
    />
  );
};

export default QuoteViewPage;
