import { gql } from '@apollo/client';

const Discounts = {
  fragments: {
    pricingOptions: gql`
      fragment PricingOptions on PricingOption {
        product {
          id
        }
        billingCycle
        price {
          amount
        }
        priceExGST {
          amount
        }
        savings {
          amount
        }
      }
    `,
  },
};

export default Discounts;
