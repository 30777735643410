const getDescription = (hasDownloaded: boolean, hasLatestDocs: boolean) => {
  if (hasDownloaded === false) return 'Not accessed';
  if (hasLatestDocs === false) return 'Addendum not viewed';
  return 'Current';
};

type DocStatusProps = { hasLatestDocs: boolean; hasDownloaded: boolean };

const DocStatus = ({ hasLatestDocs = false, hasDownloaded = false }: DocStatusProps) => {
  const docsAreCurrent = hasLatestDocs && hasDownloaded;
  const description = getDescription(hasDownloaded, hasLatestDocs);
  const className = docsAreCurrent ? 'success' : 'danger';
  const icon = docsAreCurrent ? 'check icon-sm' : 'flag';

  return (
    <span className={`${className} white-space-nowrap`}>
      <i className={`icon icon-${icon} icon-left`} />
      {description}
    </span>
  );
};

export default DocStatus;
