import { OneOrMoreReactElements } from '@estimateone/frontend-components';
import Image from '../Image';
import ImageTick from './tick.png';
import styles from './styles.scss';

type KeyFeatureProps = {
  heading: string;
  description: string;
};

const KeyFeature = ({ heading, description }: KeyFeatureProps) => (
  <li className={styles.keyFeature}>
    <Image src={ImageTick} alt="" className={styles.tick} />
    <div>
      <p className={styles.heading}>{heading}</p>
      <p>{description}</p>
    </div>
  </li>
);

type KeyFeaturesProps = {
  children: OneOrMoreReactElements<KeyFeatureProps>;
};

const KeyFeatures = ({ children }: KeyFeaturesProps) => <ul>{children}</ul>;

// eslint-disable-next-line fp/no-mutation
KeyFeatures.Item = KeyFeature;

export default KeyFeatures;
