import debounce from 'debounce-promise';
import E1Request from '../E1Request';
import { EntityId } from '../../../types';

const POLLING_WAIT_PERIOD_MS = 3500;
const COMMIT_COMPLETION_WEIGHTING = 0.01;

type UploadId = EntityId;
type SessionId = EntityId;
type ParentId = number;
type ParentType = string;

/* eslint-disable camelcase */

interface NotifyResponse {
  success: boolean;
  parent_id: ParentId;
  parent_type: ParentType;
  sess_id: SessionId;
}

export enum CommitStatus {
  PENDING = 0x00,
  COMPLETE = 0x01,
  UNZIPPING = 0x02,
  DRAFT = 0x03,
  SKIPPED_DUPLICATE = -0x01,
  SKIPPED_INVALID = -0x02,
  ERROR = -0x03,
  INFECTED = -0x04,
}

interface UploadSession {
  id: SessionId;
  uploads: UploadList;
  total: number;
  remaining: number;
  done: boolean;
}

export type UploadSessionList = UploadSession[];

export interface Upload {
  id: UploadId;
  uuid: string;
  name: string;
  hash: string;
  size: number;
  directory?: string | null;
  commit_status: CommitStatus;
  done: boolean;
  is_archive: boolean;
  archive_entries: UploadList;
  count_archive_entries?: number;

  error: string | null;
  infection_data: string | null;
}

export type ArchiveUpload = Upload;

interface CompletedUpload extends Upload {
  saved_entity: SavedFile;
  archive_entries: CompletedUpload[];
}

export type UploadList = Upload[];
export type CompletedUploadList = CompletedUpload[];

export interface SavedFile {
  id: EntityId;
  hash: string;
  file_name: string;
  file_size: number;
  directory_name?: string;
}

interface PendingFile {
  name: string;
  uuid: string;
  size: number;
  directory?: string;
  autoUnzip: boolean;
}

export type SubscriptionCallback = ({
  sessionFinished,
  pending,
  completed,
  drafts,
  pendingArchives,
  unzippedArchives,
  unzippedFiles,
  pendingUnzipFiles,
  duplicates,
  invalid,
  errored,
}: {
  sessionFinished: boolean;
  pending: UploadList;
  completed: CompletedUpload[];
  drafts: UploadList;
  pendingArchives: UploadList;
  unzippedArchives: UploadList;
  unzippedFiles: CompletedUpload[];
  pendingUnzipFiles: UploadList;
  duplicates: UploadList;
  invalid: UploadList;
  errored: UploadList;
}) => void;

type SessionRequest = E1Request<{ success: boolean; data: UploadSessionList }, { id?: SessionId }>;

export type FmErr = {
  id: string;
  name: string;
  errorMessage: string;
};

export default class PendingUploadManager {
  private sessionId: SessionId | null;
  // private parentId: ParentId;
  // private parentTypeKey: string;
  private readonly commitFn: (file: PendingFile) => Promise<SessionId>;
  private poll: boolean;
  private filesAdded: number;
  private pendingUploads: UploadList;
  private completedUploads: CompletedUploadList;
  private drafts: UploadList;
  private pendingArchives: UploadList;
  private unzippedArchives: UploadList;
  private bytesUploaded: number;
  private bytesTotal: number;
  private bytesRate: number;
  private bytesLastAt: number | null;
  private errors: UploadList;
  private subscribers: Set<SubscriptionCallback>;
  private timeout: number | null = null;

  constructor(
    private readonly parentId: ParentId,
    private readonly parentTypeKey: string,
    private readonly notifyEndpoint: string,
    private readonly pendingUploadsEndpoint: string,
    private readonly debounceWaitMs: number = 500,
  ) {
    this.notifyEndpoint = notifyEndpoint;
    this.pendingUploadsEndpoint = pendingUploadsEndpoint;
    this.sessionId = null;
    this.debounceWaitMs = debounceWaitMs;
    // @ts-ignore
    this.commitFn = debounce(this.addFilesToPendingUpload, this.debounceWaitMs, {
      accumulate: true,
    });
    this.poll = false;
    this.filesAdded = 0;
    this.pendingUploads = [];
    this.completedUploads = [];
    this.drafts = [];
    this.pendingArchives = [];
    this.unzippedArchives = [];
    this.bytesUploaded = 0;
    this.bytesTotal = 0;
    this.bytesRate = 0;
    this.bytesLastAt = null;
    this.errors = new Array<Upload>();
    this.subscribers = new Set<SubscriptionCallback>();
  }

  public commit(file: PendingFile): Promise<SessionId> {
    return this.commitFn(file);
  }

  public addSubscriber(callback: SubscriptionCallback) {
    this.subscribers.add(callback);
    return this;
  }

  public removeSubscriber(callback: SubscriptionCallback) {
    this.subscribers.delete(callback);
    return this;
  }

  public getAddedCount(): number {
    return this.filesAdded;
  }

  /**
   * Total files, including extracted archive files
   */
  public getTotalFilesCount(): number {
    return this.getCompletedCount() + this.errors.length;
  }

  public getCompletedCount(): number {
    return this.completedUploads.length;
  }

  public getPercentCompleted(waitForCommit = false): number {
    const commitRatio = this.getCompletedCount() / this.getAddedCount();
    const uploadRatio = this.bytesUploaded / this.bytesTotal;
    const commitWeight = waitForCommit ? COMMIT_COMPLETION_WEIGHTING : 0;
    const uploadWeight = 1.0 - commitWeight;
    const weightedCommitPercentage = commitRatio * commitWeight || 0;
    const weightedUploadPercentage = uploadRatio * uploadWeight || 0;

    return Math.min(Math.max(weightedCommitPercentage + weightedUploadPercentage, 0) || 0, 1);
  }

  public getEstimatedTimeRemaining(): number | null {
    const estimatedTime = Math.max(this.bytesTotal / this.bytesRate, 0) + 5 || 0;
    return Number.isFinite(estimatedTime) ? estimatedTime : null;
  }

  private static transformUploadToError(ul: Upload): FmErr {
    const { id, name, commit_status: commitStatus, error, infection_data: infectionData } = ul;

    const getErrorMessage = () => {
      if (commitStatus === CommitStatus.SKIPPED_DUPLICATE) {
        return 'A document with this file name already exists in the same folder';
      }
      if (commitStatus === CommitStatus.ERROR) {
        return error || 'Commit error';
      }
      if (commitStatus === CommitStatus.INFECTED) {
        return infectionData || 'VIRUS DETECTED!';
      }

      return 'Unknown error';
    };

    return {
      id: id.toString(),
      name,
      errorMessage: getErrorMessage(),
    };
  }

  public getPendingArchives(): ArchiveUpload[] {
    return this.pendingArchives;
  }

  public getPendingNonArchiveFiles(): UploadList {
    return this.pendingUploads.filter(({ is_archive: isArchive }) => !isArchive);
  }

  public getCompletedNonArchiveFiles(): UploadList {
    return this.completedUploads.filter(({ is_archive: isArchive }) => !isArchive);
  }

  public getCompletedArchives(): ArchiveUpload[] {
    return this.unzippedArchives;
  }

  public getDrafts(): UploadList {
    return this.drafts;
  }

  public getErrors(): FmErr[] {
    return this.errors.map(PendingUploadManager.transformUploadToError);
  }

  public setBytesFinished(): void {
    this.bytesUploaded = this.bytesTotal;
  }

  public setBytesUploaded(bytes: number): void {
    if (this.bytesLastAt) {
      this.bytesRate = (bytes - this.bytesUploaded) / ((Date.now() - this.bytesLastAt) / 1000);
    }
    this.bytesLastAt = Date.now();
    this.bytesUploaded = bytes;
  }

  public setBytesTotal(bytes: number): void {
    this.bytesTotal = bytes;
  }

  private fetchAndNotifySubscribers = async () => {
    const [pendingUploadSession] = await this.getPendingUploadSessionById();

    const { uploads, done: sessionFinished } = pendingUploadSession;
    const stopPolling =
      sessionFinished ||
      uploads.every(
        ({ commit_status: commitStatus }) =>
          commitStatus !== CommitStatus.PENDING && commitStatus !== CommitStatus.UNZIPPING,
      );

    this.filesAdded = uploads.length;

    const uploadParts = uploads.reduce(
      (uploadsPartition, upload) => {
        const { done, is_archive: isArchive, archive_entries: archiveEntries } = upload;

        if (isArchive) {
          if (done) {
            uploadsPartition.unzippedArchives.push(upload);
          } else {
            uploadsPartition.pendingArchives.push(upload);
          }
        }

        // If it is a completed archive, get the entries
        // otherwise, add the upload to the relevant array
        (isArchive ? archiveEntries : [upload]).forEach((ul) => {
          const { commit_status: commitStatus } = ul;

          switch (commitStatus) {
            case CommitStatus.UNZIPPING:
              uploadsPartition.pendingUnzipFiles.push(ul);
              break;
            case CommitStatus.COMPLETE:
              if (ul.is_archive) {
                uploadsPartition.unzippedFiles.push(ul as CompletedUpload);
              } else {
                uploadsPartition.completed.push(ul as CompletedUpload);
              }
              break;
            case CommitStatus.DRAFT:
              uploadsPartition.drafts.push(ul);
              break;
            case CommitStatus.SKIPPED_DUPLICATE:
              uploadsPartition.duplicates.push(ul);
              break;
            case CommitStatus.SKIPPED_INVALID:
              uploadsPartition.invalid.push(ul);
              break;
            case CommitStatus.ERROR:
            case CommitStatus.INFECTED: // TODO: break out and treat separately
              uploadsPartition.errored.push(ul);
              break;
            default:
              if (!done) uploadsPartition.pending.push(ul);
          }
        });
        return uploadsPartition;
      },
      {
        pending: new Array<Upload>(),
        completed: new Array<CompletedUpload>(),
        drafts: new Array<Upload>(),
        unzippedArchives: new Array<Upload>(),
        pendingArchives: new Array<Upload>(),
        unzippedFiles: new Array<CompletedUpload>(),
        pendingUnzipFiles: new Array<Upload>(),
        duplicates: new Array<Upload>(),
        invalid: new Array<Upload>(),
        errored: new Array<Upload>(),
      },
    );

    this.pendingUploads = uploadParts.pending;
    this.completedUploads = uploadParts.completed;
    this.drafts = uploadParts.drafts;
    this.unzippedArchives = uploadParts.unzippedArchives;
    this.pendingArchives = uploadParts.pendingArchives;

    this.errors = uploadParts.duplicates.concat(uploadParts.invalid).concat(uploadParts.errored);

    this.subscribers.forEach((s) =>
      s({
        sessionFinished,
        pending: this.pendingUploads,
        completed: this.completedUploads,
        drafts: this.drafts,
        pendingArchives: uploadParts.pendingArchives,
        unzippedArchives: uploadParts.unzippedArchives,
        unzippedFiles: uploadParts.unzippedFiles,
        pendingUnzipFiles: uploadParts.pendingUnzipFiles,
        duplicates: uploadParts.duplicates,
        invalid: uploadParts.invalid,
        errored: uploadParts.errored,
      }),
    );

    if (this.poll) {
      this.timeout = window.setTimeout(
        () => this.fetchAndNotifySubscribers(),
        POLLING_WAIT_PERIOD_MS,
      );
    }

    if (stopPolling) {
      this.poll = false;
      if (this.timeout) {
        window.clearTimeout(this.timeout);
      }
    }
  };

  public startPolling(): Promise<void> {
    if (this.poll) return Promise.resolve();
    this.poll = true;
    return this.fetchAndNotifySubscribers();
  }

  private async addFilesRequest(fileArrays: PendingFile[][]): Promise<SessionId> {
    const { sess_id: sessionId } = await new E1Request<
      NotifyResponse,
      { typeKey: string; parentId: ParentId; files: PendingFile[] }
    >(this.notifyEndpoint, 'POST', {
      typeKey: this.parentTypeKey,
      parentId: this.parentId,
      files: fileArrays.flat(),
    }).submit();

    return sessionId;
  }

  private appendFilesRequest(
    sessionId: SessionId,
    fileArrays: PendingFile[][],
  ): Promise<NotifyResponse> {
    return new E1Request<NotifyResponse, { sessionId: SessionId; files: PendingFile[] }>(
      this.notifyEndpoint,
      'POST',
      {
        sessionId,
        files: fileArrays.flat(),
      },
    ).submit();
  }

  private async addFilesToPendingUpload(fileArrays: PendingFile[][]): Promise<SessionId[]> {
    if (!this.sessionId) {
      const sessionId = await this.addFilesRequest(fileArrays);
      this.sessionId = sessionId;

      return fileArrays.map(() => sessionId);
    }

    await this.appendFilesRequest(this.sessionId, fileArrays);

    const { sessionId } = this;

    return fileArrays.map(() => sessionId);
  }

  public async getPendingUploadSessionsForAccount(): Promise<UploadSessionList> {
    return (await this.getPendingUploadSessionRequest().submit()).data;
  }

  private async getPendingUploadSessionById(id?: SessionId): Promise<UploadSessionList> {
    return (await this.getPendingUploadSessionRequest((id || this.sessionId) as number).submit())
      .data;
  }

  private getPendingUploadSessionRequest(id?: SessionId): SessionRequest {
    return new E1Request<{ success: boolean; data: UploadSessionList }, { id: number | undefined }>(
      this.pendingUploadsEndpoint,
      'GET',
      { id },
    );
  }
}
