import groupBy from 'lodash/groupBy';
import toArray from 'lodash/toArray';
import { Column } from './enums';
import { GetQuotes_subbieQuotes as Quote } from './types/GetQuotes';
import { SortDirection } from '@ascension/_gqltypes/subbie.generated';

export type QuoteList = Quote[];

// slightly torturous logic to respect sort order
const groupSortedQuotesByProject = (sortedQuotes: QuoteList): QuoteList[] => {
  const getId = ({ projectId }: Quote): number => projectId;
  const projectIds = Array.from(new Set(sortedQuotes.map(getId)));

  return toArray<QuoteList>(
    groupBy<Quote>(sortedQuotes, (q: Quote) => projectIds.indexOf(getId(q))),
  );
};

const getSortFunc = (column: Column): ((quote: Quote) => number | string | null) => {
  switch (column) {
    case Column.Project:
      return (quote) => quote.projectName;
    case Column.Builder:
      return (quote) => quote.recipientName;
    case Column.Status:
      return (quote) => (quote.lastDownloadedAt ? 1 : 0);
    case Column.QuotesDue:
      return (quote) => quote.tenderQuotesDue;
    case Column.Package:
      return (quote) => quote.packageTitle;
    default:
      throw new Error(`invalid column ${column}`);
  }
};

export default (quotes: QuoteList, sortBy: Column, sortDir: SortDirection) => {
  const sortFactor = sortDir === SortDirection.ASC ? -1 : 1;
  const getSortVal = getSortFunc(sortBy);

  return groupSortedQuotesByProject(
    [...quotes].sort((a, b) => {
      const aVal = getSortVal(a);
      const bVal = getSortVal(b);

      if (aVal === bVal) {
        return 0;
      }

      // e.g. null packages go after packages beginning with Z when sorted DESC
      if (aVal === null) return sortFactor * 1;
      if (bVal === null) return sortFactor * -1;

      return aVal < bVal ? sortFactor : sortFactor * -1;
    }),
  );
};
