import { gql } from '@apollo/client';

const Address = {
  fragments: {
    detailedAddress: gql`
      fragment DetailedAddress on Address {
        id
        address1
        address2
        address3
        city
        district
        province
        suburb
        postcode
        state {
          shortName
        }
      }
    `,
  },
};

export default Address;
