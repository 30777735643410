import { useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Button,
  ButtonVariant,
  FontStyle,
  Heading,
  Modal,
  ModalSize,
} from '@estimateone/frontend-components';
import { DELETE_QUOTE } from './mutations';
import { EntityId } from '../../../../types';
import { DeleteQuote, DeleteQuoteVariables } from './types/DeleteQuote';
import styles from './styles.scss';

type QuoteDeleteModalProps = {
  quoteId: EntityId;
  removeModal: () => void;
  onComplete?: (id: EntityId) => void;
};

const QuoteDeleteModal = ({ quoteId: id, removeModal, onComplete }: QuoteDeleteModalProps) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [deleteQuote] = useMutation<DeleteQuote, DeleteQuoteVariables>(DELETE_QUOTE, {
    variables: { input: { id } },
  });

  const handleSubmit = async () => {
    setSubmitting(true);

    await deleteQuote();

    if (onComplete) onComplete(id);
    if (removeModal) removeModal();
  };

  return (
    <Modal isOpen onRequestClose={removeModal} size={ModalSize.Small}>
      <Modal.Section>
        <Heading level={1} renderAs={FontStyle.h3}>
          Delete Quote
        </Heading>
        <p className={styles.modalContent}>
          Are you sure you want to delete this quote and all its revisions?
        </p>
        <Button
          type="button"
          variant={ButtonVariant.Red}
          onClick={handleSubmit}
          isDisabled={isSubmitting}
        >
          Delete quote and all revisions
        </Button>
      </Modal.Section>
    </Modal>
  );
};

export default QuoteDeleteModal;
