import { ReactElement } from 'react';
import { createRoot, Root } from 'react-dom/client';

const buildTree = (element: ReactElement, ...parentsOuterFirst: ReactElement[]) =>
  parentsOuterFirst
    .concat([element])
    .reduceRight((tree, e) => ({ ...e, ...{ props: { ...e.props, children: tree } } }));

export const renderWithParents =
  <T extends DOMStringMap>(...parents: ReactElement[]) =>
  (containerId: string) =>
  (componentFactory: (d: T) => ReactElement): HTMLElement | undefined => {
    const container = document.getElementById(containerId);
    if (!container) {
      // this happens when the container to mount into is not on the current page,
      // e.g. it's for a different page to what we're viewing.
      return undefined;
    }

    const root = createRoot(container as HTMLElement);
    const element = componentFactory(container.dataset as T);

    if (element) {
      root.render(buildTree(element, ...parents));
    }
    return container;
  };

export const renderWithParentsFromRoot =
  <T extends DOMStringMap>(...parents: ReactElement[]) =>
  (container: HTMLElement | null, root: Root | undefined) =>
  (componentFactory: (d: T) => ReactElement): HTMLElement | undefined => {
    if (!container) {
      // this happens when the container to mount into is not on the current page,
      // e.g. it's for a different page to what we're viewing.
      return undefined;
    }

    const element = componentFactory(container.dataset as T);
    if (element && root) {
      root.render(buildTree(element, ...parents));
    }
    return container;
  };
