import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const SUBBIE_REVISE_QUOTE = gql`
  mutation SubbieReviseQuote($input: ReviseQuoteInput!) {
    reviseQuote(input: $input) {
      __typename
      ... on Quote {
        id
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
