import 'core-js/stable';
import 'custom-event-polyfill';
import 'isomorphic-unfetch';
import { SubbieApiProvider } from '@api/ApiProvider';
import { renderWithParents } from '@ascension/components/render-helper';
import { TranslationProvider } from '@profiles/providers/TranslationsProvider';
import AddQuotePage from '@subbie/pages/Quotes/AddQuotePage';
import QuoteListPage from '@subbie/pages/Quotes/List';
import ReviseQuotePage from '@subbie/pages/Quotes/ReviseQuotePage';
import QuoteViewPage from '@subbie/pages/Quotes/View';
import { parseIntIfDefined } from './util';
import { CurrencyInfoContextProvider } from '@shared';
import { FileManagerParams } from '@shared/FileUploader/types';
import QuotesErrorBoundary from '@shared/Quotes/ErrorBoundaries';
import { TwigGlobalProvider } from '@builder/context/TwigFeatureProvider/TwigGlobalProvider';

const renderQuotesPage = <T extends DOMStringMap>(containerId: string) =>
  renderWithParents<T>(
    <QuotesErrorBoundary />,
    <SubbieApiProvider />,
    <CurrencyInfoContextProvider />,
    <TranslationProvider />,
  )(containerId);

renderQuotesPage<{
  quoteId: string;
  quotesListUrl: string;
  returnTo: string;
  returnUrl: string;
  hasFixedPriceQuotesFeature: string;
}>('subbie-quote-view-page')(
  ({ quoteId, quotesListUrl, returnTo, returnUrl, hasFixedPriceQuotesFeature }) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1' }}
    >
      <QuoteViewPage
        quoteId={parseInt(quoteId)}
        quotesListUrl={quotesListUrl}
        returnTo={parseInt(returnTo)}
        returnUrl={returnUrl}
      />
    </TwigGlobalProvider>
  ),
);

renderQuotesPage('subbie-quote-upload-page')(
  ({
    fileManagerLocale,
    projectId,
    returnUrl,
    packageId,
    stageId,
    hasFixedPriceQuotesFeature,
  }: {
    projectId: string;
    returnUrl: string;
    packageId: string;
    stageId: string;
    hasFixedPriceQuotesFeature: string;
  } & Pick<FileManagerParams, 'fileManagerLocale'>) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1' }}
    >
      <AddQuotePage
        fileManagerLocale={fileManagerLocale}
        projectId={parseInt(projectId)}
        returnUrl={returnUrl}
        packageId={parseIntIfDefined(packageId)}
        stageId={parseIntIfDefined(stageId)}
      />
    </TwigGlobalProvider>
  ),
);

renderQuotesPage('subbie-quote-revise-page')(
  ({
    fileManagerLocale,
    quoteId,
    returnUrl,
    hasFixedPriceQuotesFeature,
  }: {
    quoteId: string;
    returnUrl: string;
    hasFixedPriceQuotesFeature: string;
  } & Pick<FileManagerParams, 'fileManagerLocale'>) => (
    <TwigGlobalProvider
      options={{ hasFixedPriceQuotesFeature: hasFixedPriceQuotesFeature === '1' }}
    >
      <ReviseQuotePage
        fileManagerLocale={fileManagerLocale}
        returnUrl={returnUrl}
        quoteId={parseInt(quoteId)}
      />
    </TwigGlobalProvider>
  ),
);

renderQuotesPage('subbie-quote-list-page')(() => <QuoteListPage />);
