export enum AddQuoteFormSpecificFieldName {
  StagePackages = 'quoteStagePackages',
}

export enum AddQuoteFormSpecificPropertyPath {
  Packages = 'packages',
}

export enum StagePackagesSubField {
  Stage = 'stage',
  IsSelected = 'isSelected',
  IsWithdrawn = 'isWithdrawn',
  Package = 'package',
}

export enum StagePackageApiErrorKey {
  StageId = 'stageId',
  Target = 'target',
  Message = 'message',
}

export enum StagePackageApiErrorTarget {
  Stage = 'stage',
  Package = 'package',
}

export const StagePackagesSubFieldApiErrorTargetMap = {
  [StagePackagesSubField.Stage]: StagePackageApiErrorTarget.Stage,
  [StagePackagesSubField.Package]: StagePackageApiErrorTarget.Package,
};

export enum StagePackagesFieldError {
  FieldEmpty = 'Select one or more builders and a package for each',
  PackageEmpty = 'Please select a package',
  QuoteExists = 'A quote for this package exists',
}
