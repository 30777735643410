import { useField } from 'react-final-form';
import { TextInput, TextInputProps } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';

export type TextInputFieldProps = {
  fieldName: string;
  defaultValue?: string;
  id: string;
  label: string;
  validate?: (value: string) => string[] | undefined;
  inlineValidate?: boolean;
} & Partial<TextInputProps>;

export const TextInputField = ({
  fieldName,
  defaultValue,
  id,
  label,
  validate,
  inlineValidate = false,
  ...props
}: TextInputFieldProps) => {
  const {
    input,
    meta: { error, submitError, touched, pristine, data },
  } = useField(fieldName, {
    validate,
    defaultValue,
  });

  return (
    <FieldWrapper
      errors={error || submitError}
      information={!pristine && data?.information}
      showErrors={touched || (inlineValidate && !pristine)}
    >
      <TextInput id={id} label={label} {...input} {...props} />
    </FieldWrapper>
  );
};
