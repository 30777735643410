import { GetProjectAndQuotableStages_project as Project } from '../../../../pages/Quotes/AddQuotePage/types/GetProjectAndQuotableStages';
import { StagePackagePackageValue } from '../types';

type ActivePackage = Project['quotableStages'][0]['activePackages'][0];

export const fieldNameGenerator =
  <T>(parentFieldName: string) =>
  (subFieldName: T) =>
    [parentFieldName, subFieldName].join('.');

export const packageToOption = ({
  id: value,
  title: label,
  mostRecentQuoteForCurrentAccount,
}: ActivePackage): StagePackagePackageValue => ({
  value,
  label,
  mostRecentQuoteId: mostRecentQuoteForCurrentAccount?.id,
});
