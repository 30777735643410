import { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Row, Wrapper } from '@estimateone/frontend-components';
import { Pricing } from '../../../fec';
import TitleHero from '../Page/TitleHero';
import { QuoteExtraFiles, QuoteFile } from '../QuoteFiles';
import QuoteActions from './QuoteActions';
import QuoteActivityDigest from './QuoteActivityDigest';
import QuoteContactsRow from './QuoteContactsRow';
import QuoteDeleteModal from './QuoteDeleteModal';
import QuoteNav from './QuoteNav';
import QuoteNoteForm from './QuoteNoteForm';
import QuoteVersions from './QuoteVersions';
import { CurrencyInfoContext, useHasFixedPriceQuotesFeature } from '@shared';
import { MARK_QUOTE_AS_VIEWED } from './mutations';
import {
  GetQuoteAndVersions_quote as Quote,
  GetQuoteAndVersions_quote_siblings as QuoteSiblings,
} from './types/GetQuoteAndVersions';
import { MarkQuoteAsViewed, MarkQuoteAsViewedVariables } from './types/MarkQuoteAsViewed';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

const ANCHOR_DETAILS = 'quotedetails';
const ANCHOR_NOTES = 'quotenotes';

type Note = Quote['notes'][0];

export type CommonQuoteViewProps = {
  quotesListUrl: string;
  returnTo: number;
  returnUrl: string;
};

export type QuoteViewProps = CommonQuoteViewProps & {
  quote: Quote;
  handleViewSubbieDetails?: () => void;
};

export type QuoteViewPageProps = CommonQuoteViewProps & {
  quoteId: EntityId;
};

const QuoteView = ({
  quote,
  quote: {
    id,
    additionalFiles,
    clarifications,
    activityLogs,
    rfq,
    stageName,
    package: pkg,
    recipientName,
    recipientAddress,
  },
  quotesListUrl,
  returnTo,
  returnUrl,
  handleViewSubbieDetails = undefined,
}: QuoteViewProps) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [markQuoteAsViewed] = useMutation<MarkQuoteAsViewed, MarkQuoteAsViewedVariables>(
    MARK_QUOTE_AS_VIEWED,
  );
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    markQuoteAsViewed({ variables: { input: { id } } });
  }, [id]);

  const [{ currencyInfo }] = useContext(CurrencyInfoContext);
  const hasFixedPriceQuotesFeature = useHasFixedPriceQuotesFeature();

  const quoteVersions = [quote as QuoteSiblings].concat(quote.siblings);
  const currentNotes = quoteVersions.reduce((acc, { notes }) => acc.concat(notes), [] as Note[]);

  const hasAdditionalFiles = additionalFiles.length !== 0;

  return (
    <div className={styles.quoteViewPage}>
      <TitleHero
        title={stageName ?? ''}
        subtitle={`${pkg?.title} package`}
        returnUrl={returnUrl}
        nav={
          <QuoteNav
            detailsAnchor={ANCHOR_DETAILS}
            notesAnchor={ANCHOR_NOTES}
            activityCount={currentNotes.length + activityLogs.length}
          />
        }
      />

      <Wrapper>
        <Row>
          <Col>
            <h4 id={ANCHOR_DETAILS}>Quote summary</h4>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <QuoteContactsRow
              builderName={recipientName}
              builderAddress={recipientAddress}
              subbieCompanyName={rfq?.companyName ?? null}
              subbieAddress={rfq?.contactAddress ?? null}
              subbieUserName={rfq?.contactName ?? null}
              handleViewSubbieDetails={handleViewSubbieDetails}
            />

            <Row>
              <Col span={12}>
                <p className={styles.sectionSubtitle}>Quote file</p>
                <QuoteFile file={quote} />
              </Col>
            </Row>

            {hasAdditionalFiles && (
              <Row>
                <Col span={12}>
                  <p className={styles.sectionSubtitle}>Extra files</p>
                  <QuoteExtraFiles
                    files={additionalFiles.map((f) => ({ ...f, ...{ id: f.id.toString() } }))}
                  />
                </Col>
              </Row>
            )}

            {hasFixedPriceQuotesFeature && (
              <Row>
                <Col span={12}>
                  <p className={styles.sectionSubtitle}>Fixed quote confirmation</p>
                  <p>
                    {quote.confirmFixedPrice
                      ? 'Yes, I confirm that the quote provided is fixed and remains valid for the specified validity period stated in the quote.'
                      : 'No'}
                  </p>
                </Col>
              </Row>
            )}

            {clarifications && (
              <Row>
                <Col span={12}>
                  <p className={styles.sectionSubtitle}>Quote message</p>
                  <p className={styles.quoteMessage}>{clarifications}</p>
                </Col>
              </Row>
            )}

            <Row>
              <Col span={12}>
                <p className={styles.sectionSubtitle}>
                  Quote amount (excluding {currencyInfo?.excludeTaxCode ?? 'GST'})
                </p>
                <p className={styles.quoteAmount}>
                  <Pricing price={quote.amount ?? 0} /> (excluding{' '}
                  {currencyInfo?.excludeTaxCode ?? 'GST'})
                </p>
              </Col>
            </Row>
          </Col>

          <Col span={3} offset={1}>
            <QuoteActions quote={quote} handleDeleteClicked={() => setShowDeleteModal(true)} />

            <p className={styles.sectionSubtitle}>You&apos;re viewing</p>
            <QuoteVersions quote={quote} quoteVersions={quoteVersions} returnTo={returnTo} />
          </Col>
        </Row>

        <Row>
          <Col span={12}>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Row>
              <Col span={12}>
                <h4 id={ANCHOR_NOTES}>Notes &amp; history</h4>
                <p className={styles.notesTip}>
                  <strong>Tip:</strong> Notes are seen by your team only.
                </p>

                <QuoteNoteForm quoteId={id} />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <QuoteActivityDigest notes={currentNotes} logs={activityLogs} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Wrapper>

      {showDeleteModal && (
        <QuoteDeleteModal
          quoteId={id}
          removeModal={() => setShowDeleteModal(false)}
          onComplete={() => {
            window.location.replace(quotesListUrl);
          }}
        />
      )}
    </div>
  );
};

export default QuoteView;
