import { Fragment } from 'react';
import Routing from 'routing';
import { E1Link, Timeline, TimelineSize } from '@estimateone/frontend-components';
import moment from 'moment-timezone';
import { COMMON_DATE_FORMAT } from './consts/displayFormats';
import { EntityId } from '../../../../types';
import {
  GetQuoteAndVersions_quote as Quote,
  GetQuoteAndVersions_quote_siblings as Sibling,
} from './types/GetQuoteAndVersions';
import styles from './styles.scss';

type ReturnUrlAware = { returnTo: number };

const QuoteVersionTimeline = ({
  currentQuoteId,
  quotes,
  returnTo,
}: {
  currentQuoteId: EntityId;
  quotes: Sibling[];
} & ReturnUrlAware) => (
  <Timeline size={TimelineSize.Large} fullWidth>
    {quotes.map(({ id, version, createdAt, createdBy: { fullName } }) => (
      <Timeline.Segment isHighlighted={id === currentQuoteId} key={id}>
        <p>
          <E1Link link={Routing.generate('app_quote_view', { id, returnTo })}>
            Version {version}
          </E1Link>
        </p>
        <p className={styles.uploadedDetails}>
          {moment(createdAt).format(COMMON_DATE_FORMAT)} <br />
          {fullName}
        </p>
      </Timeline.Segment>
    ))}
  </Timeline>
);

const QuoteVersions = ({
  quote,
  quoteVersions,
  returnTo,
}: { quote: Quote; quoteVersions: Sibling[] } & ReturnUrlAware) => {
  const quoteVersionSort = (a: Sibling, b: Sibling) => {
    const versionA = a.version ?? 0;
    const versionB = b.version ?? 0;

    return versionB - versionA;
  };

  const quotes = quoteVersions.sort(quoteVersionSort);

  const { firstName, lastName } = quote.createdBy;

  return (
    <div className={styles.versions}>
      <p className={styles.cardTitle}>Version {quote.version}</p>
      <p className={styles.cardSubtitle}>{quote.fileName}</p>
      <p className={styles.uploadedDetails}>
        {moment(quote.createdAt).format(COMMON_DATE_FORMAT)} <br />
        Uploaded by {`${firstName} ${lastName}`}
      </p>
      {quotes.length > 0 && (
        <Fragment>
          <hr />
          <p>Quote history</p>
          <QuoteVersionTimeline
            currentQuoteId={quote.id}
            quotes={quoteVersions}
            returnTo={returnTo}
          />
        </Fragment>
      )}
    </div>
  );
};

export default QuoteVersions;
