import Moment from 'moment-timezone';
import { getUserTimezone } from '@ascension/js/app/timezone';
import HighlightedText from '../../HighlightedText';
import { COMMON_DATE_FORMAT } from './consts/displayFormats';
import { GetQuoteAndVersions_quote as Quote } from './types/GetQuoteAndVersions';
import styles from './styles.scss';

type Log = Quote['activityLogs'][0];
type Note = Quote['notes'][0];
type CreatedComparable = { createdAt: GraphQLDateTime };

const sortByCreatedAt = (
  { createdAt: a }: CreatedComparable,
  { createdAt: b }: CreatedComparable,
) => new Date(b).getTime() - new Date(a).getTime();

const getTzDateTime = (timezone: string) => (createdAt: string) =>
  Moment(createdAt).tz(timezone).format(COMMON_DATE_FORMAT);

const getFormattedDateTime = getTzDateTime(getUserTimezone());

const QuoteNote = ({ note }: { note: Note }) => (
  <li className={styles.entryNote}>
    <p>{note.text}</p>
    <p className={styles.author}>
      <strong>{note.authorName}</strong>
      <span className={styles.date}>{getFormattedDateTime(note.createdAt)}</span>
    </p>
  </li>
);

const QuoteLog = ({ log }: { log: Log }) => (
  <li className={styles.entryLog}>
    <p>
      <HighlightedText term={log.createdByFullName} text={log.message ?? ''} />
    </p>
    <p>
      <span className={styles.date}>{getFormattedDateTime(log.createdAt)}</span>
    </p>
  </li>
);

const QuoteActivityDigest = ({ notes, logs }: { notes: Note[]; logs: Log[] }) => (
  <ul className={styles.quoteActivityDigest}>
    {[...notes, ...logs].sort(sortByCreatedAt).map((entry) => {
      const key = `${entry.__typename}_${entry.id}`;
      switch (entry.__typename) {
        case 'QuoteNote':
          return <QuoteNote key={key} note={entry} />;
        case 'QuoteActivityLog':
          return <QuoteLog key={key} log={entry} />;
        default:
          return null;
      }
    })}
  </ul>
);

export default QuoteActivityDigest;
