import Routing from 'routing';
import { Button, Card, CardVariant } from '@estimateone/frontend-components';
import { LangRef, getTranslation } from '@shared/Util/lang';
import { ProductType } from '@ascension/enums';
import styles from './styles.scss';

const upgradeUrl = Routing.generate('app_accountupgrade_index');

const FreePlanCard = () => (
  <Card variant={CardVariant.Grey}>
    <Card.Body isWhite>
      <div>
        <strong className={styles.heading}>{ProductType.SubbieFree} plan</strong>
      </div>

      <div className={styles.contents}>
        <p>
          Upgrade your plan today and get access to all projects and winning{' '}
          {getTranslation(LangRef.BUILDERS)}.
        </p>

        <Button link={upgradeUrl}>See plans and pricing</Button>
      </div>
    </Card.Body>
  </Card>
);

export default FreePlanCard;
