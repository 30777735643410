import { captureException } from '@sentry/browser';

type OptionalContext = Parameters<typeof captureException>[1];

export const reportError = (error: Error, context?: OptionalContext) => {
  if (process.env.NODE_ENV === 'development') {
    /* eslint-disable no-console */
    console.group('reportError');
    console.error('error', error);
    console.error('context', context ?? '-');
    console.groupEnd();
    /* eslint-enable no-console */
    throw error;
  } else {
    captureException(error, context);
  }
};
