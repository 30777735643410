import { useQuery } from '@apollo/client';
import ReviseQuoteForm from '../../../features/QuotesFeature/ReviseQuoteForm';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import QuotePageLayout from '../../../../shared/Quotes/Page/QuotePageLayout';
import { useSetCurrency } from '@shared';
import { FileManagerParams } from '@shared/FileUploader/types';
import { quoteFileToFile } from '@shared/Quotes/Form/transformers';
import { graphqlTypeToString } from '@shared/Quotes/utils/Address';
import { SUBBIE_GET_QUOTE_FOR_REVISION } from './queries';
import {
  SubbieGetQuoteForRevision,
  SubbieGetQuoteForRevisionVariables,
} from './types/SubbieGetQuoteForRevision';
import { EntityId } from '@ascension/types';

type ReviseQuotePageProps = Pick<FileManagerParams, 'fileManagerLocale'> & {
  quoteId: EntityId;
  returnUrl: string;
};

const PageContent = ({ fileManagerLocale, quoteId, returnUrl }: ReviseQuotePageProps) => {
  const { data, loading } = useQuery<SubbieGetQuoteForRevision, SubbieGetQuoteForRevisionVariables>(
    SUBBIE_GET_QUOTE_FOR_REVISION,
    { variables: { id: quoteId } },
  );

  useSetCurrency(data?.quote.recipientCountry?.id);

  if (loading || !data) return <PageLoadingSpinner />;

  const {
    quote: {
      projectAddress,
      stageName,
      package: stagePackage,
      recipientName,
      additionalFiles,
      clarifications,
      version,
    },
  } = data;

  return (
    <div data-revising-version={version}>
      <ReviseQuoteForm
        fileManagerLocale={fileManagerLocale}
        quoteId={quoteId}
        projectAddress={graphqlTypeToString(projectAddress)}
        stageName={stageName}
        packageTitle={stagePackage!.title} // TODO: revisable quotes have a package by definition
        builderName={recipientName}
        extraFiles={additionalFiles.map(quoteFileToFile)}
        quoteDetails={clarifications ?? undefined}
        returnUrl={returnUrl}
      />
    </div>
  );
};

const ReviseQuotePage = ({ returnUrl, ...contentProps }: ReviseQuotePageProps) => (
  <QuotePageLayout title="Revise quotes" returnUrl={returnUrl}>
    <PageContent returnUrl={returnUrl} {...contentProps} />
  </QuotePageLayout>
);

export default ReviseQuotePage;
