import { Col, Row } from '@estimateone/frontend-components';
import QuoteContact from './QuoteContact';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { AddressDetails } from '@api/fragments/Address/types';

type QuoteContactsRowProps = {
  builderName: string;
  builderAddress: AddressDetails | null;
  subbieCompanyName: string | null;
  subbieAddress: AddressDetails | null;
  subbieUserName: string | null;
  handleViewSubbieDetails?: () => void;
};

const QuoteContactsRow = ({
  builderName,
  builderAddress,
  subbieCompanyName,
  subbieAddress,
  subbieUserName,
  handleViewSubbieDetails = undefined,
}: QuoteContactsRowProps) => (
  <Row>
    <Col span={6}>
      <QuoteContact
        title={getTranslation(LangRef.HEAD_CONTRACTOR_HEADING)}
        companyName={builderName}
        companyAddress={builderAddress}
      />
    </Col>
    <Col span={6}>
      <QuoteContact
        title="Subcontractor"
        companyName={subbieCompanyName}
        companyAddress={subbieAddress}
        contactName={subbieUserName}
        handleViewContactDetails={handleViewSubbieDetails}
      />
    </Col>
  </Row>
);

export default QuoteContactsRow;
