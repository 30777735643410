import { Card, CardVariant, joinClassNames } from '@estimateone/frontend-components';
import { Pricing, PricingVariant } from '@ascension/components/fec';
import { BillingCycle } from '@ascension/enums';
import styles from './styles.scss';

type BillingCycleOptionProps = {
  billingCycle: BillingCycle;
  onClick: () => void;
  price: number;
  isSelected: boolean;
  saving?: number;
};

const BillingCycleOption = ({
  billingCycle,
  onClick,
  price,
  isSelected,
  saving = undefined,
}: BillingCycleOptionProps) => {
  const isAnnual = billingCycle === BillingCycle.Months12;

  return (
    <button type="button" className={styles.billingCycleOption} onClick={onClick}>
      <Card variant={isSelected ? CardVariant.Blue : CardVariant.Grey}>
        {saving && saving > 0 && (
          <Card.Banner>
            <span className={styles.saving}>
              Save <Pricing price={saving} />
            </span>
          </Card.Banner>
        )}
        <Card.Body isWhite={!isSelected}>
          <header className={joinClassNames(styles.billingCycleName, !saving && styles.noSaving)}>
            {isAnnual ? 'Annual' : 'Monthly'}
          </header>
          <h2 className={styles.price}>
            <Pricing hideCents={price === 0} price={price} variant={PricingVariant.Featured} />
          </h2>
          <p className={styles.billingCycleFrequency}>per {isAnnual ? 'year' : 'month'}</p>
          <p className={styles.exGST}>(ex. GST)</p>
          <p className={styles.recurringBilling}>
            {isAnnual ? 'Annual recurring billing.' : 'Recurring billing, cancel at any time.'}
          </p>
        </Card.Body>
      </Card>
    </button>
  );
};

type BillingCycleSelectorProps = {
  annualPrice: number;
  monthlyPrice: number;
  onSelection: (length: BillingCycle) => void;
  selectedBillingCycle?: BillingCycle;
};

const BillingCycleSelector = ({
  annualPrice,
  monthlyPrice,
  onSelection,
  selectedBillingCycle = BillingCycle.Months12,
}: BillingCycleSelectorProps) => {
  const annualSaving = monthlyPrice * 12 - annualPrice;

  const pricingOptions = [
    {
      price: annualPrice,
      billingCycle: BillingCycle.Months12,
      saving: annualSaving,
    },
    {
      price: monthlyPrice,
      billingCycle: BillingCycle.Months1,
      saving: undefined,
    },
  ].map(({ billingCycle, price, saving }) => (
    <BillingCycleOption
      key={`${billingCycle}-${price}`}
      billingCycle={billingCycle}
      price={price}
      saving={saving}
      isSelected={selectedBillingCycle === billingCycle}
      onClick={() => onSelection(billingCycle)}
    />
  ));

  return <div className={styles.billingOptions}>{pricingOptions}</div>;
};

export default BillingCycleSelector;
