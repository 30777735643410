import { useCallback, useState } from 'react';
import { Pagination } from '@estimateone/frontend-components';
import natsort from 'natsort';
import Paginate from '../Paginate';
import SortableTable, { ColumnConfig } from '../SortableTable';
import { SortDirection } from '../types';

type SortBy<T> = {
  column: keyof T;
  direction: SortDirection;
};

type GenericDataTableProps<T> = {
  columnConfig: ColumnConfig<T>;
  tableData: T[];
  initialSortBy?: SortBy<T>;
  perPage?: number;
  extraClasses?: string;
};

const GenericDataTable = <T extends { id: number }>({
  columnConfig,
  tableData,
  initialSortBy,
  perPage = 25,
  extraClasses,
}: GenericDataTableProps<T>) => {
  const [pageNum, setPageNum] = useState(1);
  const [sortBy, setSortBy] = useState<SortBy<T> | undefined>(initialSortBy);

  const pageCount = new Paginate(pageNum, perPage, tableData.length).calcPageCount();

  const updateSort = (sortColumn: keyof T, sortDirection: SortDirection) => {
    setPageNum(1);
    setSortBy({ column: sortColumn, direction: sortDirection });
  };

  const getSortedData = useCallback(
    (sortByColumn?: keyof T, sortByDirection?: SortDirection) => {
      const sorter = natsort();
      const sortedTableData = tableData.sort((a, b) =>
        !sortByColumn || a[sortByColumn] === b[sortByColumn]
          ? sorter(a.id, b.id)
          : sorter(a[sortByColumn] as unknown as number, b[sortByColumn] as unknown as number),
      );
      if (sortByDirection === SortDirection.DESC) {
        sortedTableData.reverse();
      }
      const paginate = new Paginate(pageNum, perPage, tableData.length);
      return sortedTableData.filter((_, index) => paginate.isCurrentPage(index));
    },
    [pageNum, perPage, tableData],
  );

  return (
    <>
      <SortableTable<T>
        rows={getSortedData(sortBy?.column, sortBy?.direction)}
        columnConfig={columnConfig}
        sortBy={{
          direction: SortDirection.ASC,
          ...sortBy,
          fnSortData: (col: keyof T, dir: SortDirection) => updateSort(col, dir),
        }}
        extraClasses={extraClasses}
      />
      {pageCount > 1 ? (
        <Pagination
          currentPage={pageNum}
          pageCount={pageCount}
          fnSetPageNum={(newPageNum) => setPageNum(newPageNum)}
        />
      ) : null}
    </>
  );
};

export default GenericDataTable;
