import { useEffect } from 'react';
import { Row, Col, Modal, ModalSize, Button } from '@estimateone/frontend-components';
import { captureMessage, setTag } from '@sentry/browser';

const ApiVersionMismatchError = () => {
  useEffect(() => {
    captureMessage('GraphQL request failed: client/server version mismatch', 'info');
    setTag('category', 'apollo:versioning');
  });

  const handleReload = () => window.location.reload();

  return (
    <Modal size={ModalSize.Small} onRequestClose={handleReload} hideClose isOpen>
      <Modal.Section>
        <Row>
          <Col span={12} alignContentX="center">
            <h2>E1 has been updated</h2>
          </Col>
        </Row>
        <Row>
          <Col span={12} alignContentX="center">
            <p>A newer version of the application is available.</p>
            <p>Please reload your browser window or click the button below.</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} alignContentX="center" excludeBottomGutter>
            <Button onClick={handleReload}>Update now</Button>
          </Col>
        </Row>
      </Modal.Section>
    </Modal>
  );
};

export default ApiVersionMismatchError;
