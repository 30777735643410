type HighlightedTextProps = {
  text: string;
  term?: string | null;
};

const HighlightedText = ({ text, term }: HighlightedTextProps) => {
  const countMap = new Map();
  const terms = (term || '').replace(/"/g, '').toLowerCase().split(' ');
  return (
    <>
      {text
        .replace(/\r?\n|\r/g, ' ')
        .split(' ')
        .map((word) => {
          const count = (countMap.get(word) || 0) + 1;
          countMap.set(word, count);
          return (
            <span key={`${word}-${count}`}>
              {terms.includes(word.replace(',', '').toLowerCase()) ? (
                <em>{`${word} `}</em>
              ) : (
                `${word} `
              )}
            </span>
          );
        })}
    </>
  );
};

export default HighlightedText;
