import { ApolloClient, ApolloLink, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { InMemoryCache, PossibleTypesMap } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import TokenCache from '@api/GatewayProvider/TokenCache';
import { isServerError } from '@api/GatewayProvider/util';
import * as adminFragment from '@api/fragments-admin.json';

export const createBearerTokenClient = (
  uri: string,
  idpAuthoriseUri: string,
  fragment?: { possibleTypes: PossibleTypesMap },
): ApolloClient<NormalizedCacheObject> => {
  const tokenCache = new TokenCache(idpAuthoriseUri);

  const authLink = setContext(async (_, { headers }) => ({
    // return the headers to the context so httpLink can read them
    headers: {
      authorization: `Bearer ${await tokenCache.getToken()}`,
      ...headers,
    },
  }));

  const resetTokenOnErrorLink = onError(({ networkError }) => {
    if (networkError && isServerError(networkError) && networkError.statusCode === 401) {
      tokenCache.expireToken();
    }
  });

  const { possibleTypes } = fragment || adminFragment;

  return new ApolloClient({
    cache: new InMemoryCache({ possibleTypes }),
    link: ApolloLink.from([
      // This will retry both getting the token and the GraphQL request 5 times with backoff
      // https://www.apollographql.com/docs/react/api/link/apollo-link-retry/#default-configuration
      new RetryLink(),
      authLink,
      resetTokenOnErrorLink,
      new HttpLink({
        uri,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }),
    ]),
  });
};
