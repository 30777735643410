import { useEffect } from 'react';
import { Row, Col, Button, Modal, ModalSize } from '@estimateone/frontend-components';
import { captureMessage, setTag } from '@sentry/browser';

const AuthenticationError = () => {
  useEffect(() => {
    captureMessage('GraphQL request failed: session expired?', 'warning');
    setTag('category', 'apollo:authentication');
  });

  const handleReload = () => window.location.reload();

  return (
    <Modal size={ModalSize.Small} onRequestClose={handleReload} hideClose isOpen>
      <Modal.Section>
        <Row>
          <Col span={12} alignContentX="center">
            <h2>Authentication Timeout</h2>
          </Col>
        </Row>
        <Row>
          <Col span={12} alignContentX="center">
            <p style={{ textAlign: 'center' }}>
              Uh oh, we can&apos;t process that request, as it looks like you&apos;ve been logged
              out from your account.
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12} alignContentX="center" excludeBottomGutter>
            <Button onClick={handleReload}>Log back in</Button>
          </Col>
        </Row>
      </Modal.Section>
    </Modal>
  );
};

export default AuthenticationError;
