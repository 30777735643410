import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { getTransformedSubmitErrors } from './transformers';
import { useHasFixedPriceQuotesFeature } from '@shared';
import { fileToFileInput } from '@shared/Quotes/Form/transformers';
import { CREATE_QUOTES_FOR_PROJECT } from './mutations';
import { AddQuoteFormSpecificFieldName, StagePackagesSubField } from './enums';
import { QuoteFormCommonFieldName } from '@ascension/enums';
import { QuoteSubbieAddFormValues, StagePackageValue } from './types';
import {
  CreateQuotesForProject,
  CreateQuotesForProject_createQuotesForProject_Quotes as Quotes,
  CreateQuotesForProjectVariables,
} from './types/CreateQuotesForProject';
import { CreateQuotesForProjectInput } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';

type Quote = Quotes['quotes'][0];

export const useCreateQuotesForProject = (projectId: EntityId) => {
  const [newQuotes, setNewQuotes] = useState<Quote[]>();
  const hasFixedPriceQuoteFeature = useHasFixedPriceQuotesFeature();

  const transformValues = useCallback(
    (values: QuoteSubbieAddFormValues): CreateQuotesForProjectInput => ({
      projectId,
      packageIds: (values[AddQuoteFormSpecificFieldName.StagePackages] || []).reduce(
        (acc: EntityId[], _: StagePackageValue) => {
          if (!_[StagePackagesSubField.IsSelected]) return acc;
          const packageId = _[StagePackagesSubField.Package]?.value;
          return packageId ? acc.concat(packageId) : acc;
        },
        [],
      ),
      amount: values[QuoteFormCommonFieldName.Amount]!, // TODO: differentiate between filled and unfilled form
      quoteFile: fileToFileInput(values[QuoteFormCommonFieldName.QuoteFile]!),
      additionalFiles: (values[QuoteFormCommonFieldName.ExtraFiles] || []).map(fileToFileInput),
      details: values[QuoteFormCommonFieldName.Details],
      confirmFixedPrice: hasFixedPriceQuoteFeature
        ? (values[QuoteFormCommonFieldName.ConfirmFixedPrice] ?? false)
        : undefined,
    }),
    [hasFixedPriceQuoteFeature, projectId],
  );

  const [createQuotes, { data }] = useMutation<
    CreateQuotesForProject,
    CreateQuotesForProjectVariables
  >(CREATE_QUOTES_FOR_PROJECT);

  const submit = async (values: QuoteSubbieAddFormValues) => {
    const createQuotesForProjectData = await createQuotes({
      variables: { input: transformValues(values) },
    });

    if (createQuotesForProjectData?.data?.createQuotesForProject.__typename === 'Errors') {
      return getTransformedSubmitErrors(
        values[AddQuoteFormSpecificFieldName.StagePackages]!,
        createQuotesForProjectData.data.createQuotesForProject.errors,
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (data) {
      if (data.createQuotesForProject.__typename === 'Quotes') {
        setNewQuotes(data.createQuotesForProject.quotes);
      }
    }
  }, [data]);

  return { submit, newQuotes };
};
