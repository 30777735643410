import { Alert, AlertContainer } from 'react-bs-notifier';
import useNotificationContext from '@shared/context/NotificationContext';
import styles from './styles.scss';

const notificationsStyles = {
  alert: styles.alert,
  headline: styles.headline,
  msgContainer: styles.msgContainer,
  icon: styles.icon,
  close: styles.close,
};

type NotificationsProps = { position?: string; timeout?: number };

const Notifications = ({ position = 'bottom-left', timeout = 6000 }: NotificationsProps) => {
  const { notifications, removeNotification } = useNotificationContext();
  return (
    <AlertContainer position={position}>
      {notifications.map((item) => (
        <Alert
          key={`notification-${item.id}`}
          headline={item.headline}
          timeout={timeout}
          onDismiss={() => removeNotification(item)}
          dismissTitle="Close alert"
          classes={notificationsStyles}
        >
          {item.message}
        </Alert>
      ))}
    </AlertContainer>
  );
};

export default Notifications;
