import Link, { LinkProps } from './Link';
import UserActivity, { UserActivityProps } from './UserActivity';

const UserActivityLink = ({
  href,
  onClick,
  onClickParams,
  amount,
  showAmount = true,
  description,
}: LinkProps & UserActivityProps) => (
  <Link
    onClick={onClick}
    href={href}
    onClickParams={onClickParams}
    extraClasses={`${amount > 0 ? 'danger' : 'success'}`}
  >
    <UserActivity amount={amount} showAmount={showAmount} description={description} />
  </Link>
);

export default UserActivityLink;
