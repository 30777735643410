import { ARRAY_ERROR } from 'final-form';
import { FieldError } from '@shared/FinalForm/types';
import { transformQuoteFormApiErrors } from '@shared/Quotes/Form/transformers';
import {
  AddQuoteFormSpecificFieldName,
  AddQuoteFormSpecificPropertyPath,
  StagePackageApiErrorKey,
  StagePackageApiErrorTarget,
  StagePackagesSubField,
  StagePackagesSubFieldApiErrorTargetMap,
} from './enums';
import {
  StagePackageApiError,
  StagePackageError,
  StagePackageValue,
  SubbieAddQuoteFormErrors,
} from './types';
import { ApiError } from '@api/fragments/Errors/types';
import { EntityId } from '@ascension/types';

export const transformStagePackagesErrors = (
  stagePackageErrors: ApiError[],
): StagePackageApiError[] =>
  (stagePackageErrors || []).reduce((acc, { field, message }) => {
    if (!field) return acc;

    const [, stageId, target] = field.split('-') as [string, string, StagePackageApiErrorTarget];

    return [
      ...acc,
      {
        [StagePackageApiErrorKey.StageId]: stageId ? parseInt(stageId) : undefined,
        [StagePackageApiErrorKey.Target]: target,
        [StagePackageApiErrorKey.Message]: message,
      },
    ];
  }, new Array<StagePackageApiError>());

type CustomErrors = { [AddQuoteFormSpecificFieldName.StagePackages]: StagePackageApiError[] };

const transformAddQuoteFormApiErrors = (apiErrors: ApiError[]) =>
  transformQuoteFormApiErrors<CustomErrors>(apiErrors, [
    {
      fieldName: AddQuoteFormSpecificFieldName.StagePackages,
      propertyPath: AddQuoteFormSpecificPropertyPath.Packages,
      transformer: transformStagePackagesErrors,
    },
  ]);

export const getTransformedSubmitErrors = (
  stagePackagesValue: StagePackageValue[],
  apiErrors: ApiError[],
): SubbieAddQuoteFormErrors => {
  const { [AddQuoteFormSpecificFieldName.StagePackages]: quoteStagePackagesErrors, ...errors } =
    transformAddQuoteFormApiErrors(apiErrors);

  const getStagePackageErrorsForSubField = (
    stageId: EntityId | undefined,
    subField: keyof typeof StagePackagesSubFieldApiErrorTargetMap | undefined,
  ): FieldError[] | undefined =>
    quoteStagePackagesErrors?.reduce((acc: string[], _) => {
      if (
        subField === undefined ||
        (_[StagePackageApiErrorKey.StageId] === stageId &&
          _[StagePackageApiErrorKey.Target] === StagePackagesSubFieldApiErrorTargetMap[subField])
      )
        acc.push(_[StagePackageApiErrorKey.Message]);
      return acc;
    }, []);

  const transformedStagePackagesErrors: StagePackageError[] = stagePackagesValue.map(
    (stagePackage) => {
      const getErrorsForSubfield = (
        subField: keyof typeof StagePackagesSubFieldApiErrorTargetMap,
      ): FieldError[] | undefined =>
        getStagePackageErrorsForSubField(stagePackage[StagePackagesSubField.Stage].id, subField);

      return Object.keys(StagePackagesSubFieldApiErrorTargetMap).reduce(
        (subFieldErrors, subField: keyof typeof StagePackagesSubFieldApiErrorTargetMap) => ({
          ...subFieldErrors,
          [subField]: getErrorsForSubfield(subField),
        }),
        {},
      );
    },
  );

  // Special property for setting the FieldArray field-level errors
  /* eslint-disable fp/no-mutation */
  // @ts-ignore
  transformedStagePackagesErrors[ARRAY_ERROR] = getStagePackageErrorsForSubField(
    undefined,
    undefined,
  );
  /* eslint-enable */

  return {
    ...errors,
    [AddQuoteFormSpecificFieldName.StagePackages]: transformedStagePackagesErrors,
  };
};
