import { Component, MouseEvent, ReactElement } from 'react';
import { flushSync } from 'react-dom';
import Routing from 'routing';
import {
  Button,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  CheckboxStatus,
  Icon,
  IconName,
} from '@estimateone/frontend-components';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Uppy, { AddFileOptions, UppyFile, UppyOptions } from '@uppy/core';
import { DragDrop } from '@uppy/react';
import { v4 as generateUuidv4 } from 'uuid';
import PendingUploadManager, {
  ArchiveUpload,
  SavedFile,
  SubscriptionCallback,
  Upload,
} from '../../../js/classes/file_manager/PendingUploadManager';
import { getUserId } from '../../../js/utils/helpers';
import ArchiveUploadProgressBar from './ArchiveUploadProgressBar';
import CustomDragDropArea from './CustomDragDropArea';
import ErrorList from './ErrorList';
import { UploadProgressBar } from './ProgressBar';
import { UploadCategory } from '../../../enums';
import { FileManagerParams } from './types';
import styles from './styles.scss';

export enum UploadContext {
  APP,
  ITP,
}

export type ExistingFile = {
  name: string;
  size: number;
};

type IndexedUppyFileCollection = { [p: string]: UppyFile };

type SimpleFile = ExistingFile & { type?: string };
type FileManagerResponseFile = SimpleFile & { id: string; hash: string };
export type TransformedFile = FileManagerResponseFile & { fileName: string; fileSize: number };

export type FileUploaderProps = FileManagerParams & {
  context?: UploadContext;
  existingFiles?: ExistingFile[];
  allowDuplicates?: boolean;
  onFileUploaded?: (files: TransformedFile[], errors?: FileError[]) => void;
  onUploadComplete?: (files: TransformedFile[], errors?: FileError[]) => void;
  onAllUploadsComplete?: (files: TransformedFile[], errors?: FileError[]) => void;
  uppyOptions?: Partial<UppyOptions>;
  beforeContent?: ReactElement;
  category: UploadCategory;
  allowAutoUnzipChoice?: boolean;
  autoUnzip?: boolean;
  dragDropMessage?: string;
  browseMessage?: string;
} & typeof FileUploader.defaultProps;

// TODO: union type of file errors and general errors?
export type FileError = {
  file: {
    name: string;
  } | null;
  message: string;
};

type FileUploaderState = {
  uploadManager: PendingUploadManager;
  errors: FileError[];
  errorsCollapsed: boolean;
  restrictionViolation: boolean;
  filesAdded: UppyFile[];
  filesComplete: TransformedFile[];
  archivesPending: ArchiveUpload[];
  archivesComplete: ArchiveUpload[];
  autoUnzip: boolean;
  progress: number;
};

const fileInputToUppy = (file: File): AddFileOptions => ({
  name: file.name,
  type: file.type,
  data: file,
  source: 'Local',
  isRemote: false,
});

export default class FileUploader extends Component<FileUploaderProps, FileUploaderState> {
  static readonly defaultProps = {
    context: UploadContext.APP,
    existingFiles: new Array<ExistingFile>(),
    allowDuplicates: true,
    uppyOptions: {},
    allowAutoUnzipChoice: false,
    autoUnzip: false,
    dragDropMessage: 'Drag & drop your files here, or',
    browseMessage: 'browse files',
  };

  static readonly defaultUppyOptions = {
    meta: {
      unzip: false,
      deepUnzip: false,
    },
  };

  static readonly transformUpload = (upload: Upload): TransformedFile => ({
    id: upload.id.toString(),
    fileName: upload.name,
    fileSize: upload.size,
    type: undefined,
    name: upload.name,
    size: upload.size,
    hash: upload.hash,
  });

  static readonly transformSavedFile = (file: SavedFile): TransformedFile => ({
    id: file.id.toString(),
    fileName: file.file_name,
    fileSize: file.file_size,
    type: undefined,
    name: file.file_name,
    size: file.file_size,
    hash: file.hash,
  });

  static readonly transformError = ({
    name,
    errorMessage: message,
  }: {
    name: string;
    errorMessage: string;
  }) => ({
    file: {
      name,
    },
    message,
  });

  private readonly uppy: Uppy;

  private readonly processedUuids: Set<string>;

  constructor(props: FileUploaderProps) {
    super(props);

    const {
      context,
      uppyOptions,
      fileManagerLocale,
      category,
      parentType,
      parentId,
      autoUnzip,
      dragDropMessage,
    } = props;

    this.onPollingForAsyncComplete = this.onPollingForAsyncComplete.bind(this);

    // TODO: just make an ITPFileUploader
    const inItpContext = context === UploadContext.ITP;

    this.state = {
      uploadManager: new PendingUploadManager(
        parentId,
        parentType,
        Routing.generate(`s3_upload_notify${inItpContext ? '_itp' : ''}`, {
          category,
        }),
        Routing.generate(`s3_upload_uploads${inItpContext ? '_itp' : ''}`),
      ).addSubscriber(this.onPollingForAsyncComplete),
      errors: [],
      errorsCollapsed: false,
      filesAdded: [],
      filesComplete: [],
      archivesPending: [],
      archivesComplete: [],
      progress: 0,
      autoUnzip,
      restrictionViolation: false,
    };

    this.processedUuids = new Set<string>();

    this.uppy = new Uppy({
      allowMultipleUploadBatches: true,
      onBeforeUpload: this.onBeforeUpload.bind(this),
      onBeforeFileAdded: this.onBeforeFileAdded.bind(this),
      ...FileUploader.defaultUppyOptions,
      ...uppyOptions,
      ...{
        locale: {
          strings: {
            ...uppyOptions.locale?.strings,
            dropHereOr: dragDropMessage,
          },
        },
      },
    });

    this.uppy.use(AwsS3Multipart, {
      limit: 5,
      // Uppy adds the s3/multipart bit, but we still want to use generated routes
      companionUrl: Routing.generate(`s3_multipart_init${inItpContext ? '_itp' : ''}`).replace(
        's3/multipart',
        '',
      ),
      companionHeaders: { region: fileManagerLocale },
      abortMultipartUpload: () => {
        // Keep this for now to aid with testing new FM/Uppy integration
        // eslint-disable-next-line no-console
        console.error('Aborting - was the component prematurely unmounted?');
      },
    });

    this.bindEvents();
  }

  componentWillUnmount() {
    const { uploadManager } = this.state;
    this.uppy.close();
    uploadManager?.removeSubscriber(this.onPollingForAsyncComplete);
  }

  onPollingForAsyncComplete: SubscriptionCallback = (params) => {
    // Destructuring here inexplicably causes the variables to be assigned the wrong values, perhaps a memory bug
    const { pending } = params;
    const { completed } = params;
    const { pendingArchives } = params;
    const { unzippedArchives } = params;
    const { drafts } = params;
    const { duplicates } = params;
    const { invalid } = params;

    const { onFileUploaded, onUploadComplete, onAllUploadsComplete } = this.props;
    const { uploadManager, errors, filesComplete } = this.state;
    const errorCount = duplicates.length + invalid.length;
    const processedCount = completed.length + drafts.length + errorCount;

    const data = this.omitProcessedUuids(completed).reduce<SavedFile[]>(
      (acc, { is_archive: isArchive, saved_entity: file, archive_entries: archiveEntries }) => {
        if (!isArchive) {
          acc.push(file);
          return acc;
        }
        return acc.concat(archiveEntries.map(({ saved_entity: archiveFile }) => archiveFile));
      },
      new Array<SavedFile>(),
    );
    const newTransformedSavedFiles = data.map(FileUploader.transformSavedFile);

    if (newTransformedSavedFiles.length) {
      if (onFileUploaded) {
        onFileUploaded(newTransformedSavedFiles, errors);
      }

      if (newTransformedSavedFiles.length && onUploadComplete) {
        onUploadComplete(newTransformedSavedFiles, errors);
      }

      this.appendProcessedUuids(completed);
    }

    const newTransformedDrafts = this.omitProcessedUuids(drafts).map(FileUploader.transformUpload);

    if (newTransformedDrafts.length) {
      if (onFileUploaded) {
        onFileUploaded(newTransformedDrafts, errors);
      }

      if (newTransformedDrafts.length && onUploadComplete) {
        onUploadComplete(newTransformedDrafts, errors);
      }

      this.appendProcessedUuids(drafts);
    }

    flushSync(() =>
      this.setState((prev) => ({
        ...prev,
        filesComplete: filesComplete.concat(newTransformedSavedFiles, newTransformedDrafts),
        archivesPending: pendingArchives,
        archivesComplete: unzippedArchives,
        errors: uploadManager.getErrors().map(FileUploader.transformError),
      })),
    );

    if (pending.length === 0 && processedCount) {
      uploadManager.setBytesFinished();
      // TODO: get rid of this in favour of onUploadComplete
      if (onAllUploadsComplete) {
        onAllUploadsComplete(this.getCompletedFiles(), errors);
      }
      this.setState({ filesComplete: [], filesAdded: [] });
    }
  };

  onBeforeFileAdded(currentFile: UppyFile, files: IndexedUppyFileCollection) {
    const { allowDuplicates } = this.props;
    const { data: currentFileData, name: currentFileName } = currentFile;
    const { size: currentFileSize, type: currentFileType } = currentFileData;

    // Uppy uses IDs internally. They must be unique. They are generated from file names, sizes and timestamps.
    // If we are allowing duplicates, set the modified date to the next highest timestamp
    const maxModified = Object.values(files).reduce<number | null>(
      (ts, { data: { name, size, lastModified } }: UppyFile & { data: File }) => {
        if (name === currentFileName && size === currentFileSize) {
          return ts ? Math.max(ts, lastModified) : lastModified;
        }
        return ts;
      },
      null,
    );

    if (allowDuplicates && maxModified) {
      return {
        ...currentFile,
        data: new File([currentFileData], currentFileName, {
          type: currentFileType,
          lastModified: maxModified + 1,
        }),
      };
    }

    return currentFile;
  }

  onBeforeUpload(files: IndexedUppyFileCollection): boolean {
    const maxFileErrors = this.validateMaxFiles(files);
    const duplicateFileErrors = this.validateDuplicateFiles(files);

    if (!maxFileErrors.length && !duplicateFileErrors.length) {
      return true;
    }

    this.uppy.cancelAll();
    this.resetState();

    this.setState(({ errors }) => ({
      errors: errors.concat(maxFileErrors, duplicateFileErrors),
      restrictionViolation: true,
    }));

    return false;
  }

  getCompletedFiles() {
    const { filesComplete } = this.state;

    return filesComplete;
  }

  omitProcessedUuids<T extends { uuid: string }>(uploads: T[]): T[] {
    return uploads.filter(({ uuid }) => !this.processedUuids.has(uuid));
  }

  appendProcessedUuids<T extends { uuid: string }>(uploads: T[]): void {
    uploads.forEach(({ uuid }) => this.processedUuids.add(uuid));
  }

  validateMaxFiles(files: IndexedUppyFileCollection): FileError[] {
    const { uppyOptions } = this.props;
    const maxFiles = uppyOptions.restrictions?.maxNumberOfFiles;

    if (!maxFiles || Object.keys(files).length <= maxFiles) {
      return [];
    }

    return [
      {
        file: null,
        message: `Please upload no more than ${maxFiles} file${maxFiles === 1 ? '' : 's'}`,
      },
    ];
  }

  validateDuplicateFiles(files: IndexedUppyFileCollection) {
    const { existingFiles } = this.props;

    return existingFiles
      .filter(
        (existingFile) =>
          Object.values(files).filter(
            (file) => file.size === existingFile.size && file.name === existingFile.name,
          ).length,
      )
      .map((file) => ({
        file,
        message: 'This file has already been uploaded',
      }));
  }

  resetState(): void {
    this.setState((prev) => ({
      ...prev,
      errors: [],
      errorsCollapsed: false,
      filesAdded: [],
      filesComplete: [],
      archivesPending: [],
      archivesComplete: [],
      progress: 0,
      restrictionViolation: false,
    }));
  }

  calculateTotalProgress(): number {
    const { progress, filesAdded, filesComplete } = this.state;
    const percentVerified =
      filesAdded.length === 0 ? 0 : (filesComplete.length / filesAdded.length) * 100;

    return Math.floor(progress * 0.9 + percentVerified * 0.1);
  }

  bindEvents() {
    this.uppy.on('file-added', async (file: UppyFile) => {
      const { restrictionViolation, filesAdded } = this.state;
      if (restrictionViolation && filesAdded.length === 0) {
        // user has been naughty, but we've given them a clean slate and they have
        // started uploading some (hopefully valid) files this time... clear errors etc:
        this.resetState();
      }

      const { id, size, name } = file;
      const { autoUnzip, uploadManager } = this.state;

      const uuid = generateUuidv4();
      const uuidWithUserId = [uuid, getUserId()].join('-');

      const uploadSessionId = uploadManager
        ? await uploadManager.commit({
            name,
            size,
            autoUnzip,
            uuid: uuidWithUserId,
            directory: undefined,
          })
        : null;

      this.uppy.setFileMeta(id, {
        autoUnzip,
        uploadSessionId,
        pathPrefix: '',
        qquuid: uuidWithUserId,
        uid: getUserId(),
        qqfilename: name,
        qqtotalfilesize: size,
      });

      this.setState(({ filesAdded: prevAdded }) => ({ filesAdded: prevAdded.concat(file) }));

      return this.uppy.upload();
    });

    this.uppy.on('restriction-failed', (file, error) => {
      this.setState(({ errors }) => ({
        errors: errors.concat({ file: file || null, message: error.message }),
        restrictionViolation: true,
      }));
    });

    this.uppy.on('progress', (progress: number) => {
      this.setState({ progress });
    });

    this.uppy.on('upload-error', (file: UppyFile, error: { message: string }) => {
      this.setState(({ errors }) => ({
        errors: errors.concat({ file, message: error.message }),
      }));
    });

    this.uppy.on('complete', async (result) => {
      const { onUploadComplete } = this.props;
      const { uploadManager, filesAdded, filesComplete } = this.state;

      if (uploadManager) {
        // Don't call onFileUploaded when using S3 - hashes are returned async
        // Start polling for completed uploads instead
        await uploadManager.startPolling();
        // Don't call this either - call it in onPollingForAsyncComplete instead
        // onUploadComplete(filesComplete);
        return;
      }

      if (result.successful.length + result.failed.length === filesAdded.length) {
        // if batch is finished, reset files state
        this.setState({ filesComplete: [], filesAdded: [] });
      }

      if (onUploadComplete) {
        onUploadComplete(filesComplete);
      }
    });
  }

  render() {
    const {
      progress,
      filesAdded,
      filesComplete,
      archivesPending,
      archivesComplete,
      errors,
      errorsCollapsed: hideErrors,
      autoUnzip,
    } = this.state;

    const hasErrors = errors.length !== 0;
    const toggleErrors = (e: MouseEvent) => {
      e.preventDefault();
      this.setState((prev) => ({ errorsCollapsed: !prev.errorsCollapsed }));
    };
    const { beforeContent, allowAutoUnzipChoice, browseMessage } = this.props;

    const totalProgress = this.calculateTotalProgress();

    const numFilesVerified = filesComplete.length;
    const numFilesAdded = filesAdded.length;
    const message =
      Math.ceil(progress) === 100
        ? `Verifying file ${Math.max(1, numFilesVerified)} of ${numFilesAdded}`
        : `Uploading ${numFilesAdded} file${numFilesAdded > 1 ? 's' : ''}`;

    return (
      <div className="uppy-container">
        <div className={beforeContent ? 'with-extra-content' : undefined}>
          {beforeContent ? (
            <CustomDragDropArea
              activeBackgroundColour="#e9fbf6"
              onFile={(file) => this.uppy.addFile(fileInputToUppy(file))}
            >
              {beforeContent}
            </CustomDragDropArea>
          ) : null}
          <DragDrop width="100%" height="100%" uppy={this.uppy} note={browseMessage} />
          {allowAutoUnzipChoice && (
            <div className={styles.autoUnzipContainer}>
              <Checkbox
                id="auto-unzip"
                label="Automatically un-zip any ZIP files I upload"
                statusCurrent={autoUnzip ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
                onChange={(e) =>
                  this.setState((prev) => ({ ...prev, autoUnzip: e.target.checked }))
                }
              />
            </div>
          )}
        </div>
        {filesAdded.length !== 0 && (
          <UploadProgressBar percentComplete={totalProgress}>
            <div className={styles.message}>{message}</div>
            <div>{totalProgress}% complete</div>
            {hasErrors && (
              <div className={styles.errorToggle}>
                <Button
                  onClick={toggleErrors}
                  size={ButtonSize.Small}
                  variant={ButtonVariant.Transparent}
                >
                  {hideErrors ? 'See' : 'Hide'} errors
                  <Icon
                    name={hideErrors ? IconName.ChevronDown : IconName.ChevronUp}
                    size="0.6em"
                    marginLeft="small"
                  />
                </Button>
              </div>
            )}
          </UploadProgressBar>
        )}

        <ArchiveUploadProgressBar
          pendingArchives={archivesPending}
          completedArchives={archivesComplete}
        />

        {hasErrors && (
          <ErrorList errors={errors} isCollapsed={hideErrors} toggleCollapsed={toggleErrors} />
        )}
      </div>
    );
  }
}
