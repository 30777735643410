import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Country, CurrencyInfo, defaultCurrencyInfo, getCurrencyInfo } from '@ascension/enums';

type CurrencyInfoContextProps = {
  children?: ReactNode;
  currencyInfo?: CurrencyInfo;
};

const createFormatter = (currency: string) =>
  new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

const formatCurrencyAsStringFactory =
  (formatter: Intl.NumberFormat) =>
  (value: number): string => {
    const parts = formatter.formatToParts(value);
    return parts
      .filter((part) => part.type !== 'currency')
      .filter((part) => part.type !== 'literal')
      .map((part) => part.value)
      .join('');
  };

const formatCurrencyAsNumberFactory = (formatter: Intl.NumberFormat) => (value: string) => {
  const parts = formatter.formatToParts(50000);
  const currency = parts.find((part) => part.type === 'currency')?.value;
  const group = parts.find((part) => part.type === 'group')?.value;
  return Number(
    value
      .replace(group === '.' ? /\./g : /,/g, '')
      .replace(/,/g, '.')
      .replace(`${currency} `, ''),
  );
};

const defaultFormatter = createFormatter('AUD');
type ContextType = [
  CurrencyInfoContextProps,
  Dispatch<SetStateAction<CurrencyInfoContextProps>>,
  (value: string) => number,
  (value: number) => string,
];
export const CurrencyInfoContext = createContext<ContextType>([
  {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  formatCurrencyAsNumberFactory(defaultFormatter),
  formatCurrencyAsStringFactory(defaultFormatter),
]);

const { Provider } = CurrencyInfoContext;

export const useSetCurrency = (country?: Country) => {
  const [, setCurrencyContextInfo] = useContext(CurrencyInfoContext);
  useEffect(() => {
    if (country) {
      const currencyInfo = getCurrencyInfo(country);
      setCurrencyContextInfo({ currencyInfo });
    }
  }, [country, setCurrencyContextInfo]);
};

export const useCurrencyFormatter = () => {
  const [, , formatAsNumber, formatAsString] = useContext(CurrencyInfoContext);
  return {
    toString: formatAsString,
    toNumber: formatAsNumber,
  };
};

export const useCurrencySymbol = () => {
  const [context] = useContext(CurrencyInfoContext);
  const { currencyInfo } = context;

  return currencyInfo ? currencyInfo.currencySymbol : '';
};

export const CurrencyInfoContextProvider = ({
  children,
  currencyInfo,
}: CurrencyInfoContextProps) => {
  const context = {
    currencyInfo: currencyInfo ?? defaultCurrencyInfo,
  };

  type CurrencyInfoState = Required<Omit<CurrencyInfoContextProps, 'children'>>;

  const [currencyInfoContext, setCurrencyInfoContext] = useState<CurrencyInfoState>(context);

  const formatter = useMemo(
    () => createFormatter(currencyInfoContext.currencyInfo.currency),
    [currencyInfoContext],
  );

  const formatAsString = useMemo(() => formatCurrencyAsStringFactory(formatter), [formatter]);
  const formatAsNumber = useMemo(() => formatCurrencyAsNumberFactory(formatter), [formatter]);

  return (
    <Provider value={[currencyInfoContext, setCurrencyInfoContext, formatAsNumber, formatAsString]}>
      {children}
    </Provider>
  );
};
