import {
  OperatingCountry,
  ValidCountryId,
} from '@ascension/components/localisation/operatingCountry';

type LocalisedArticles = {
  [key in ValidCountryId]: {
    fallback?: ValidCountryId;
    articles: { [key: string]: string };
  };
};

const AU = {
  articles: {
    connectionSuggestionsHelp:
      'https://builder.support.estimateone.com/en/articles/1705407095-how-to-use-the-network#connection-suggestions',
    matchingNetworkCompanyHelp:
      'https://builder.support.estimateone.com/en/articles/1708035476-connecting-a-company-to-the-e1-network-faq',
  },
};

const UK = {
  fallback: OperatingCountry.AU,
  articles: {
    connectionSuggestionsHelp:
      'https://international.support.estimateone.com/en/articles/1716235077-how-to-use-the-network#connection-suggestions',
    matchingNetworkCompanyHelp:
      'https://international.support.estimateone.com/en/articles/1715841965-the-network-faq#how-can-i-match-subcontractors-from-my-address-book-with-the-e1-network',
  },
};

const IE = {
  fallback: OperatingCountry.UK,
  articles: {},
};

const NZ = {
  fallback: OperatingCountry.AU,
  articles: {},
};

export const localisedArticles: LocalisedArticles = {
  [OperatingCountry.AU]: AU,
  [OperatingCountry.UK]: UK,
  [OperatingCountry.IE]: IE,
  [OperatingCountry.NZ]: NZ,
};

export type KnowledgeBaseArticles =
  | keyof (typeof AU)['articles']
  | keyof (typeof UK)['articles']
  | keyof (typeof IE)['articles']
  | keyof (typeof NZ)['articles'];
