import $ from 'jquery';
import { initAddressForm } from '../utils/address_form';
import initialiseSortableTable from '../utils/sortable_table';
import AnalyticsService from './AnalyticsService';
import LocationAutocompleteWidget from './LocationAutocompleteWidget';

const jQuery = $;

type ModalOptions = {
  modal: boolean;
  closeOnContentClick: boolean;
  showCloseBtn: boolean;
  closeOnBgClick: boolean;
  enableEscapeKey: boolean;
  callbacks?: Record<string, unknown>;
};

export default class Modal {
  public $modal: JQuery;
  // Set when this.show() is called
  public modal: MagnificPopupInstance | null = null;
  private readonly convertedOptions: Record<string, unknown> = {};
  private readonly analytics: AnalyticsService;

  constructor(
    public contents: string,
    public options?: ModalOptions,
    public onCloseModal?: () => void,
  ) {
    this.contents = contents;
    this.options = options;
    this.analytics = new AnalyticsService();
    this.onCloseModal = onCloseModal;

    this.$modal = $('<div>');
    this.init();
  }

  static closeAll() {
    $.magnificPopup.instance.close();
  }

  static buildModalTemplate(title: string, $content: JQuery, $footer: JQuery, classes?: string) {
    const $modalContent = $content instanceof jQuery ? $content[0].outerHTML : $content;
    const $modalFooter = $footer instanceof jQuery ? $footer[0].outerHTML : $footer;

    const modal = `
      <div class="modal-dialog e1-modal ${classes}">
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="btn btn-close e1-modal-close">
                      Close <i class="icon icon-sm icon-close"></i>
                      <span class="sr-only">Close</span>
                  </button>
                  <h3 class="modal-title">${title}</h3>
              </div>
              <div class="modal-body">
                  ${$modalContent}
              </div>
              <div class="modal-footer">
                  ${$modalFooter}
              </div>
          </div>
      </div>`;

    return $(modal);
  }

  init() {
    this.$modal = $('<div>');
    this.$modal.html(this.contents);
    this.convertedOptions.modal = this.hasModalBehaviour();

    if (this.$modal.find('.e1-modal').length > 0) {
      this.setModalHeight();
    }

    this.convertedOptions.showCloseBtn = false;
    this.convertedOptions.closeOnBgClick =
      typeof window.CLOSE_MODAL_ON_BG_CLICK === 'boolean'
        ? this.getOption('closeOnBgClick', window.CLOSE_MODAL_ON_BG_CLICK)
        : this.getOption('closeOnBgClick', true);

    this.convertedOptions.items = [
      {
        src: this.$modal,
      },
    ];
    if ($(this.contents).data('required')) {
      this.convertedOptions.closeOnBgClick = false;
      this.convertedOptions.enableEscapeKey = false;
    }
    if (this.onCloseModal) {
      this.convertedOptions.closeOnBgClick = false;
      this.convertedOptions.enableEscapeKey = false;
    }

    this.convertedOptions.callbacks = {
      open: this.getCallbackOption('open', () => {
        const modalForm = this.$modal.find('form').first();
        if (modalForm != null) {
          const $addressForm = modalForm.find('.auto_address_form');
          $addressForm
            .toArray()
            .forEach((form) => initAddressForm($(form) as unknown as JQuery<HTMLFormElement>));

          const $autocompleteAddressForm = modalForm.find('.autocomplete_address_form');
          $autocompleteAddressForm.toArray().forEach((form) => {
            new LocationAutocompleteWidget($(form));
          });

          $('table.sortable-table', this.$modal).each((i, table) => {
            initialiseSortableTable(table);
          });

          const input = $(modalForm).find(
            ':input:not(input[type=button],input[type=submit],button):visible:enabled:first',
          );

          if (!input.hasClass('datetime')) {
            setTimeout(() => {
              input.trigger('focus');
            }, 100);
          }

          $(modalForm).find('input[type="checkbox"][data-toggle="toggle"]').bootstrapToggle();

          $('body').trigger('modal-loaded');
        }
      }),
      close: this.getCallbackOption('close', () => {
        const $dialogOuter = this.$modal.find('.modal-dialog');
        if ($dialogOuter.hasClass('analytics-on-close')) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          this.analytics.addEvent('interact', {
            action: $dialogOuter.data('analytics-action'),
            trigger: $dialogOuter.data('analytics-trigger'),
          });
        }

        if (this.onCloseModal) {
          this.onCloseModal();
        }

        if (this.$modal.find('.e1-modal-close-page-reload').length > 0) {
          location.reload();
        }
      }),
    };

    this.$modal.on('click', '.e1-modal-close', () => {
      if (this.modal !== null && typeof this.modal.close !== 'undefined') {
        this.close();
      }
    });

    this.$modal.on('click', '.e1-modal-close-page-reload', () => {
      if (this.modal !== null && typeof this.modal.close !== 'undefined') {
        this.closeAndReload();
      }
    });
  }

  hasModalBehaviour() {
    if (this.$modal.find('.modal-dialog').hasClass('modal-behaviour')) {
      return true;
    }
    return this.getOption('modal', false);
  }

  getOption(optionKey: keyof ModalOptions, defaultVal: unknown) {
    // Convert options for use with the modal library
    if (typeof this.options === 'object') {
      if (typeof this.options[optionKey] !== 'undefined') {
        return this.options[optionKey];
      }
    }
    return defaultVal;
  }

  getCallbackOption(optionKey: string, defaultVal: unknown) {
    if (typeof this.options === 'object' && typeof this.options.callbacks === 'object') {
      if (typeof this.options.callbacks[optionKey] === 'function') {
        return this.options.callbacks[optionKey];
      }
    }

    return defaultVal;
  }

  setModalHeight() {
    const viewportHeight = $(window).height() as number;

    const minMaxHeight = 600;

    // Setting these values statically is stupid, but here we go.
    const modalMargin = 30 * 2; // There's a margin at the top and bottom (2)
    const headerHeight = 56;
    const footerHeight = 64;

    const heightAdjust = modalMargin + headerHeight + footerHeight;
    const maxModalBodyHeight = Math.max(viewportHeight - heightAdjust, minMaxHeight);
    this.$modal.find('.modal-body').css('max-height', maxModalBodyHeight);
  }

  show() {
    $.magnificPopup?.open(this.convertedOptions, 0);
    this.$modal.find('select.select2:enabled:not([readonly])').select2({
      theme: 'bootstrap',
      minimumResultsForSearch: 10,
    });

    this.$modal.find('select.select2.force-searchable:enabled').select2({
      minimumResultsForSearch: 0,
    });

    this.modal = $.magnificPopup.instance;

    return this;
  }

  close() {
    if (this.modal) {
      this.modal.close();
    }
  }

  closeAndReload() {
    this.close();
    location.reload();
  }
}
