import { Heading } from '../../../../fec';
import styles from './styles.scss';

enum Type {
  Project = 'Project',
  Stage = 'Stage',
}

type ProjectOrStageDetailsProps = {
  type: Type;
  name: string;
  addressString: string;
};

const ProjectOrStageDetails = ({ type, name, addressString }: ProjectOrStageDetailsProps) => (
  <div>
    <Heading level={3} isHidden>
      {type}
    </Heading>

    <p>
      <span className={styles.name}>{name}</span>
      <br />
      <span className={styles.details}>{addressString}</span>
    </p>
  </div>
);

export const ProjectDetails = ({
  name,
  addressString,
}: {
  name: string;
  addressString: string;
}) => <ProjectOrStageDetails type={Type.Project} name={name} addressString={addressString} />;

export const StageDetails = ({ name, addressString }: { name: string; addressString: string }) => (
  <ProjectOrStageDetails type={Type.Stage} name={name} addressString={addressString} />
);
