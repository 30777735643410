import { useState, useEffect } from 'react';
import { Nav } from '@estimateone/frontend-components';

type QuoteNavProps = {
  detailsAnchor: string;
  notesAnchor: string;
  activityCount: number;
};

const QuoteNav = ({ detailsAnchor, notesAnchor, activityCount }: QuoteNavProps) => {
  const [currentView, setCurrentView] = useState(detailsAnchor);

  const handleNavUpdate = (anchor: string) => {
    setCurrentView(anchor);
    // eslint-disable-next-line fp/no-mutation
    window.location.hash = anchor === detailsAnchor ? '' : anchor;
  };

  const handleScroll = () => {
    if (!window.pageYOffset) {
      handleNavUpdate(detailsAnchor);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <Nav>
      <Nav.Item
        label="Quote summary"
        onClick={() => handleNavUpdate(detailsAnchor)}
        isActive={currentView === detailsAnchor}
      />
      <Nav.Item
        label="Notes & history"
        badgeValue={activityCount}
        onClick={() => handleNavUpdate(notesAnchor)}
        isActive={currentView === notesAnchor}
      />
    </Nav>
  );
};

export default QuoteNav;
