import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import {
  KnowledgeBaseArticles,
  localisedArticles,
} from '@ascension/components/localisation/localisedKnowledgeBaseArticles';
import {
  OperatingCountry,
  ValidCountryId,
} from '@ascension/components/localisation/operatingCountry';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { TranslationTerms } from '@profiles/providers/TranslationsProvider/translations';

/**
 * This looks at all translation terms, then any with suffixes matching our OperatingCountry's (as above)
 * are valid strings which we accept into our useCountryTermTranslator callback.
 *
 * eg. Assume we have the following translation terms in our TranslationProvider:
 *
 * const translationTerms['businessIdentifier_UK', 'someRandomTranslationTerm', 'something_US'];
 *
 * const termTranslator = useCountryTermTranslator();
 * termTranslator('businessIdentifier'); // Valid, because we have _UK version of businessIdentifier
 * termTranslator('businessIdentifier_UK'); // Invalid, because businessIdentifier_UK_UK doesn't exist
 * termTranslator('someRandomTranslationTerm'); // Invalid, because there's no _UK version of someRandomTranslationTerm
 * termTranslator('something'); // Invalid, because US is not a valid OperatingCountry
 */
type RemoveSuffix<T extends string, Suffix extends string> = T extends `${infer Prefix}_${Suffix}`
  ? Prefix
  : never;
type TranslationSuffix = keyof typeof OperatingCountry;
type OperatingCountryTerm = RemoveSuffix<TranslationTerms, TranslationSuffix>;

type OperatingCountryConfiguration = {
  translationSuffix: TranslationSuffix;
};

const countriesMap = new Map<ValidCountryId, OperatingCountryConfiguration>();
countriesMap.set(OperatingCountry.AU, { translationSuffix: 'AU' });
countriesMap.set(OperatingCountry.NZ, { translationSuffix: 'NZ' });
countriesMap.set(OperatingCountry.UK, { translationSuffix: 'UK' });
countriesMap.set(OperatingCountry.IE, { translationSuffix: 'IE' });

const isValidCountryId = (id: number): id is ValidCountryId =>
  countriesMap.has(id as ValidCountryId);

const resolveOperatingCountry = (countryId: ValidCountryId) => {
  const operatingCountry = countriesMap.get(countryId);

  if (!operatingCountry) {
    throw new Error(`Unable to find operating country: ${countryId}`);
  }

  return operatingCountry;
};

type OperatingCountryContextValue = { countryId: ValidCountryId };

const OperatingCountryContext = createContext<OperatingCountryContextValue>({
  countryId: OperatingCountry.AU,
});

type OperatingCountryProviderProps = {
  countryId: number;
  children: ReactNode;
};

export const OperatingCountryProvider = ({
  countryId,
  children,
}: OperatingCountryProviderProps) => {
  const value: OperatingCountryContextValue = useMemo(() => {
    if (isValidCountryId(countryId)) {
      return { countryId };
    }

    return { countryId: OperatingCountry.AU };
  }, [countryId]);

  return (
    <OperatingCountryContext.Provider value={value}>{children}</OperatingCountryContext.Provider>
  );
};

export const useOperatingCountryId = (): ValidCountryId =>
  useContext(OperatingCountryContext).countryId;

export const useCountryTermTranslator = () => {
  const countryId = useOperatingCountryId();
  const translate = useTranslator();
  const { translationSuffix } = resolveOperatingCountry(countryId);

  return useCallback(
    (term: OperatingCountryTerm) => translate(`${term}_${translationSuffix}`),
    [translate, translationSuffix],
  );
};

export const useKnowledgeBaseArticle = () => {
  const initialCountryId = useOperatingCountryId();

  return useCallback(
    (term: KnowledgeBaseArticles) => {
      const resolve = (countryId: ValidCountryId): string => {
        const article = localisedArticles[countryId];
        return article.articles[term] ?? (article.fallback ? resolve(article.fallback) : '');
      };

      return resolve(initialCountryId);
    },
    [initialCountryId],
  );
};
