import { ReactNode } from 'react';
import { Icon, IconName } from '@estimateone/frontend-components';
import styles from './styles.scss';

const normalisePercentage = (percentComplete: number) => {
  if (percentComplete > 100) {
    return 100;
  }

  if (percentComplete < 0) {
    return 0;
  }

  return Math.floor(percentComplete);
};

export enum ProgressBarColour {
  GREEN = '#00c389',
  BLUE = '#c6cfff',
}

const ProgressBar = ({
  percentComplete,
  colour = ProgressBarColour.GREEN,
  icon = IconName.Upload,
  children,
}: {
  percentComplete: number;
  colour?: ProgressBarColour;
  icon?: IconName;
  children?: ReactNode;
}) => {
  const percent = normalisePercentage(percentComplete);
  const complete = percent === 100;

  return (
    <div className={styles.container}>
      <div className={styles.bar}>
        <div
          title="progressPercentFill"
          className={styles.fill}
          style={{ width: `${percent}%`, background: colour }}
        />
        <div className={styles.content}>
          <div className={styles.icon}>
            <Icon name={complete ? IconName.Check : icon} />
          </div>
          {children}
        </div>
      </div>
    </div>
  );
};

const UploadProgressBar = ({
  percentComplete,
  children,
}: {
  percentComplete: number;
  colour?: ProgressBarColour;
  icon?: IconName;
  children?: ReactNode;
}) =>
  ProgressBar({
    percentComplete,
    children,
    icon: IconName.Upload,
    colour: ProgressBarColour.GREEN,
  });

const ArchiveProgressBar = ({
  percentComplete,
  children,
}: {
  percentComplete: number;
  colour?: ProgressBarColour;
  icon?: IconName;
  children?: ReactNode;
}) =>
  ProgressBar({
    percentComplete,
    children,
    icon: IconName.Archive,
    colour: ProgressBarColour.BLUE,
  });

export { UploadProgressBar, ArchiveProgressBar };
