import { gql } from '@apollo/client';

export const GET_PROJECT_WITH_QUOTABLE_STAGES = gql`
  query GetProjectAndQuotableStages($id: EntityId!) {
    project(id: $id) {
      ... on ProjectInterface {
        id
        name

        address {
          id
          address1
          shortAddress
        }

        quotableStages {
          id
          private
          builderDetails {
            ... on BuilderDetails {
              id
              name
              contactName
              contactPhone
              contactEmail
            }
            ... on HiddenBuilderDetails {
              isIncognito
            }
          }
          country {
            id
          }
          activePackages {
            id
            title
            mostRecentQuoteForCurrentAccount {
              id
            }
          }
        }
      }
    }
  }
`;
