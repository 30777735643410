import QuoteList from '../../../features/QuotesFeature/List';
import AccountProvider from '@subbie/context/AccountProvider';

const QuoteListPage = () => (
  <AccountProvider>
    <QuoteList />
  </AccountProvider>
);

export default QuoteListPage;
