import Moment from 'moment-timezone';
import styles from './styles.scss';

type ProjectDateProps = {
  timestamp: string;
  format?: string;
  longFormat?: string;
  useRelativeDayNames?: boolean;
  hasDateDisclaimer?: boolean;
};

const getRelativeDateValueAndClass = (
  date: Moment.Moment,
  diff: number,
): { value?: string; className?: string } => {
  switch (diff) {
    case -1:
      return { value: 'Yesterday', className: styles.yesterday };
    case 0:
      return { value: 'Today', className: styles.today };
    case 1:
      return { value: 'Tomorrow', className: styles.tomorrow };
    default:
      return { value: Math.abs(diff) < 7 ? date.fromNow() : undefined };
  }
};

const isCurrentYear = (date: Moment.Moment) => date.format('YY') === Moment().format('YY');

export const DisclaimerSymbol = () => (
  <abbr
    className={styles.disclaimerSymbol}
    aria-label="Denotes that some packages have different due dates"
  >
    *
  </abbr>
);

const ProjectDate = ({
  timestamp,
  format = 'D MMM',
  longFormat = 'D MMM YY',
  useRelativeDayNames = false,
  hasDateDisclaimer = false,
}: ProjectDateProps) => {
  const date = Moment(timestamp);
  const daysDiff = date.diff(Moment(), 'days');

  if (useRelativeDayNames) {
    const { value, className } = getRelativeDateValueAndClass(date, daysDiff);
    if (value) return <span className={className}>{value}</span>;
  }

  const FormattedDate = () => {
    const formattedDate = isCurrentYear(date) ? date.format(format) : date.format(longFormat);
    return hasDateDisclaimer ? (
      <>
        {formattedDate} <DisclaimerSymbol />
      </>
    ) : (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>{formattedDate}</>
    );
  };

  return (
    <span className={styles.projectDate}>
      <FormattedDate />
    </span>
  );
};

export default ProjectDate;
