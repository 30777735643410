import { MouseEventHandler } from 'react';
import { Alert, AlertVariant, CollapsibleSection } from '@estimateone/frontend-components';
import { FileError } from './index';
import styles from './styles.scss';

const ErrorList = ({
  errors,
  isCollapsed = false,
  toggleCollapsed = () => undefined,
}: {
  errors: FileError[];
  isCollapsed?: boolean;
  toggleCollapsed?: MouseEventHandler<HTMLButtonElement>;
}) => (
  <CollapsibleSection isCollapsed={isCollapsed}>
    <div className={styles.errorsContainer}>
      <Alert onDismiss={toggleCollapsed} variant={AlertVariant.Red}>
        <ul className={styles.errors}>
          {errors.map((error) =>
            error.file ? (
              <li key={error.file.name}>
                Failed to upload <strong>{error.file.name}:</strong> {error.message}
              </li>
            ) : (
              <li key={error.message}>
                <strong>File upload failed.</strong> {error.message}
              </li>
            ),
          )}
        </ul>
      </Alert>
    </div>
  </CollapsibleSection>
);

export default ErrorList;
