import { useMemo } from 'react';
import { joinClassNames } from '@estimateone/frontend-components';
import ProjectDate from '../ProjectDate';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { NoticeboardProjects_projectSearch_highlightedProjects_project as Project } from '@subbie/features/NoticeboardFeature/types/NoticeboardProjects';
import styles from './styles.scss';

type Stage = Extract<Project, { __typename: 'ViewableNoticeboardProject' }>['stages'][0];

export type BuildersQuotesProps = {
  stages: Stage[];
  timestampKey: 'awardedAt' | 'tenderQuotesDue' | 'tenderQuotesDueForCreatorTimezone';
};

const isIncognitoBuilder = (
  builderDetails: Stage['builderDetails'],
): builderDetails is Extract<Stage['builderDetails'], { __typename: 'HiddenBuilderDetails' }> =>
  builderDetails.__typename === 'HiddenBuilderDetails';

const getBuilderAbbrev = (builderDetails: Stage['builderDetails']) => {
  if (isIncognitoBuilder(builderDetails)) {
    return getTranslation(LangRef.INCOGNITO_BUILDER_HEADING);
  }
  return builderDetails ? builderDetails.abbrev : '';
};

export const BuildersQuotes = ({ stages, timestampKey }: BuildersQuotesProps) => {
  const sortedByQuotesDueStages = useMemo(
    () =>
      [...stages]
        .sort(
          ({ tenderQuotesDue: tqa }, { tenderQuotesDue: tqb }) =>
            new Date(tqa ?? 0).getTime() - new Date(tqb ?? 0).getTime(),
        )
        .map((stage) => ({ ...stage, ...{ timestamp: stage[timestampKey] } })),
    [stages, timestampKey],
  );

  return (
    <ul className={styles.buildersQuoteList}>
      {sortedByQuotesDueStages.map(
        ({ id, builderDetails, timestamp, hasMultipleQuoteDueDates }) => (
          <li key={id}>
            <span className={joinClassNames(styles.builderName, styles.builderNameNoWrap)}>
              {getBuilderAbbrev(builderDetails)}
            </span>
            <span className={styles.quoteDate}>
              {timestamp && (
                <ProjectDate
                  timestamp={timestamp}
                  useRelativeDayNames
                  hasDateDisclaimer={hasMultipleQuoteDueDates}
                />
              )}
            </span>
          </li>
        ),
      )}
    </ul>
  );
};

type RedactedBuildersQuotesProps = {
  activeTenderStageCount: number;
  singleBuilder: boolean;
  from: string;
  to: string;
  omitFirstMonthIfSame?: boolean;
};

export const RedactedBuildersQuotes = ({
  activeTenderStageCount,
  singleBuilder,
  from,
  to,
  omitFirstMonthIfSame = false,
}: RedactedBuildersQuotesProps) => (
  <ul className={styles.buildersQuoteList}>
    <li>
      <span className={styles.builderName}>
        {activeTenderStageCount}{' '}
        {singleBuilder ? getTranslation(LangRef.BUILDER) : getTranslation(LangRef.BUILDERS)}{' '}
        tendering
      </span>
      <span className={styles.quoteDate}>
        {from === to ? (
          <ProjectDate timestamp={from} />
        ) : (
          <>
            <ProjectDate
              timestamp={from}
              format={
                omitFirstMonthIfSame && new Date(from).getMonth() === new Date(to).getMonth()
                  ? 'D'
                  : 'D MMM'
              }
            />
            <span> - </span>
            <ProjectDate timestamp={to} />
          </>
        )}
      </span>
    </li>
  </ul>
);
