import { useField, useForm } from 'react-final-form';
import { Checkbox, CheckboxStatus, joinClassNames } from '@estimateone/frontend-components';
import { fieldNameGenerator } from './utils';
import { FieldWrapper } from '@shared/FinalForm/Field';
import { FieldError } from '@shared/FinalForm/types';
import { AddQuoteFormSpecificFieldName, StagePackagesSubField } from '../enums';
import {
  QuoteSubbieAddFormValues,
  StagePackageIsSelectedValue,
  StagePackageIsWithdrawnValue,
  StagePackageStageValue,
} from '../types';
import { StagePackagesSubFieldValues } from './types';
import { EntityId } from '@ascension/types';
import styles from './styles.scss';

type StageSelectorFieldProps = {
  parentFieldName: string;
  stageId: EntityId;
  accountName: string;
  phone: string | null;
  hasPackageErrors?: boolean;
  isPreselected?: boolean;
  isDisabled?: boolean;
};

const StageSelectorField = ({
  parentFieldName,
  stageId,
  accountName,
  phone,
  hasPackageErrors = false,
  isPreselected = false,
  isDisabled = false,
}: StageSelectorFieldProps) => {
  const {
    change: updateFieldValue,
    mutators: { setFieldTouched },
  } = useForm<QuoteSubbieAddFormValues & StagePackagesSubFieldValues>();

  const getFullSubFieldName = fieldNameGenerator<StagePackagesSubField>(parentFieldName);
  const stageFieldName = getFullSubFieldName(StagePackagesSubField.Stage);
  const isSelectedFieldName = getFullSubFieldName(StagePackagesSubField.IsSelected);
  const isWithdrawnFieldName = getFullSubFieldName(StagePackagesSubField.IsWithdrawn);
  const packageFieldName = getFullSubFieldName(StagePackagesSubField.Package);

  const {
    meta: { error, submitError, touched },
  } = useField<StagePackageStageValue>(stageFieldName);

  const errors: FieldError[] = error || submitError;
  const hasErrors = Boolean(errors && errors.length !== 0);

  const {
    input: { checked: isSelected, ...isSelectedInputProps },
  } = useField<StagePackageIsSelectedValue>(isSelectedFieldName, { type: 'checkbox' });

  const {
    input: { value: isWithdrawn },
  } = useField<StagePackageIsWithdrawnValue>(isWithdrawnFieldName);

  const handleClick = () => {
    if (isSelected) {
      updateFieldValue(isSelectedFieldName, false);
      setFieldTouched(AddQuoteFormSpecificFieldName.StagePackages, true);
    } else {
      updateFieldValue(isSelectedFieldName, true);
    }
  };

  return (
    <FieldWrapper errors={errors} showErrors={touched}>
      {isWithdrawn ? (
        <div className={joinClassNames(styles.builder, styles.withdrawn)}>
          <span>{accountName}</span>
          <span className={styles.withdrawnLabel}>Withdrawn</span>
        </div>
      ) : (
        <div
          tabIndex={-1}
          role="none"
          className={joinClassNames(
            styles.builder,
            (hasErrors || hasPackageErrors) && styles.hasError,
            isPreselected && styles.preselected,
            isDisabled && styles.disabled,
          )}
          onClick={isPreselected ? undefined : handleClick}
          onBlur={() => setFieldTouched(packageFieldName, true)}
        >
          <Checkbox
            {...isSelectedInputProps}
            id={`quote-stage-package-${stageId}-stage`}
            label={accountName}
            statusCurrent={isSelected ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
            isSubcomponent
            value={stageId}
            disabled={isDisabled}
          />
          <span className={styles.phone}>{phone}</span>
        </div>
      )}
    </FieldWrapper>
  );
};

export default StageSelectorField;
