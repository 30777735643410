import { Fragment } from 'react';
import { Button, ButtonSize, Card, CardVariant, E1Link } from '@estimateone/frontend-components';
import Moment from 'moment-timezone';
import { Pricing } from '@ascension/components/fec';
import { getUserTimezone } from '@ascension/js/app/timezone';
import ProductCardHeader from './ProductCardHeader';
import { getBillingCycleString } from './util';
import { BillingCycle } from '@ascension/enums';
import styles from './styles.scss';

const formatDate = (date: string) => Moment(date).tz(getUserTimezone()).format('Do MMMM, YYYY');

type License = {
  activatedAt?: string;
  billingCycle: number;
  expiredAt?: string;
  priceExGST: number;
  product: {
    name: string;
  };
  creditCard: {
    id: number;
    maskedNumber: string;
    expiry: string;
  };
};

type LicenseDetailsActiveProps = { license: License };

const LicenseDetailsActive = ({
  license: { activatedAt, billingCycle, expiredAt, priceExGST, creditCard },
}: LicenseDetailsActiveProps) => (
  <>
    <strong>
      {billingCycle === BillingCycle.Months1
        ? 'Month by month license'
        : `${billingCycle} month license`}
    </strong>

    <div className={styles.summaryContainer}>
      <p className={styles.licenseDetails}>
        <Pricing price={priceExGST} /> (ex. GST) payable {getBillingCycleString(billingCycle)}
        <br />
        Activated on: {activatedAt ? formatDate(activatedAt) : ''}
        <br />
        Next payment: {expiredAt ? formatDate(expiredAt) : ''}
        <br />
        {creditCard ? (
          <Fragment>
            <span className={styles.creditCard}>
              Credit card: {creditCard.maskedNumber} (exp {creditCard.expiry})
            </span>
            <E1Link onClick={() => undefined}>Update card</E1Link>
          </Fragment>
        ) : (
          'Payment by cheque / eft'
        )}
      </p>
    </div>
  </>
);

type LicenseDetailsProvisionedProps = { license: License };

const LicenseDetailsProvisioned = ({ license: { product } }: LicenseDetailsProvisionedProps) => (
  <Fragment>
    <div className={styles.summaryContainer}>
      <p>
        <strong>Once payment is received, you will get immediate access to {product.name}.</strong>
      </p>
    </div>

    <div className={styles.actions}>
      <E1Link onClick={() => undefined}>View invoice</E1Link>
      <Button size={ButtonSize.Small} onClick={() => undefined}>
        Pay now
      </Button>
    </div>
  </Fragment>
);

type LicenseCardProps = { license: License; isProvisioned?: boolean };

const LicenseCard = ({ license, isProvisioned = false }: LicenseCardProps) => {
  const {
    billingCycle,
    priceExGST,
    product: { name: productName },
  } = license;
  const showLongsell = !isProvisioned && billingCycle < BillingCycle.Months12;

  return (
    <div className={styles.productCard}>
      <Card variant={isProvisioned ? CardVariant.Yellow : CardVariant.Blue}>
        {showLongsell && (
          <Card.Banner>
            <span className={styles.longsellButton}>
              <E1Link onClick={() => undefined}>
                Switch to a 12 month subscription and save <Pricing price={priceExGST * 2} /> per
                annum
              </E1Link>
            </span>
          </Card.Banner>
        )}

        <Card.Body>
          <ProductCardHeader
            billingCycle={billingCycle}
            name={productName}
            priceExGST={priceExGST}
          />
          {isProvisioned ? (
            <LicenseDetailsProvisioned license={license} />
          ) : (
            <LicenseDetailsActive license={license} />
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default LicenseCard;
