import { Country } from '@subbie/features/AccountUpgradeFeature/enum';
import { ActiveLicense, User, StockTrade, useAccountContext } from '.';
import { BillingCycle, ShortStateName } from '@ascension/enums';
import {
  AccountContextQuery_currentUser_account_realUsers as RealUser,
  AccountContextQuery_currentUser_account as Account,
} from './types/AccountContextQuery';
import { AccountType, ExperimentName, FeatureName } from '@ascension/_gqltypes/subbie.generated';
import { EntityId } from '@ascension/types';
import { AccountOffer } from '@subbie/features/AccountUpgradeFeature/types';

/**
 * Returns the project budget paywall value in dollars
 */
export const useProjectPaywallValue = (): number | undefined =>
  useAccountContext().projectBudgetPaywallValue;

/**
 * Slightly counter-intuitive:
 * Legacy products have this 'unredact fifty' feature
 * Which allows them to enforce the budget paywall instead
 */
export const hasLegacyPaywallProduct = (
  activeLicenses: Pick<ActiveLicense, 'billingCycle' | 'product'>[],
): boolean => {
  const activeFreeProductLicenses = activeLicenses.filter(
    ({ billingCycle }) => billingCycle === BillingCycle.Months0,
  );

  return activeFreeProductLicenses.some(({ product: { features } }) =>
    features.includes(FeatureName.UNREDACT_FIFTY),
  );
};

export const useFiftyPercentRedactedCheck = (): boolean => {
  const activeLicenses = useAccountContext().account?.licensingInfo.activeLicenses;

  if (activeLicenses === undefined) return false;

  return !hasLegacyPaywallProduct(activeLicenses);
};

export const useAccountIsLoading = (): boolean => useAccountContext().loading;

export const useCurrentAccount = (): Account | null => useAccountContext()?.account ?? null;

export const useAccountType = (): AccountType | undefined => useCurrentAccount()?.type;

export const useCurrentUser = (): User | null => useAccountContext().user;

export const useAccountUserList = (): RealUser[] => useAccountContext().realUsers;

export const useInExperiment = (name: ExperimentName): boolean =>
  useAccountContext().inExperiment(name);

export const useHasFeature = (name: FeatureName): boolean => useAccountContext().hasFeature(name);

export const useAccountOffer = (): AccountOffer | null => useAccountContext().accountOffer;

export const useUserPhone = (): string | null => useAccountContext().user?.phone ?? null;

export const useIsAdminUser = (): boolean => useAccountContext().user?.isAdmin ?? false;

export const useIsChargebeeAccount = (): boolean =>
  useAccountContext().account?.isChargebee ?? false;

const EMPTY_STOCK_TRADE = new Array<StockTrade>();
export const useAccountStockTrades = (): StockTrade[] =>
  useCurrentAccount()?.stockTrades ?? EMPTY_STOCK_TRADE;

export const useAccountHasStockTrades = (): boolean => useAccountStockTrades().length > 0;

export const useInState = (shortNames: ShortStateName[]): boolean => {
  const stateName = useCurrentAccount()?.primaryState?.shortName as ShortStateName;
  return stateName !== undefined && shortNames.includes(stateName);
};

export const useInCountry = (id: EntityId): boolean =>
  useCurrentAccount()?.primaryCountry?.id === id || false;

// TODO: wrong level of abstraction
export const useIsSubbieDirectoryEnabledSubbie = () =>
  useInState([
    ShortStateName.STATE_VIC,
    ShortStateName.STATE_NSW,
    ShortStateName.STATE_QLD,
    ShortStateName.STATE_WA,
  ]);

// TODO: wrong level of abstraction
export const useIsProfilesEnabledSubbie = (): boolean | undefined => {
  const accountType = useAccountType();
  const isAustralianSubbie = useInCountry(Country.AU_ID);

  if (accountType === undefined) return undefined;

  return accountType === AccountType.SUBCONTRACTOR && isAustralianSubbie;
};

export const useHasActivePaidLicenses = (): boolean =>
  useCurrentAccount()?.licensingInfo?.hasActivePaidLicenses ?? false;

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const useHasActivePaidLicensesOtherThanLiteTier = () => {
  const account = useCurrentAccount();
  const hasLiteTierLicense = useHasFeature(FeatureName.CREDITS_LITE_TIER);

  if (!account) {
    return false;
  }

  const activePaidLicenses = account.licensingInfo.activeLicenses.filter(
    (license) => license.billingCycle !== BillingCycle.Months0 && license.renewedAt === null,
  );

  const licensesCount = hasLiteTierLicense ? 1 : 0;
  return activePaidLicenses.length > licensesCount;
};

/** SUBBIE_CREDIT_EXPERIMENT_ALPHA_CODE */
export const useIsInCreditExperiment = (): boolean => {
  const account = useCurrentAccount();
  const hasActivePaidLicensesOtherThanLiteTier = useHasActivePaidLicensesOtherThanLiteTier();
  const isInNewInSubbieCreditExperiment = useInExperiment(
    ExperimentName.NEW_IN_SUBBIE_CREDIT_EXPERIMENT,
  );

  if (!account) {
    return false;
  }

  if (hasActivePaidLicensesOtherThanLiteTier) {
    return false;
  }

  return isInNewInSubbieCreditExperiment;
};

export const useNoteCounts = () => useAccountContext().noteCounts ?? new Map<EntityId, number>();

export const useSearches = () => useAccountContext().searches;

export const useIsInCreditLiteExperiment = (): boolean =>
  useInExperiment(ExperimentName.NEW_IN_SUBBIE_CREDIT_EXPERIMENT);

export const useTermsOfUseUrl = () => useAccountContext().termsOfUseURL;
export const usePrivacyPoliciesUrl = () => useAccountContext().privacyPoliciesURL ?? undefined;
export const useSupportDetails = () => {
  const { supportDetails } = useAccountContext();
  return {
    email: supportDetails?.email ?? 'support@estimateone.com',
    phone: supportDetails?.phone,
  };
};
export const useHasProductById = (id: number) => useAccountContext().hasProductById(id) ?? false;
export const useUserImperialSetting = () => useCurrentUser()?.imperialUnitsSetting;
