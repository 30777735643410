import { MouseEvent } from 'react';
import { useField } from 'react-final-form';
import { Checkbox, CheckboxProps, CheckboxStatus } from '@estimateone/frontend-components';
import { FieldWrapper } from './FieldWrapper';

export type CheckboxFieldProps = {
  fieldName: string;
  id: string;
  label: string;
} & Partial<CheckboxProps>;

export const CheckboxField = ({ fieldName, id, label, onClick, ...props }: CheckboxFieldProps) => {
  const {
    input: { checked, onChange, ...input },
    meta: { error, submitError, touched },
  } = useField(fieldName, { type: 'checkbox' });

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    onChange(!checked);
    if (onClick) onClick(e);
  };

  return (
    <FieldWrapper errors={error || submitError} showErrors={touched}>
      <Checkbox
        id={id}
        label={label}
        statusCurrent={checked ? CheckboxStatus.Checked : CheckboxStatus.Unchecked}
        onChange={onChange}
        onClick={handleClick}
        {...input}
        {...props}
      />
    </FieldWrapper>
  );
};
