import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { reportError } from '../../../helpers/errorReporter';
import { USER_ACCOUNT_AND_SUPPORT_DETAILS } from './queries';
import { WatchlistStatus } from '@ascension/enums';
import {
  AccountContextQuery,
  AccountContextQuery_currentUser as CurrentUser,
  AccountContextQuery_currentUser_account as Account,
  AccountContextQuery_currentUser_account_stockTrades as StockTrade,
  AccountContextQuery_availableAccountOffer as AccountOffer,
  AccountContextQuery_searches as ProjectSearch,
  AccountContextQuery_currentUser_account_realUsers as RealUser,
  AccountContextQuery_currentUser_account_licensingInfo_activeLicenses as ActiveLicense,
  AccountContextQuery_supportDetails as SupportDetails,
  AccountContextQuery_speciFinderKeywordsForUser as UserKeyword,
} from './types/AccountContextQuery';
import { ExperimentName, FeatureName, InterestLevel } from '@ascension/_gqltypes/subbie.generated';

type ThingExists<T> = (id: T) => boolean;

type User = CurrentUser & { speciFinderKeywords: UserKeyword[] };

export type AccountContextProps = {
  loading: boolean;
  user: User | null;
  account: Account | null;
  accountOffer: AccountOffer | null;
  noteCounts: Map<number, number>;
  searches: ProjectSearch[];
  realUsers: RealUser[];
  projectBudgetPaywallValue?: number;
  hasProductById: ThingExists<number>;
  hasFeature: ThingExists<FeatureName>;
  inExperiment: ThingExists<ExperimentName>;
  supportDetails?: SupportDetails;
  onUpgrade: () => void;
  termsOfUseURL: string | null;
  privacyPoliciesURL: string | null;
};

export const AccountContext = createContext<AccountContextProps | undefined>(undefined);

export const getNumericInterestLevel = (_: InterestLevel): number | undefined => WatchlistStatus[_];

const getProductIdForLicense = (license: { product: { id: number } }) => license.product.id;

const { Provider } = AccountContext;

const AccountProvider = ({ children }: { children?: ReactNode }) => {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState<User | null>(null);
  const [account, setAccount] = useState<Account | null>(null);
  const [accountOffer, setAccountOffer] = useState<AccountOffer | null>(null);
  const [featureNames, setFeatureNames] = useState<Set<FeatureName>>(new Set());
  const [projectBudgetPaywallValue, setProjectBudgetPaywallValue] = useState<number>();
  const [supportDetails, setSupportDetails] = useState<SupportDetails>();
  const [experimentNames, setExperimentNames] = useState<Set<ExperimentName>>(new Set());
  const [productIds, setProductIds] = useState<Set<number>>(new Set());
  const [noteCounts, setNoteCounts] = useState<Map<number, number>>(new Map());
  const [searches, setSearches] = useState<ProjectSearch[]>([]);
  const [realUsers, setRealUsers] = useState<RealUser[]>([]);
  const [termsOfUseURL, setTermsOfUseURL] = useState<string | null>(null);
  const [privacyPoliciesURL, setPrivacyPoliciesURL] = useState<string | null>(null);

  const {
    loading: queryLoading,
    data,
    refetch,
    error,
  } = useQuery<AccountContextQuery>(USER_ACCOUNT_AND_SUPPORT_DETAILS);

  useEffect(() => {
    if (queryLoading) setLoading(true);
    if (!data || queryLoading) return;

    const {
      availableAccountOffer,
      currentUser,
      currentUser: { account: currentAccount },
      experiments,
      projectNoteCounts,
      searches: projectSearches,
      paywallValueProjectMaxBudget,
      supportDetails: _supportDetails,
      termsOfUseURL: _termsOfUseURL,
      privacyPoliciesURL: _privacyPoliciesURL,
      speciFinderKeywordsForUser,
      hasSpeciFinderFeature,
    } = data;
    const activeLicenses = currentAccount?.licensingInfo.activeLicenses ?? [];
    const provisionedLicenses = currentAccount?.licensingInfo.provisionedLicenses ?? [];

    setLoading(false);

    const allFeatures = new Set(
      activeLicenses.reduce(
        (acc, { product: { features } }) => acc.concat(features),
        new Array<FeatureName>(),
      ),
    );

    // Graphql checks for feature that is not attached to the account license's products
    // as for suppliers this is done externally to their products.
    if (hasSpeciFinderFeature) {
      allFeatures.add(FeatureName.SPECIFINDER_SAVED_KEYWORDS);
    }

    setUser({ ...currentUser, ...{ speciFinderKeywords: speciFinderKeywordsForUser } });
    setAccount(currentAccount);
    setAccountOffer(availableAccountOffer);
    setFeatureNames(allFeatures);
    setExperimentNames(new Set(experiments.map(({ name }) => name)));

    setProductIds(
      new Set<number>(
        activeLicenses
          .map(getProductIdForLicense)
          .concat(provisionedLicenses.map(getProductIdForLicense)),
      ),
    );
    setNoteCounts(new Map(projectNoteCounts.map(({ projectId, count }) => [projectId, count])));
    setSearches(projectSearches);
    setRealUsers(currentAccount?.realUsers.filter((realUser) => realUser.deletedAt === null) ?? []);

    if (paywallValueProjectMaxBudget !== null) {
      setProjectBudgetPaywallValue(paywallValueProjectMaxBudget);
    }

    setSupportDetails(_supportDetails);

    setTermsOfUseURL(_termsOfUseURL);
    setPrivacyPoliciesURL(_privacyPoliciesURL);
  }, [queryLoading, data]);

  if (error) {
    reportError(error);
    return null;
  }

  const value: AccountContextProps = {
    loading,
    user,
    account,
    accountOffer,
    noteCounts,
    searches,
    realUsers,
    projectBudgetPaywallValue,
    supportDetails,
    termsOfUseURL,
    privacyPoliciesURL,
    hasProductById: (_) => productIds.has(_),
    hasFeature: (_) => featureNames.has(_),
    inExperiment: (_) => experimentNames.has(_),
    onUpgrade: refetch,
  };

  return <Provider value={value}>{children}</Provider>;
};

export {
  Account,
  ActiveLicense,
  ProjectSearch,
  User,
  InterestLevel,
  SupportDetails,
  UserKeyword,
  StockTrade,
};

const useAccountContext = () => {
  const ctx = useContext(AccountContext);
  if (!ctx) throw Error('AccountProvider not a parent');
  return ctx;
};

export default AccountProvider;

export { useAccountContext };
