import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { FileManagerParams } from '@shared/FileUploader/types';
import { ITP_RFQ_CONTEXT_QUERY } from './queries';
import { EntityId } from '../../../types';
import {
  ItpRfqContextQuery,
  ItpRfqContextQuery_currentItpUser as LimitedUser,
  ItpRfqContextQuery_currentItpUser_account as LimitedAccount,
  ItpRfqContextQuery_quotesForRfq as LimitedQuote,
  ItpRfqContextQueryVariables,
} from './types/ItpRfqContextQuery';

type ItpSubbieContextProviderProps = {
  stageId: EntityId;
  rfqId: EntityId;
  openSignupModal: boolean;
} & Pick<FileManagerParams, 'fileManagerLocale'>;

export type ItpSubbieContextProps = ItpSubbieContextProviderProps & {
  account?: LimitedAccount;
  user?: LimitedUser;
  quotesForRfq?: LimitedQuote[];
  renderTable: boolean;
};

export const ItpSubbieContext = createContext<
  [ItpSubbieContextProps, Dispatch<SetStateAction<ItpSubbieContextProps>>] | undefined
>(undefined);

const { Provider } = ItpSubbieContext;

export const ItpSubbieContextProvider = ({
  children,
  stageId,
  rfqId,
  fileManagerLocale,
  openSignupModal,
}: { children: ReactNode } & ItpSubbieContextProviderProps) => {
  const initialItpSubbieContext = {
    stageId,
    rfqId,
    fileManagerLocale,
    openSignupModal,
    renderTable: false,
  };
  const [itpSubbieContext, setItpSubbieContext] =
    useState<ItpSubbieContextProps>(initialItpSubbieContext);

  const { data, loading } = useQuery<ItpRfqContextQuery, ItpRfqContextQueryVariables>(
    ITP_RFQ_CONTEXT_QUERY,
    {
      variables: { rfqId },
    },
  );

  useEffect(() => {
    if (data !== undefined && !loading) {
      const { quotesForRfq, currentItpUser: user } = data;

      const account = user?.account ?? undefined;
      const renderTable = quotesForRfq?.length > 0;

      setItpSubbieContext({
        account,
        user: user ?? undefined,
        stageId,
        rfqId,
        quotesForRfq,
        fileManagerLocale,
        renderTable,
        openSignupModal,
      });
    }
  }, [data, loading]);

  return <Provider value={[itpSubbieContext, setItpSubbieContext]}>{children}</Provider>;
};

export const useItpSubbieContext = () => {
  const ctx = useContext(ItpSubbieContext);
  if (!ctx) throw Error('ItpSubbieContextProvider not a parent');
  return ctx;
};

export const useIsItp = () => useContext(ItpSubbieContext) !== undefined;
