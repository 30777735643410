import { Tooltip } from '@estimateone/frontend-components';

export type UserActivityProps = {
  amount: number;
  showAmount?: boolean;
  description: string;
};

const getTooltip = (description: string) => {
  switch (description) {
    case 'current':
      return 'The document set you have previously downloaded for this package is the current set.';
    case 'out of date':
      return 'An addendum has been issued and the document set you have previously downloaded for this package is now out-of-date.';
    default:
      return null;
  }
};

const UserActivity = ({ amount = 0, showAmount = true, description }: UserActivityProps) => {
  const tooltipText = getTooltip(description);

  if (tooltipText) {
    return (
      <Tooltip text={tooltipText} tooltipId="document-status-tooltip">
        <span>
          <i className={`icon icon-${amount > 0 ? 'flag' : 'check icon-sm'} icon-left`} />
          {showAmount && `${amount} `}
          {description}
        </span>
      </Tooltip>
    );
  }

  return (
    <span>
      <i className={`icon icon-${amount > 0 ? 'flag' : 'check icon-sm'} icon-left`} />
      {showAmount && `${amount} `}
      {description}
    </span>
  );
};

export default UserActivity;
