import { HTMLAttributes, MouseEvent, KeyboardEvent, ReactNode, useState } from 'react';
import ReactModal from 'react-modal';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { Button, ButtonVariant, CloseIcon } from '@estimateone/frontend-components';
import {
  ResponsiveBreakpoints,
  SpanOptions,
} from '@estimateone/frontend-components/src/components/Grid';
import styles from './styles.scss';

export type UpgradeModalProps = HTMLAttributes<HTMLDivElement> & {
  isOpen: boolean;
  onRequestClose: (event: MouseEvent | KeyboardEvent) => void;
  children?: ReactNode;
  hideClose?: boolean;
  rootElement?: string;
  useTransparentOverlay?: boolean;
  reactModalProps?: Omit<ReactModal.Props, 'isOpen' | 'onRequestClose'>;
};

export type UpgradeModalSharedProps = HTMLAttributes<HTMLDivElement> & {
  span?: SpanOptions | ResponsiveBreakpoints<SpanOptions>;
  src?: string;
  alt?: string;
  testId?: string;
  posterImage?: string;
  altDescription?: string;
  videoTitle?: string;
};

export type UpgradeModalCloseButtonProps = {
  onRequestClose?: UpgradeModalProps['onRequestClose'];
};

export const UpgradeModalImage = ({
  src,
  alt,
  ...props
}: HTMLAttributes<HTMLDivElement> & UpgradeModalSharedProps & { src?: string }) => (
  <img className={styles.modalImage} src={src} alt={alt} {...props} />
);

export const UpgradeModalVideo = ({
  url,
  width = '500',
  height = '500',
  testId = 'video-player',
  altDescription,
  posterImage,
  videoTitle,
  ...props
}: UpgradeModalSharedProps & ReactPlayerProps & { testId?: string }) => {
  const [hasError, setHasError] = useState(false);

  const onPlaybackError = () => {
    setHasError(true);
  };

  return (
    <div style={{ width, height, position: 'relative' }}>
      {!hasError ? (
        <ReactPlayer
          data-testid={testId}
          className={styles.modalVideo}
          url={url}
          width={width}
          height={height}
          muted
          loop
          playing
          controls
          onError={onPlaybackError}
          title={videoTitle}
          {...props}
        />
      ) : (
        <UpgradeModalImage className={styles.posterImage} src={posterImage} alt={altDescription} />
      )}
    </div>
  );
};

export const UpgradeModalContent = ({ children, ...props }: UpgradeModalSharedProps) => (
  <div className={styles.modalContent} {...props}>
    {children}
  </div>
);

export const UpgradeModalWrapper = ({
  isOpen,
  onRequestClose,
  children,
  useTransparentOverlay,
  reactModalProps,
}: UpgradeModalProps) => {
  ReactModal.setAppElement('#root');
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={useTransparentOverlay ? styles.overlayTransparent : styles.overlay}
      {...reactModalProps}
    >
      {children}
    </ReactModal>
  );
};

export const UpgradeModalCloseButton = ({ onRequestClose }: UpgradeModalCloseButtonProps) => (
  <div className={styles.closeIconPosition}>
    <Button onClick={onRequestClose} variant={ButtonVariant.Transparent}>
      <CloseIcon className={styles.closeIcon} />
    </Button>
  </div>
);
