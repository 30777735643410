import { gql } from '@apollo/client';
import Errors from '../../../../api/fragments/Errors';

export const CREATE_QUOTES_FOR_PROJECT = gql`
  mutation CreateQuotesForProject($input: CreateQuotesForProjectInput!) {
    createQuotesForProject(input: $input) {
      __typename
      ... on Quotes {
        quotes {
          id
        }
      }
      ... on Errors {
        errors {
          ...ApiError
        }
      }
    }
  }
  ${Errors.fragments.error}
`;
