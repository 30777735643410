import Routing from 'routing';
import { ARRAY_ERROR } from 'final-form';
import { FieldError } from '@shared/FinalForm/types';
import {
  AddQuoteFormSpecificFieldName,
  StagePackagesSubField,
  StagePackagesFieldError,
} from './enums';
import {
  StagePackageError,
  StagePackageFieldErrorPackage,
  StagePackageFieldErrors,
  StagePackageFieldsetError,
  StagePackageValue,
} from './types';

const validateStagePackage = (stagePackage: StagePackageValue): StagePackageFieldErrorPackage[] => {
  if (!stagePackage[StagePackagesSubField.IsSelected]) return [];

  const packageErrors = new Set<StagePackageFieldErrorPackage>();

  if (stagePackage[StagePackagesSubField.Package] === undefined) {
    packageErrors.add(StagePackagesFieldError.PackageEmpty);
  }

  const existingQuoteId = stagePackage[StagePackagesSubField.Package]?.mostRecentQuoteId;
  if (existingQuoteId) {
    packageErrors.add({
      message: StagePackagesFieldError.QuoteExists,
      url: Routing.generate('app_quote_revise', { id: existingQuoteId, returnTo: 'list' }),
      urlText: 'Revise existing quote',
    });
  }

  return Array.from(packageErrors);
};

const getRowErrors = (values: StagePackageValue[]): (StagePackageError | undefined)[] =>
  values.map((stagePackage) => {
    const packageErrors = validateStagePackage(stagePackage);
    return packageErrors.length !== 0
      ? { [StagePackagesSubField.Package]: packageErrors }
      : undefined;
  });

const getFieldsetErrors = (values: StagePackageValue[]): FieldError[] => {
  const errors = [];

  const hasSelectedStages = values.some(
    (stagePackage) => stagePackage[StagePackagesSubField.IsSelected],
  );

  if (!hasSelectedStages) {
    errors.push(StagePackagesFieldError.FieldEmpty);
  }

  return errors;
};

export const validateStagePackagesField = (
  values?: StagePackageValue[],
): StagePackageFieldErrors => {
  const rowErrors = values ? getRowErrors(values) : [];
  const stagePackageErrors = rowErrors.some((_) => _ !== undefined) ? rowErrors : [];

  // Special property for setting the FieldArray field-level errors
  /* eslint-disable fp/no-mutation */
  (stagePackageErrors as StagePackageFieldsetError)[ARRAY_ERROR] = getFieldsetErrors(values ?? []);
  /* eslint-enable */

  if (
    (stagePackageErrors as StagePackageFieldsetError)[ARRAY_ERROR]?.length === 0 &&
    stagePackageErrors.length === 0
  )
    return {};

  return {
    [AddQuoteFormSpecificFieldName.StagePackages]: stagePackageErrors,
  };
};
