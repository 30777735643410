import { useQuery } from '@apollo/client';
import NoResults from './NoResults';
import QuoteListLayout from './QuoteListLayout';
import PageLoadingSpinner from '../../../../shared/LoadingSpinner/PageLoadingSpinner';
import { GET_QUOTES } from './queries';
import { GetQuotes } from './types/GetQuotes';

const QuoteList = () => {
  const { data, loading } = useQuery<GetQuotes>(GET_QUOTES);

  if (loading) return <PageLoadingSpinner />;

  if (!data || !data.subbieQuotes.length) {
    return <NoResults />;
  }

  return <QuoteListLayout quotes={data.subbieQuotes} />;
};

export default QuoteList;
