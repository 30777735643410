export type Address = {
  address1?: string | null;
  address2?: string | null;
  address3?: string | null;
  postcode?: string | null;
  shortAddress?: string | null;
  district?: string | null;
  province?: string | null;
  suburb?: string | null;
  region?: string | null;
  city?: string | null;
  state?: {
    shortName: string | null;
  } | null;
  country?: {
    name: string | null;
  };
};

const DELIMITER = ', ';

export const graphqlTypeToString = (address: Address) =>
  [address.address1, address.shortAddress].filter(Boolean).join(DELIMITER);

export const graphqlTypeToLines = (
  address: Address,
): { firstLine: string; secondLine: string } => ({
  firstLine: [address.address1, address.address2, address.address3].filter(Boolean).join(DELIMITER),
  secondLine: [
    address.district,
    address.province,
    address.suburb,
    address.region,
    address.city,
    address.state?.shortName,
    address.country?.name,
  ]
    .filter(Boolean)
    .join(DELIMITER)
    .concat(address.postcode ? ` ${address.postcode}` : ''),
});
