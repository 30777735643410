import $ from 'jquery';
import Routing from 'routing';
import { captureException } from '@sentry/browser';
import { debounce, escape } from 'underscore';
import E1Request from '../classes/E1Request';

function hideAddressFields($form: JQuery<HTMLFormElement>) {
  $form.find('.address-fields').slideUp();
}

type UKAddress = {
  // No states, no suburbs
  address1: string;
  address2: string | null;
  province: string | null;
  city: string;
  country: { id: number };
  postcode: string;
};

type AUAddress = {
  // No provinces
  address1: string;
  address2: string | null;
  suburb: string | null;
  city: string;
  country: { id: number };
  state: { shortName: string } | null;
  postcode: string;
};

type Address = UKAddress | AUAddress;

export const getAddressString = (address: Address) => {
  const addressString = [escape(address.address1)];
  if (address.address2) {
    addressString.push(`<br />${escape(address.address2)}`);
  }
  if ('suburb' in address && address.suburb) {
    addressString.push(`<br />${escape(address.suburb)}`);
  }
  addressString.push(`<br />${escape(address.city)}`);
  if ('state' in address && address.country.id === 1) {
    addressString.push(`, ${escape(address.state!.shortName)}`);
  }
  if ('province' in address && address.province) {
    addressString.push(`<br />${escape(address.province)}`);
  }
  if (address.postcode) {
    addressString.push(` ${escape(address.postcode)}`);
  }
  return addressString.join('');
};

export const getShortAddress = (address: Address) => {
  const shortAddress = [];
  if (address) {
    if ('suburb' in address && address.suburb) {
      shortAddress.push(address.suburb);
    } else if (address.city) {
      shortAddress.push(address.city);
    }

    if ('province' in address && address.province) {
      shortAddress.push(address.province);
    } else if ('state' in address && address.state) {
      shortAddress.push(address.state.shortName);
    }
  }
  return escape(shortAddress.join(', '));
};

export const getBoundsForLocaleBias = () => {
  const locale = window.global.locale || 'en_AU';

  try {
    switch (locale) {
      case 'en_GB':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(49.0, -10.0),
          new google.maps.LatLng(59.0, 2.0),
        );
      case 'en_IE':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(52.0, -10.0),
          new google.maps.LatLng(55.0, 6.0),
        );
      case 'en_NZ':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(-48.0, 166.0),
          new google.maps.LatLng(-34.0, 179.0),
        );
      case 'en_PH':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(5.0, 117.0),
          new google.maps.LatLng(19.0, 127.0),
        );
      case 'en_AU':
      default:
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(-44.0, 112.0),
          new google.maps.LatLng(-9.0, 154.0),
        );
    }
  } catch (err) {
    // google is probably blocked e.g. China
    captureException(err);
    return null;
  }
};

const loadStates = async (countryId: number) => {
  if (countryId > 0) {
    const path = Routing.generate('app_ajax_loadstates', { id: countryId });
    const request = new E1Request<{
      success: boolean;
      states: { id: number; shortName: string }[];
    }>(path);
    const statesResponse = await request.submit();
    if (statesResponse.success) {
      return statesResponse.states;
    }
  }

  return new Array<{ id: number; shortName: string }>();
};

const updateStateSelector = async (countryId: number, $form: JQuery<HTMLFormElement>) => {
  const $stateSelector = $form.find('.state-selector');

  $stateSelector.find('option:gt(0)').remove();
  const states = await loadStates(countryId);

  states.forEach(({ id, shortName }) => {
    $stateSelector.append($('<option>', { value: id, text: shortName }));
  });

  if ($stateSelector.find('option').length <= 1) {
    $stateSelector.closest('.form-group').slideUp();
  } else if (!$stateSelector.is(':visible')) {
    $stateSelector.closest('.form-group').slideDown();
  }
};

const setCountyProvinceNaming = ($form: JQuery<HTMLFormElement>, countryId: number) => {
  const $provinceField = $form.find('.address-fields').find('.province-field');
  // TODO: translation component always in AU mode in admin, so change the label here...
  const provinceCountyName = [4, 11].includes(countryId) ? 'County' : 'Province';
  $provinceField.attr('placeholder', provinceCountyName);
  $provinceField.parent().find('label').text(provinceCountyName);
};

const showAddressFields = ($form: JQuery<HTMLFormElement>, countryId: number) => {
  const $addressFields = $form.find('.address-fields');
  const $groups = $addressFields.find('.form-group');

  $groups.toArray().forEach((field) => {
    const $field = $(field);
    if ($field.hasClass(`address_form_country_hide_${countryId.toString()}`)) {
      $field.hide();
    } else {
      $field.show();
    }
  });

  setCountyProvinceNaming($form, countryId);

  $addressFields.slideDown();
};

const onAddressFormCountryChange = async (
  $selector: JQuery<HTMLInputElement>,
  $form: JQuery<HTMLFormElement>,
) => {
  const countryId = parseInt($selector.val() ?? '');
  await updateStateSelector(countryId, $form);

  if (countryId > 0) {
    showAddressFields($form, countryId);
  } else {
    hideAddressFields($form);
  }
};

function getGeocoder() {
  try {
    return new google.maps.Geocoder();
  } catch (err) {
    captureException(err);
  }
  return null;
}

export function initAddressForm($addressForm: JQuery<HTMLFormElement>) {
  const $countrySelector: JQuery<HTMLInputElement> = $addressForm.find('select.country-selector');
  if ($addressForm.hasClass('no-country')) {
    const $options = $countrySelector.find('option');
    const $target = $options.first();
    $target.prop('selected', true);
    $countrySelector.trigger('change');
  }

  setCountyProvinceNaming($addressForm, parseInt($countrySelector.val() ?? ''));

  const $longitude = $addressForm.find('input.longitude-field');
  const $latitude = $addressForm.find('input.latitude-field');

  if ($longitude.length > 0 && $latitude.length > 0) {
    $addressForm.on(
      'change',
      ':input',
      debounce(async () => {
        $longitude.val('');
        $latitude.val('');

        const addressArray = $addressForm.find('input:visible').serializeArray();
        const addressStringVisibleInputs = addressArray.reduce(
          (acc, part) => `${acc} ${part.value}`,
          '',
        );
        const addressStringSelectedOptions = $addressForm
          .find('option:selected')
          .toArray()
          .reduce((acc, opt) => `${acc} ${$(opt).text()}`, '');
        const addressString = `${addressStringVisibleInputs} ${addressStringSelectedOptions}`;

        const geocoder = getGeocoder();
        if (geocoder) {
          await new Promise<void>((resolve) => {
            geocoder.geocode({ address: addressString }, (results, status) => {
              if (status === google.maps.GeocoderStatus.OK) {
                const lngLat = results[0].geometry.location;
                $longitude.val(lngLat.lng());
                $latitude.val(lngLat.lat());
              }
            });
            resolve();
          });
        }
      }, 200),
    );
  }
}

$(window).on('load', () => {
  const { geoAddressId: addressId, geoAddressFormatted: address } = $('body').data();
  if (addressId && address) {
    const geocoder = getGeocoder();
    if (geocoder) {
      geocoder.geocode({ address }, async (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lngLat = results[0].geometry.location;
          try {
            await new E1Request(
              Routing.generate('app_ajax_geolocateaddress', { id: addressId }),
              'POST',
              {
                lat: lngLat.lat(),
                lng: lngLat.lng(),
              },
              true,
            ).submit();
          } catch (geolocateFailed) {
            throw Error('Failed to geolocate address');
          }
        }
      });
    }
  }
});

$(() => {
  $('body').on('change', '.auto_address_form select.country-selector', (e) => {
    const $countrySelector = $(e.currentTarget);

    // We have to give TS a little help here - it can trust we are passing form elements

    const $form: JQuery<HTMLFormElement> = $countrySelector.closest('.auto_address_form');

    return onAddressFormCountryChange($countrySelector, $form);
  });

  const $addressForm = $('.auto_address_form');

  $addressForm
    .toArray()
    .forEach((form: HTMLFormElement) =>
      initAddressForm($(form) as unknown as JQuery<HTMLFormElement>),
    );
});
