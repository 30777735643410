import { joinClassNames } from '@estimateone/frontend-components';

type UserInitialsIconProps = { initials?: string; isCurrentUser?: boolean };

const UserInitialsIcon = ({ initials = '', isCurrentUser = false }: UserInitialsIconProps) => (
  <div
    className={joinClassNames(
      'badge badge-round-sm badge-initials',
      isCurrentUser && 'current-user',
    )}
  >
    {initials}
  </div>
);

export default UserInitialsIcon;
