import { ReactNode } from 'react';
import { Wrapper } from '@estimateone/frontend-components';
import TitleHero, { TitleHeroProps } from './TitleHero';

type AddQuoteLayoutProps = {
  children: ReactNode;
  title: string;
  returnUrl: string;
} & TitleHeroProps;

const QuotePageLayout = ({
  children,
  title,
  returnUrl,
  ...titleHeroProps
}: AddQuoteLayoutProps) => (
  <div>
    <TitleHero title={title} returnUrl={returnUrl} {...titleHeroProps} />
    <Wrapper>{children}</Wrapper>
  </div>
);

export default QuotePageLayout;
