import { useContext, useEffect, useState } from 'react';
import { useField, useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Col, Row, Fieldset, Heading, FontStyle, Tooltip } from '@estimateone/frontend-components';
import PackageSelectorField from './PackageSelectorField';
import StageSelectorField from './StageSelectorField';
import { fieldNameGenerator } from './utils';
import { FieldError } from '@shared/FinalForm/types';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { CurrencyInfoContext } from '@shared/context';
import {
  AddQuoteFormSpecificFieldName,
  StagePackagesFieldError,
  StagePackagesSubField,
} from '../enums';
import { Country, getCurrencyInfo } from '@ascension/enums';
import {
  QuotableStage,
  QuoteSubbieAddFormValues,
  StagePackageIsSelectedValue,
  StagePackagePackageValue,
  StagePackageStageValue,
  StagePackageValue,
} from '../types';
import { StagePackagesSubFieldValues } from './types';
import styles from './styles.scss';

const isIncognito = (
  builderDetails: QuotableStage['builderDetails'],
): builderDetails is Extract<
  QuotableStage['builderDetails'],
  { __typename: 'HiddenBuilderDetails' }
> => builderDetails.__typename === 'HiddenBuilderDetails';

type StagePackageFieldProps = {
  parentFieldName: string;
  stage: QuotableStage;
  isPreselected?: boolean;
  setCountry: (country: Country) => void;
  isDisabled?: boolean;
  disabledText?: string;
};

const StagePackageField = ({
  parentFieldName,
  stage,
  isPreselected = false,
  setCountry,
  isDisabled = false,
  disabledText = undefined,
}: StagePackageFieldProps) => {
  const {
    change: updateFieldValue,
    mutators: { setFieldTouched },
    getState,
  } = useForm<QuoteSubbieAddFormValues & StagePackagesSubFieldValues>();

  const [, setCurrencyInfoContext] = useContext(CurrencyInfoContext);

  const {
    id: stageId,
    country: { id: primaryCountryId },
    activePackages,
    builderDetails,
  } = stage;

  const { hasSubmitErrors, modifiedSinceLastSubmit } = getState();
  const isCleanSubmitWithErrors = hasSubmitErrors && !modifiedSinceLastSubmit;

  const getFullSubFieldName = fieldNameGenerator<StagePackagesSubField>(parentFieldName);
  const stageFieldName = getFullSubFieldName(StagePackagesSubField.Stage);
  const isSelectedFieldName = getFullSubFieldName(StagePackagesSubField.IsSelected);
  const isWithdrawnFieldName = getFullSubFieldName(StagePackagesSubField.IsWithdrawn);
  const packageFieldName = getFullSubFieldName(StagePackagesSubField.Package);

  const {
    meta: { submitError: stageSubmitError },
  } = useField<StagePackageStageValue>(stageFieldName);

  const {
    input: { checked: isSelected },
  } = useField<StagePackageIsSelectedValue>(isSelectedFieldName, { type: 'checkbox' });

  useEffect(() => {
    if (isSelected && stageSubmitError?.find((_: FieldError) => _.includes('withdrawn'))) {
      updateFieldValue(isWithdrawnFieldName, true);
      updateFieldValue(isSelectedFieldName, false);
      updateFieldValue(packageFieldName, undefined);
    }

    if (isSelected) {
      setCountry(primaryCountryId);
      const currencyInfo = getCurrencyInfo(primaryCountryId);
      setCurrencyInfoContext({ currencyInfo });
    }
  }, [
    isSelected,
    stageSubmitError,
    updateFieldValue,
    isWithdrawnFieldName,
    isSelectedFieldName,
    packageFieldName,
    setCountry,
    primaryCountryId,
    setCurrencyInfoContext,
  ]);

  const {
    meta: {
      active: packageActive,
      error: packageError,
      submitError: packageSubmitError,
      touched: packageTouched,
      dirtySinceLastSubmit,
    },
  } = useField<StagePackagePackageValue>(packageFieldName);

  useEffect(() => {
    if (isCleanSubmitWithErrors && packageTouched) {
      setFieldTouched(packageFieldName, false);
    }
  }, [isCleanSubmitWithErrors, packageTouched, setFieldTouched, packageFieldName]);

  const packageSubmitErrors =
    isSelected && (isCleanSubmitWithErrors || !dirtySinceLastSubmit) ? packageSubmitError : null;
  const isPackageEmptyError = packageError?.includes(StagePackagesFieldError.PackageEmpty);
  const packageErrors =
    (packageTouched && (isPackageEmptyError ? !packageActive && packageError : packageError)) ||
    packageSubmitErrors;

  const { name: builderName, contactPhone } = isIncognito(builderDetails)
    ? { name: getTranslation(LangRef.INCOGNITO_BUILDER_HEADING), contactPhone: '' }
    : builderDetails;

  const useTooltip = isDisabled && disabledText;
  const tooltipdId = `quoting-disabled-tip-${stageId}`;

  return (
    <Fieldset legend={`${builderName} package selector`} hideLegend>
      {useTooltip && <Tooltip tooltipId={tooltipdId} text={disabledText} />}
      <Row>
        <Col
          span={7}
          excludeBottomGutter
          data-tip={useTooltip || undefined}
          data-for={useTooltip ? tooltipdId : undefined}
        >
          <StageSelectorField
            parentFieldName={parentFieldName}
            stageId={stageId}
            accountName={builderName}
            phone={contactPhone}
            hasPackageErrors={Boolean(packageErrors && packageErrors.length !== 0)}
            isPreselected={isPreselected}
            isDisabled={isDisabled}
          />
        </Col>

        <Col span={5} excludeBottomGutter>
          <PackageSelectorField
            parentFieldName={parentFieldName}
            stageId={stageId}
            activePackages={activePackages}
            errors={packageErrors}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

const QuotePackagesField = () => {
  const { change: updateFieldValue } = useForm<
    QuoteSubbieAddFormValues & StagePackagesSubFieldValues
  >();

  const {
    fields,
    meta: { error, touched },
  } = useFieldArray<StagePackageValue>(AddQuoteFormSpecificFieldName.StagePackages);

  const [country, setCountry] = useState<Country>(
    fields.value[0][StagePackagesSubField.Stage].country.id,
  );

  const fieldErrors: FieldError[] = (error as unknown as string[]) || [];

  const shouldPreselectStage =
    fields.length === 1 && !fields.value[0][StagePackagesSubField.IsSelected];

  if (shouldPreselectStage) {
    const getFullSubFieldName = fieldNameGenerator<StagePackagesSubField>(`${fields.name}[0]`);
    const isSelectedFieldName = getFullSubFieldName(StagePackagesSubField.IsSelected);
    updateFieldValue(isSelectedFieldName, true);
  }

  const anySelected = fields.value.some((_) => _[StagePackagesSubField.IsSelected]);

  return (
    <Fieldset
      name={AddQuoteFormSpecificFieldName.StagePackages}
      id="quote-stage-packages"
      legend="Builder and package selector"
      hideLegend
    >
      <Row>
        <Col span={7} excludeBottomGutter>
          <Heading level={3} renderAs={FontStyle.h4}>
            Which head contractor would you like to send this quote to?
          </Heading>
        </Col>
        <Col span={5} excludeBottomGutter>
          <strong className={styles.customFormLabel} aria-disabled>
            Package
          </strong>
        </Col>
      </Row>

      {fieldErrors.length !== 0 && touched && (
        <Row>
          <Col excludeBottomGutter>
            <ul className={styles.packageSelectorFieldError}>
              {fieldErrors.map((_) => (
                <li key={_}>{_}</li>
              ))}
            </ul>
          </Col>
        </Row>
      )}

      <div className={styles.packageOptions}>
        {fields.value.map((_, index) => {
          const isCountryDifferentFromOtherSelection =
            anySelected && country && _[StagePackagesSubField.Stage].country.id !== country;
          const areBuilderDetailsHidden =
            _[StagePackagesSubField.Stage].builderDetails.__typename === 'HiddenBuilderDetails';

          return (
            <StagePackageField
              key={_[StagePackagesSubField.Stage].id}
              parentFieldName={`${AddQuoteFormSpecificFieldName.StagePackages}[${index}]`}
              stage={_[StagePackagesSubField.Stage]}
              isPreselected={fields.length === 1}
              setCountry={setCountry}
              isDisabled={isCountryDifferentFromOtherSelection || areBuilderDetailsHidden}
              disabledText={
                areBuilderDetailsHidden
                  ? 'You must request documents from this builder so that you can add a quote'
                  : undefined
              }
            />
          );
        })}
      </div>
    </Fieldset>
  );
};

export default QuotePackagesField;
