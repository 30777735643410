export const QuoteFileRestrictions = {
  MaxFileSize: 24.5 * 1e6, // 24.5MB
  AllowedAttachmentFileTypes: [
    '.7z',
    '.css',
    '.csv',
    '.doc',
    '.docm',
    '.docx',
    '.dot',
    '.dotx',
    '.dwg',
    '.dxf',
    '.eml',
    '.emltpl',
    '.eps',
    '.gdoc',
    '.htm',
    '.html',
    '.indd',
    '.key',
    '.log',
    '.m4a',
    '.mdb',
    '.mhtml',
    '.mpp',
    '.msg',
    '.numbers',
    '.odg',
    '.ods',
    '.odt',
    '.oxps',
    '.pages',
    '.pdf',
    '.ppt',
    '.pptm',
    '.pptx',
    '.psd',
    '.pub',
    '.rar',
    '.rtf',
    '.sdocx',
    '.txt',
    '.webloc',
    '.webarchive',
    '.xls',
    '.xlsm',
    '.xlsx',
    '.xltx',
    '.xml',
    '.xps',
    '.zip',
    'image/*',
  ],
};
