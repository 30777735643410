import { ReactNode } from 'react';

type OnClickParams = { [k: string]: unknown };

export type LinkProps = {
  children?: ReactNode;
  href?: string;
  onClick?: (params: OnClickParams) => void;
  onClickParams?: OnClickParams;
  extraClasses?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
};

const Link = ({
  children,
  href,
  onClick,
  onClickParams = {},
  extraClasses,
  target = '_self',
}: LinkProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick(onClickParams);
    }
  };

  return (
    <a onClick={handleClick} href={href} className={`link ${extraClasses}`} target={target}>
      {children}
    </a>
  );
};

export default Link;
