import { Heading, FontStyle } from '../../../../fec';
import { getTranslation, LangRef } from '@shared/Util/lang';
import { AccountType } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type NonEditablePackageContactSectionProps = {
  accountType: AccountType;
  packageName: string;
  recipientName?: string;
  companyName?: string;
  contactName?: string;
};
const NonEditablePackageContactSection = ({
  accountType,
  packageName,
  recipientName,
  companyName,
  contactName,
}: NonEditablePackageContactSectionProps) => (
  <>
    <div className={styles.section}>
      <Heading level={3} renderAs={FontStyle.h4}>
        Package
      </Heading>
      <p>{packageName}</p>
    </div>

    <div className={`${styles.section} ${styles.contact}`}>
      <Heading level={3} renderAs={FontStyle.h4}>
        {accountType === AccountType.SUBCONTRACTOR
          ? getTranslation(LangRef.HEAD_CONTRACTOR_HEADING)
          : 'Subcontractor'}
      </Heading>
      <p>
        {accountType === AccountType.SUBCONTRACTOR
          ? recipientName
          : `${companyName} - ${contactName}`}
      </p>
    </div>
  </>
);

type NonEditablePackageBuilderSectionProps = {
  packageName: string;
  recipientName: string;
};

export const NonEditablePackageBuilderSection = ({
  packageName,
  recipientName,
}: NonEditablePackageBuilderSectionProps) => (
  <NonEditablePackageContactSection
    accountType={AccountType.SUBCONTRACTOR}
    packageName={packageName}
    recipientName={recipientName}
  />
);

type NonEditablePackageSubbieSectionProps = {
  packageName: string;
  companyName: string;
  contactName: string;
};

export const NonEditablePackageSubbieSection = ({
  packageName,
  companyName,
  contactName,
}: NonEditablePackageSubbieSectionProps) => (
  <NonEditablePackageContactSection
    accountType={AccountType.BUILDER}
    packageName={packageName}
    companyName={companyName}
    contactName={contactName}
  />
);
