import { useState, useEffect, useMemo } from 'react';
import { Wrapper } from '@estimateone/frontend-components';
import { useTranslator } from '@profiles/providers/TranslationsProvider';
import { QuoteViewedBuilderUpgradeHook } from '../QuoteViewedBuilderUpgradeHook';
import formatQuotesForTable, { IndexedComponents } from './formatQuotesForTable';
import sortQuotesForTable, { QuoteList } from './sortQuotesForTable';
import Table from '../../../../shared/Table';
import { useCanSeeQuoteViewedByBuilderInQuotesPage } from './hooks';
import { Column } from './enums';
import { GetQuotes_subbieQuotes as Quote } from './types/GetQuotes';
import { SortDirection } from '@ascension/_gqltypes/subbie.generated';
import styles from './styles.scss';

type QuoteListLayoutProps = { quotes: Quote[] };

const QuoteListLayout = ({ quotes }: QuoteListLayoutProps) => {
  const [sortBy, setSortBy] = useState(Column.QuotesDue);
  const [sortDir, setSortDir] = useState(SortDirection.DESC);
  const [rows, setRows] = useState<QuoteList[]>([]);

  const translate = useTranslator();
  const builderTerm = translate('builders');
  useEffect(() => {
    setRows(sortQuotesForTable(quotes, sortBy, sortDir));
  }, [quotes, sortBy, sortDir]);

  const handleSortChange = (column?: Column, direction?: SortDirection) => {
    if (!column || !direction) return;
    setSortBy(column);
    setSortDir(direction);
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Project',
        accessor: Column.Project,
      },
      {
        Header: `${builderTerm}`,
        accessor: Column.Builder,
      },
      {
        Header: 'Quotes due',
        accessor: Column.QuotesDue,
      },
      {
        Header: 'Package',
        accessor: Column.Package,
      },
      {
        Header: 'Quote',
        accessor: Column.QuoteDetails,
        disableSortBy: true,
      },
      {
        Header: `Viewed By ${builderTerm}`,
        accessor: Column.Status,
      },
    ],
    [builderTerm],
  );

  const canSeeStatusColumn = useCanSeeQuoteViewedByBuilderInQuotesPage();
  const visibleColumns = useMemo(
    () => (canSeeStatusColumn ? columns : columns.filter((col) => col.accessor !== Column.Status)),
    [canSeeStatusColumn, columns],
  );

  return (
    <div className={styles.quoteListPage}>
      <Wrapper className={styles.quoteListWrapper}>
        <QuoteViewedBuilderUpgradeHook />
        <Table<IndexedComponents>
          columns={visibleColumns}
          data={formatQuotesForTable(rows)}
          sortDir={sortDir}
          sortColumn={sortBy}
          onSortChange={handleSortChange}
        />
      </Wrapper>
    </div>
  );
};

export default QuoteListLayout;
