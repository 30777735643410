import { useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { Button, ButtonSize, ButtonVariant, Col, Row } from '@estimateone/frontend-components';
import arrayMutators from 'final-form-arrays';
import setFieldTouched from 'final-form-set-field-touched';
import { Heading } from '@ascension/components/fec';
import QuotePackagesField from './QuotePackagesField';
import { packageToOption } from './QuotePackagesField/utils';
import { validateStagePackagesField } from './validators';
import { FileManagerParams } from '@shared/FileUploader/types';
import GlobalErrorList from '@shared/FinalForm/GlobalErrorList';
import { ProjectDetails } from '@shared/Quotes/Form/ProjectOrStageDetails';
import { QuoteSectionWithQuoteFileUploader } from '@shared/Quotes/Form/QuoteFormQuoteSection';
import { focusOnQuoteErrorsDecorator } from '@shared/Quotes/Form/decorators';
import { validateQuoteFormCommonFields } from '@shared/Quotes/Form/validators';
import { useCreateQuotesForProject } from './hooks';
import { AddQuoteFormSpecificFieldName, StagePackagesSubField } from './enums';
import { QuoteFormCommonFieldName, UploadParentType } from '@ascension/enums';
import {
  QuotableStage,
  QuoteSubbieAddFormValues,
  StagePackageValue,
  SubbieAddQuoteFormErrors,
} from './types';
import { EntityId } from '@ascension/types';
import styles from '@shared/Quotes/Form/styles.scss';

export type AddQuoteFormProps = {
  projectId: EntityId;
  projectName: string;
  projectAddress: string;
  stages: QuotableStage[];
  returnUrl: string;
  defaultPackageId?: EntityId;
} & Pick<FileManagerParams, 'context' | 'fileManagerLocale'>;

const FORM_ID = 'subbie-add-quote-form';

const getRedirectURLWithCueQuery = (queryString: string) => (url: string) =>
  url.indexOf('?') === -1 ? `${url}?${queryString}` : `${url}&${queryString}`;

const validate = (values: QuoteSubbieAddFormValues): SubbieAddQuoteFormErrors => {
  const commonQuoteErrors = validateQuoteFormCommonFields(values);
  const stagePackageErrors = validateStagePackagesField(
    values[AddQuoteFormSpecificFieldName.StagePackages],
  );

  return { ...stagePackageErrors, ...commonQuoteErrors };
};

const AddQuoteForm = ({
  context,
  fileManagerLocale,
  projectId,
  projectName,
  projectAddress,
  stages,
  returnUrl,
  defaultPackageId,
}: AddQuoteFormProps) => {
  const focusOnErrors = useMemo(
    () =>
      focusOnQuoteErrorsDecorator<QuoteSubbieAddFormValues, SubbieAddQuoteFormErrors>(FORM_ID, {
        customOffsets: { [AddQuoteFormSpecificFieldName.StagePackages]: -20 },
      }),
    [],
  );

  const { submit: createQuotes, newQuotes } = useCreateQuotesForProject(projectId);

  useEffect(() => {
    if (newQuotes) {
      window.location.replace(getRedirectURLWithCueQuery('cue=quoteSubmitted')(returnUrl));
    }
  }, [newQuotes, returnUrl]);

  return (
    <Form<QuoteSubbieAddFormValues>
      onSubmit={createQuotes}
      initialValues={{
        [AddQuoteFormSpecificFieldName.StagePackages]: stages.reduce(
          (acc: StagePackageValue[], stage) => {
            const activePackage = defaultPackageId
              ? stage.activePackages.find((_) => _.id === defaultPackageId)
              : undefined;
            return [
              ...acc,
              {
                [StagePackagesSubField.Stage]: stage,
                [StagePackagesSubField.IsSelected]: false,
                [StagePackagesSubField.IsWithdrawn]: false,
                [StagePackagesSubField.Package]: activePackage
                  ? packageToOption(activePackage)
                  : undefined,
              },
            ];
          },
          [],
        ),
        [QuoteFormCommonFieldName.ExtraFiles]: [],
      }}
      decorators={[focusOnErrors]}
      validate={(values): SubbieAddQuoteFormErrors => validate(values)}
      mutators={{
        ...arrayMutators,
        setFieldTouched,
      }}
      keepDirtyOnReinitialize
    >
      {({ handleSubmit, submitting, submitError }) => (
        <form id={FORM_ID} onSubmit={handleSubmit} name="Add Quote">
          <Row>
            <Col span={12}>
              {submitError && <GlobalErrorList errors={submitError} />}

              <div className={styles.subHeader}>
                Upload quote files and complete the details below to submit the quote.
              </div>

              <div className={styles.sectionHeading}>
                <Heading level={2}>Project details</Heading>
              </div>
              <ProjectDetails name={projectName} addressString={projectAddress} />
            </Col>
          </Row>

          <QuotePackagesField />

          <QuoteSectionWithQuoteFileUploader
            context={context}
            fileManagerLocale={fileManagerLocale}
            parentId={projectId}
            parentType={UploadParentType.PROJECT_QUOTE}
            extraFilesParentId={projectId}
            extraFilesParentType={UploadParentType.PROJECT_QUOTE_EXTRA_FILE}
          />

          <Row>
            <Col span={12}>
              <hr />
            </Col>
          </Row>

          <Row>
            <Col span={2}>
              <Button link={returnUrl} size={ButtonSize.Large} variant={ButtonVariant.Grey}>
                Cancel
              </Button>
            </Col>
            <Col span={4} offset={6}>
              <Button size={ButtonSize.Large} fullWidth isDisabled={submitting}>
                Submit
              </Button>
            </Col>
          </Row>
        </form>
      )}
    </Form>
  );
};

export default AddQuoteForm;
